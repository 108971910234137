/* eslint-disable no-undef */
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Link } from "@mui/material";
// import { sendForgetUserPassword } from "../../../utils/userAction";
import { verifyEmailFormat } from "../../../utils/verifyFormat";
import { Box, Button, InputAdornment, TextField, Grid } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
export default function ResetPasswordByForget(props) {
  //定义定时器
  let resetPasswordTimer = null;

  //邮箱数据
  const [resetEmailValue, setResetEmailValue] = React.useState({
    email: "",
  });
  //检测邮箱状态
  const [resetEmailState, setResetEmailState] = React.useState("");
  //校验重新注册邮箱格式
  const [errorResetEmail, setErrorResetEmail] = React.useState("");
  //发送邮件时转为loading
  const [resetPasswordLoading, setRegisterPasswordLoading] = React.useState(false);
  //请求时的错误提示
  const [errorResetPassword, setErrorResetPassword] = React.useState("");

  ///重置密码接口
  async function resetPassword(anchor) {
    setRegisterPasswordLoading(true);
    sendConstructEventByEventParams("reset_password_page", {
      click: "reset",
    });
    if (resetEmailState === "success") {
      const { resetUserPasswordSendEmail } = await import("../../../api/user");
      const res = await resetUserPasswordSendEmail({
        username: resetEmailValue.email,
      });
      if (res.status === "success") {
        sendConstructEventByEventParams("reset_password_page", {
          reset_result: "success",
        });
        setErrorResetPassword("reset password link send to your email.");
        props.setNowPage(props.pages[0]);
      } else {
        sendConstructEventByEventParams("reset_password_page", {
          reset_result: "fail",
          reset_fail_reason: res.message,
        });
        setErrorResetPassword(res.message);
      }
    } else {
      if (resetEmailValue.email === "") {
        setErrorResetEmail("The mailbox cannot be empty");
      }
    }
    setRegisterPasswordLoading(false);
  }

  ///检查邮件是否正确
  const checkEmail = (event) => {
    setResetEmailValue({ email: event.target.value });
    if (verifyEmailFormat(event.target.value)) {
      setResetEmailState("success");
      setErrorResetEmail("");
      return true;
    } else {
      setResetEmailState("error");
      setErrorResetEmail("Email format error");
      if (event.target.value == "") {
        setErrorResetEmail("");
      }
      return false;
    }
  };

  React.useEffect(() => {
    return () => {
      //组件页面卸载后，清除定时器
      clearTimeout(resetPasswordTimer);
    };
  }, []);

  return (
    <Box
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "600px",
          maxHeight: "100vh",
        }}
      >
        <Grid container justifyContent="center" item>
          <Typography
            variant="h4"
            gutterBottom
            component="div"
            sx={{
              textAlign: "center",
              marginBottom: "20px",
              width: "416px",
              height: "58px",
              fontSize: "32px",
            }}
          >
            <span style={{ fontWeight: "bold", color: "#323339" }}>To reset </span>
            your password
          </Typography>
          {/* 继续 */}
          <div style={{ textAlign: "center" }}>
            <span>We will send you a link to reset your password</span>
          </div>

          {/* 输入框 */}
          {/*邮箱*/}
          <TextField
            color="secondary"
            InputProps={{
              sx: {
                height: "40px",
                fontSize: "16px",
              },
            }}
            InputLabelProps={{
              sx: {
                top: "-6px",
                fontSize: "16px",
              },
            }}
            error={errorResetEmail === "" ? false : true}
            id="Email"
            onChange={checkEmail}
            label="Email"
            helperText={errorResetEmail}
            sx={{ width: "360px", height: "48px", marginTop: "40px" }}
          />
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: "35px",
              color: "red",
              width: "360px",
              overflow: "hidden",
              wordBreak: "nowrap",
            }}
          >
            {errorResetPassword}
          </Box>
          {/* 登陆 */}
          {resetPasswordLoading ? (
            <LoadingButton
              loading
              // onClick={sendResetPasswordRequest}
              variant="contained"
              // disabled={submitButtonDisable}
              // variant="contained"
              sx={{
                borderRadius: "24px",
                width: "360px",
                height: "48px",
                outline: "none",
              }}
            >
              Submit
            </LoadingButton>
          ) : (
            <Button
              sx={{
                borderRadius: "24px",
                width: "360px",
                height: "48px",
                textTransform: "none",
                outline: "none",
              }}
              onClick={() => resetPassword("right")}
              variant="contained"
            >
              <Typography variant="body5"> Send E-mail</Typography>
            </Button>
          )}
          {/* 横线 */}
          <div
            style={{
              white: "400px",
              borderTop: "0.5px solid #eee",
              marginTop: "10px",
            }}
          ></div>
          {/* 注册 */}
          <Box sx={{ width: "400px", textAlign: "center", marginTop: "10px" }}>
            <Link
              underline="hover"
              sx={{ fontSize: "16px" }}
              onClick={() => {
                sendConstructEventByEventParams("reset_password_page", {
                  click: "login",
                });
                props.setNowPage(props.pages[0]);
              }}
            >
              Log in
            </Link>
            <Link underline="none" sx={{ fontSize: "16px", color: "#323339" }}>
              No account yet？
            </Link>
            <Link
              underline="hover"
              sx={{ fontSize: "16px" }}
              onClick={() => {
                sendConstructEventByEventParams("reset_password_page", {
                  click: "register",
                });
                props.setNowPage(props.pages[2]);
              }}
            >
              registered
            </Link>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}
