// home首页
import React, { useState } from 'react'
import { Grid } from '@mui/material'
// 
import { PageList, ServiceList } from './page-list'
// 
import SideMenu from '../../components/side-menu'
import Navbar from '../../components/navbar'
// 
import MyVideos from './my-videos'
import Background from './background'
import Trash from './trash'
import Setting from './setting'


const HomeContext = React.createContext()
export { HomeContext }

// main
export default function Home() {

    const [page, setPage] = useState('videos')//当前的页面类别
    const [isOpenClassroomDrawer, setIsOpenClassroomDrawer] = useState(false)//是否打开课堂抽屉
    const [classroomList, setClassroomList] = useState([])//课堂列表
    let [globalVideoKey, setGlobalVideoKey] = useState()//全局存储当前播放视频的key值
    let [isLoading, setIsLoading] = useState(false)//是否正在加载


    return (
        <HomeContext.Provider value={{ page, setPage,isOpenClassroomDrawer, setIsOpenClassroomDrawer,classroomList, setClassroomList,globalVideoKey, setGlobalVideoKey ,isLoading, setIsLoading}}>
             <Grid
            container
            sx={{
                width: '100vw',
                height: '100vh',
            }}
        >
  
 
            {/* 侧边栏菜单 */}
            <SideMenu
                page={page}
                setPage={setPage}
                PageList={PageList}
                ServiceList={ServiceList}
            />
            <Grid
                item
                xs
                container
                sx={{
                    flexDirection: 'column',
                }}
            >
                {/* 头部导航栏 */}
                <Navbar />
                {page === 'videos' && <MyVideos isOpenClassroomDrawer={isOpenClassroomDrawer} setIsOpenClassroomDrawer={setIsOpenClassroomDrawer} classroomList={classroomList} />}
                {page === 'background' && <Background />}
                {page === 'trash' && <Trash />}
                {page === 'setting' && <Setting />}
            </Grid>
        </Grid>
        </HomeContext.Provider>
       
    )
}
