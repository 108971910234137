/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useContext } from "react";
import { Box, Grid } from "@mui/material";
import TextButton from "../../../components/ui/button/text-button";
import SyncIcon from "@mui/icons-material/Sync";
import TimePeriod from "./time-period";
import VideoControls from "../../../components/video-controls";
//
import { randomKey } from "../../../utils/random";
import { useMeasure } from "react-use";
import { GlobalContext } from "../../../utils/GlobalContext";
import newFFmpegFactory from "../../../utils/ffmpeg/newFFmpeg";
export default function SplitPage({ setToolState, videoSrc, videoDuration, isAddSubtitleLoading }) {
  let { globalCurrentVideo, setGlobalCurrentVideo } = useContext(GlobalContext);

  const [timeLineRef, timeLineCD] = useMeasure();
  // init 宽度
  const [initWidth, setInitWidth] = React.useState(0);

  const videoRef = React.useRef(null);
  const timeBarRef = React.useRef(null);
  // 是否可以裁剪
  const [isSplit, setIsSplit] = React.useState(false);
  // 是否可以删除时间段
  const [isDelete, setIsDelete] = React.useState(false);
  // 活跃的时间段id
  const [activeId, setActiveId] = React.useState(0);
  // 裁剪点位置
  const [splitPosition, setSplitPosition] = React.useState(null);
  // 裁剪数据
  let [splitData, setSplitData] = React.useState([]);
  // 一秒视频的宽度
  const secondsTimeWidth = useRef(0);
  const [redoList, setRedoList] = React.useState([]);
  const [undoList, setUndoList] = React.useState([]);
  const [isSplitSaving, setIsSplitSaving] = React.useState(false);
  // 切割事件
  const splitHandle = React.useCallback(() => {
    if (!splitPosition) return;
    // 获取当前activeId的数据
    const activeData = splitData.find((item) => item.id === activeId);
    const activeIndex = splitData.findIndex((item) => item.id === activeId);
    if (!activeData || activeIndex < 0) return;

    const newLength = splitPosition - activeData.position - 4;
    // 更新当前activeId的数据
    const newData = splitData.map((item) => {
      if (item.id === activeId) {
        return {
          ...item,
          length: newLength,
        };
      }
      return item;
    });

    // 添加新的数据
    const newId = randomKey();
    // * 判断数据是否合理
    if (activeData.length - newLength <= 0) return;
    // 在指定位置添加新的数据
    const newSplitData = [
      ...newData.slice(0, activeIndex + 1),
      {
        id: newId,
        position: splitPosition,
        length: activeData.length - newLength,
      },
      ...newData.slice(activeIndex + 1),
    ];
    // *undo redo
    undoList.push(newSplitData);
    setUndoList(undoList);
    setRedoList([]);

    // 更新数据
    setSplitData(newSplitData);
  }, [activeId, splitData, splitPosition]);

  // 删除切割的时间段
  const deleteSplit = React.useCallback(() => {
    // 删除当前activeId的数据
    const newSplitData = splitData.filter((item) => item.id !== activeId);
    // *undo redo
    undoList.push(newSplitData);
    setUndoList(undoList);
    setRedoList([]);
    // 更新数据
    setSplitData(newSplitData);
  }, [activeId, splitData]);

  // 初始化裁剪数据
  useEffect(() => {
    const timeBarWidth = timeBarRef.current.offsetWidth;
    // 初始化裁剪数据
    const id = randomKey();
    splitData = [
      {
        id,
        position: 0,
        length: timeBarWidth,
      },
    ];

    // 初始化存储 undo
    setUndoList([splitData]);
    setSplitData([...splitData]);
    setActiveId(id);

    // 初始化一秒视频长度
    // secondsTimeWidth.current = timeBarWidth / videoRef.current.duration
    secondsTimeWidth.current = timeBarWidth / 10;
  }, []);

  useEffect(() => {
    if (!splitPosition) return;
    setIsSplit(true);
  }, [splitPosition]);

  useEffect(() => {
    if (!activeId) return;

    if (splitData.length > 1) setIsDelete(true);
    if (splitData.length === 1) setIsDelete(false);
  }, [splitData]);

  useEffect(() => {
    if (!initWidth) {
      setInitWidth(timeLineCD.width);
    }
  }, [timeLineCD]);
  return (
    <Grid
      xs
      item
      sx={{
        width: "100%",
        height: "calc(100% - 65px)",
      }}
    >
      <Grid
        container
        sx={{
          width: "100%",
          height: "calc(100% - 100px)",
          backgroundColor: "#000",
          position: "relative",
        }}
      >
        <video
          ref={videoRef}
          src={videoSrc}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
        <VideoControls videoRef={videoRef} videoDuration={videoDuration} />
      </Grid>
      {/* 工具条 */}
      <Grid
        container
        sx={{
          height: "78px",
          width: "100%",
          padding: "0px 28px",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* 左侧 */}
        <Grid
          container
          sx={{
            height: "36px",
            width: "auto",
          }}
        >
          <TextButton
            onClick={splitHandle}
            disabled={!isSplit || isSplitSaving}
            title="Split at"
            icon="editor_split"
            style={{
              marginRight: "16px",
            }}
          />
          <TextButton
            onClick={deleteSplit}
            disabled={!isDelete || isSplitSaving}
            title="Delete clip"
            icon="delete"
          />
          <Box
            sx={{
              width: "1px",
              height: "36px",
              backgroundColor: "#d7d5dd",
              margin: "0px 16px",
            }}
          />
          {/* split 保存按钮  */}
          <TextButton
            disabled={isSplitSaving}
            loading={isSplitSaving}
            loadingImg={<SyncIcon className="rotate-element" />}
            title="Save"
            icon="save"
            onClick={async () => {
              setIsSplitSaving(true);

              /**
               *
               * @description: 定时器检测字幕是否在保存
               *
               */

              let isAddSubtitleLoadingIntervalID = setInterval(async () => {
                if (isAddSubtitleLoading) return;
                /**
                 *
                 * @description: 字幕保存已经完成或者没有在保存
                 *
                 * 进行 split
                 *
                 */
                clearInterval(isAddSubtitleLoadingIntervalID);
                /**
                 *
                 * @description: 判断是否需要split 保存
                 *
                 * 不需要保存
                 *
                 */
                if (splitData.length === 1 && undoList.length === 1) {
                  setToolState("play");
                  return;
                } else {
                  // 进行保存
                  // 将 spilt 中的长度数据转换为百分比
                  // 获取当前真实的宽度
                  const realWidthSplitData = splitData.map((item) => {
                    return {
                      ...item,
                      position: item.position * (timeLineCD.width / initWidth),
                      length: item.length * (timeLineCD.width / initWidth),
                    };
                  });

                  // 转换为百分比
                  const percentageSplitData = realWidthSplitData.map((item) => {
                    return {
                      ...item,
                      position: item.position / timeLineCD.width,
                      length: item.length / timeLineCD.width,
                    };
                  });

                  // 转换为时间
                  const timeSplitData = percentageSplitData.map((item) => {
                    let floorNumPosition = Math.floor(item.position * videoDuration);
                    let floorNumLength = Math.floor(item.length * videoDuration);
                    if (floorNumLength <= 0) return null;
                    return {
                      ...item,
                      position: floorNumPosition,
                      length: floorNumLength,
                    };
                  });

                  // 进行数组裁剪
                  const filterNullSplitData = timeSplitData.filter((item) => item !== null);

                  // // 生成命令
                  let videoFile = globalCurrentVideo.videoFile;

                  let trimVideoFFmpegCommandRes = await newFFmpegFactory.trimVideoFFmpegCommand(
                    filterNullSplitData,
                  );

                  let splitVideoRes = await newFFmpegFactory.splitVideo(
                    videoFile,
                    trimVideoFFmpegCommandRes,
                  );
                  globalCurrentVideo.videoFile = splitVideoRes;
                  setGlobalCurrentVideo({ ...globalCurrentVideo });

                  /**
                   *
                   * @description: 关闭 split 操作空间
                   *
                   */
                  setToolState("play");

                  //#region 旧版本

                  // const worker = new Worker("/core/ffmpeg-worker.js");
                  // worker.postMessage({
                  //   type: "trimVideoConcat",
                  //   videoFile,
                  //   ffmpeg_command: trimVideoFFmpegCommandRes,
                  // });

                  // worker.onmessage = (event) => {
                  //   const { type } = event.data;
                  //   if (type === "trimVideoConcat") {
                  //     const { file } = event.data;
                  //     globalCurrentVideo.videoFile = file;
                  //     setGlobalCurrentVideo({ ...globalCurrentVideo });

                  //     /**
                  //      *
                  //      * @description: 关闭 split 操作空间
                  //      *
                  //      */
                  //     setToolState("play");
                  //   }
                  // };
                  //#endregion 旧版本
                }
              }, 2000);
            }}
            style={{
              marginRight: "16px",
            }}
          />
          <TextButton onClick={() => setToolState("play")} title="Cancel" icon="cancel" />
        </Grid>

        {/* 右侧 */}
        <Grid
          container
          sx={{
            height: "36px",
            width: "auto",
          }}
        >
          <TextButton
            onClick={() => {
              const newUndoList = [...undoList];
              const newRedoList = [...redoList];
              newRedoList.push(newUndoList.pop());
              setUndoList(newUndoList);
              setRedoList(newRedoList);
              setSplitData(newUndoList[newUndoList.length - 1]);
            }}
            disabled={undoList.length <= 1 || isSplitSaving}
            title="Undo"
            // icon='undo'
          />
          <Box
            sx={{
              width: "1px",
              height: "36px",
              backgroundColor: "#d7d5dd",
              margin: "0px 16px",
            }}
          />
          <TextButton
            disabled={redoList.length === 0 || isSplitSaving}
            onClick={() => {
              const newUndoList = [...undoList];
              const newRedoList = [...redoList];
              newUndoList.push(newRedoList.pop());
              setUndoList(newUndoList);
              setRedoList(newRedoList);
              setSplitData(newUndoList[newUndoList.length - 1]);
            }}
            title="Redo"
            // icon='redo'
          />
        </Grid>
      </Grid>
      {/* 时间条 */}
      <Box
        sx={{
          width: "100%",
          height: "48px",
          padding: "0px 28px",
          boxSizing: "border-box",
        }}
      >
        <Grid
          ref={timeBarRef}
          container
          sx={{
            width: "100%",
            height: "100%",
            background: "#f2f1f3",
            border: "2px solid #d7d5dd",
            borderRadius: "8px",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          <Box
            ref={timeLineRef}
            sx={{
              width: "100%",
              height: "100%",
            }}
          >
            {splitData.map((item, index) => {
              return (
                <TimePeriod
                videoRef={videoRef}
                videoDuration={videoDuration}
                  timeLineCD={timeLineCD}
                  initWidth={initWidth}
                  key={index}
                  position={item.position - 2}
                  length={item.length}
                  minLength={secondsTimeWidth.current}
                  maxLength={
                    index === splitData.length - 1
                      ? timeBarRef.current.offsetWidth - 2
                      : splitData[index + 1].position - 6
                  }
                  minPosition={
                    index === 0
                      ? -2
                      : splitData[index - 1].position + splitData[index - 1].length + 2
                  }
                  update={(id, data) => {
                    const newData = splitData.map((item) => {
                      if (item.id === id) {
                        return {
                          ...item,
                          ...data,
                        };
                      }
                      return item;
                    });
                    splitData = newData;
                    setSplitData([...splitData]);
                  }}
                  mouseUp={() => {
                    undoList.push(splitData);
                    setUndoList([...undoList]);
                    document.onmouseup = null;
                    setRedoList([]);
                  }}
                  id={item.id}
                  isActive={activeId === item.id}
                  setActiveId={setActiveId}
                  setSplitPosition={setSplitPosition}
                />
              );
            })}
          </Box>

          {/* 红色裁剪点 */}
          {splitPosition && (
            <Box
              sx={{
                width: "3px",
                height: "78px",
                position: "absolute",
                left: splitPosition * (timeLineCD.width / initWidth) + "px",
                top: "-15px",
                backgroundColor: "#F7652F",
                pointerEvents: "none",
                zIndex: "100",
              }}
            />
          )}
        </Grid>
      </Box>
    </Grid>
  );
}
