import localforage from "localforage";
import JSZip from "jszip";
import newFFmpegFactory from "../ffmpeg/newFFmpeg";

const downloadAll = ({ selectedKeys, setSelectedKeys }) => {
  const downloadSelectPromiseToast = new Promise(async (resolve) => {
    let fileWebm = [];
    let fileName = [];
    let zip = new JSZip();

    for (let index = 0; index < selectedKeys.length; index++) {
      let element = selectedKeys[index];
      let videoInfo = await localforage.getItem(element);
      fileWebm.push(
        new File(videoInfo.file, `${videoInfo.videoTitle}.webm`, { type: "video/webm" }),
      );
      fileName.push(`${videoInfo.videoTitle}`);
    }

    let webmArrayToMp4Res = await newFFmpegFactory.webmArrayToMp4(fileWebm);
    for (let index = 0; index < webmArrayToMp4Res.length; index++) {
      let videoItem = webmArrayToMp4Res[index];
      zip.file(`${fileName[index]}.mp4`, videoItem);
    }

    zip.generateAsync({ type: "blob" }).then(async (content) => {
      let url = window.URL.createObjectURL(
        new File([content], "videos.zip", { type: "application/zip" }),
      );
      let a = document.createElement("a");
      a.href = url;
      a.download = "videos.zip";
      a.click();
      a.remove();
      URL.revokeObjectURL(url);
    });
    setSelectedKeys([]);
    resolve("success");

    //#region 旧版本
    // /**
    //  *
    //  *
    //  * @description: 连接 worker
    //  *
    //  *
    //  */
    // const worker = new Worker("/core/ffmpeg-worker.js");
    // worker.postMessage({
    //   type: "webmArrayToMp4",
    //   videoList: fileWebm,
    // });
    // worker.onmessage = (event) => {
    //   if (event.data.type === "webmArrayToMp4") {

    //   }
    // };
    //#region 旧版本
  });

  window.toast.promise(downloadSelectPromiseToast, {
    pending: "Promise is pending",
    success: "Download success 👌",
    error: "Download defeat 🤯",
  });
};

export default downloadAll;
