// 文件大小单位
const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
// 文件大小进位阈值
const threshold = 1024;
// 文件大小正则表达式,10MB=>[10,MB]
const fileSizeRegexp = /\d+|[a-z]+/gi;
//文件大小格式化 fileBytesValue文件大小 'number' decimals保留小数位数(默认值为2) 'number'
export function fileSizeFormat(fileBytesValue, decimals = 2) {
  // console.log(fileBytesValue);
  if (!fileBytesValue || fileBytesValue === undefined) {
    return null;
  }
  if (fileBytesValue === 0)
    // 如果文件大小为0 返回0字节
    return "0 Bytes";
  // 检测保留小数位数
  const decimal = decimals < 0 ? 0 : decimals;
  // 计算自然对数获取单位级别 文件大小自然对数/阈值自然对数(向下取整)
  const index = Math.floor(Math.log(fileBytesValue) / Math.log(threshold));
  // 返回格式化后的文件大小
  return (
    //格式化 格式化后的文件大小=文件大小/单位大小(阈值的单位级别次幂)
    parseFloat((fileBytesValue / Math.pow(threshold, index)).toFixed(decimal)) +
    " " +
    sizes[index]
  );
}
export function computeFileSize(fileSizeString = "") {
  const [quantity, unit] = fileSizeString
    .trim()
    .toUpperCase()
    .match(fileSizeRegexp);
  return quantity * Math.pow(threshold, sizes.indexOf(unit));
}
