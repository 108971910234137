import googleLogin from "../../utils/GoogleOAuth2/googleLogin";
import saveToDrive from "../../utils/GoogleOAuth2/SaveFileToDrive";
import localforage from "localforage";
import newFFmpegFactory from "../../utils/ffmpeg/newFFmpeg";
import { decryptText } from "../../utils/GoogleOAuth2/AES";

const ToolSection = (page) => {
  if (page === "videos")
    return [
      {
        title: "Rename",
        onClick: ({ setIsEditVideoTitle, videoItemUUID, setAnchorEl }) => {
          setIsEditVideoTitle(true);
          setAnchorEl(null);
          setTimeout(() => {
            document.getElementById(videoItemUUID + "rename").focus();
          }, 0);
        },
      },
      {
        title: "Upload to Google Drive",
        onClick: async ({
          videoItemUUID,
          setAnchorEl,
          globalUploadFileToDrive,
          setGlobalUploadFileToDrive,
        }) => {
          /**
           *
           *
           * @description: 关闭弹窗
           *
           * 打开上传进度弹窗
           *
           *
           */
          setAnchorEl(null);
          document.getElementById("upload").click();

          let googleLoginRes = await googleLogin();

          if (!googleLoginRes) return;
          /**
           *
           * @description: 从 localforage 中读取文件
           *
           * 获取文件
           *
           * 连接 worker
           *
           * 转换为 MP4
           *
           */
          let videoItem = await localforage.getItem(videoItemUUID);
          let fileObject = new File(videoItem.file, videoItem.videoTitle, {
            type: "video/webm",
          });
          let webmToMp4Res = await newFFmpegFactory.webmToMp4(fileObject);

          let fileData = webmToMp4Res;
          globalUploadFileToDrive.push({
            videoTitle: videoItem.videoTitle,
            videoSize: fileData.size,
            progress: 0,
          });
          setGlobalUploadFileToDrive([...globalUploadFileToDrive]);
          const renamedFile = new File([fileData], videoItem.videoTitle, { type: "video/mp4" });

          /**
           *
           * @description: 将文件上传到 google drive
           *
           */
          saveToDrive(renamedFile, globalUploadFileToDrive, setGlobalUploadFileToDrive);

          //#region旧版本
          // let worker = new Worker("/core/ffmpeg-worker.js");
          // worker.postMessage({
          //   type: "webmToMp4",
          //   file: fileObject,
          // });
          // worker.onmessage = async (e) => {
          //   if (e.data.type === "webmToMp4") {
          //     let fileData = e.data.file;
          //     globalUploadFileToDrive.push({
          //       videoTitle: videoItem.videoTitle,
          //       videoSize: fileData.size,
          //       progress: 0,
          //     });
          //     setGlobalUploadFileToDrive([...globalUploadFileToDrive]);
          //     const renamedFile = new File([fileData], videoItem.videoTitle, { type: "video/mp4" });

          //     /**
          //      *
          //      * @description: 将文件上传到 google drive
          //      *
          //      */
          //     saveToDrive(renamedFile, globalUploadFileToDrive, setGlobalUploadFileToDrive);
          //   }
          // };
          //#endregion旧版本
        },
      },
      {
        title: "Upload to Google Classroom",
        onClick: async ({videoItemUUID, setAnchorEl, setIsOpenClassroomDrawer, setClassroomList ,   globalVideoKey, setGlobalVideoKey}) => {
          setAnchorEl(null);

          globalVideoKey = videoItemUUID;
          setGlobalVideoKey(globalVideoKey);

          let googleLoginRes = await googleLogin();
          if (!googleLoginRes) return;

          /**
           *
           *
           * @description: 获取 token
           *
           * 解密
           */

          let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
          googleAuthInfo = JSON.parse(googleAuthInfo);
          googleAuthInfo.access_token = decryptText(googleAuthInfo.access_token);

          // 发送请求
          let getClassroomCoursesRes = window.getClassroomCourses(googleAuthInfo.access_token);

          window.toast.promise(getClassroomCoursesRes, {
            pending: "Obtaining course data!",
            success: "Successfully obtained 👌",
            error: "Acquisition failed 🤯",
          });

          getClassroomCoursesRes.then((res) => {
            /**
             *
             *
             * @description: 判断是否有课程
             *
             *
             */
            if (res.courses) {
              setClassroomList(res.courses);
            } else {
              setClassroomList([]);
            }
            setIsOpenClassroomDrawer(true);
          });
        },
      },
      {
        title: "Download",
        onClick: async ({
          videoItemUUID,
          setAnchorEl,
          globalUploadFileToDrive,
          setGlobalUploadFileToDrive,
        }) => {
          const downloadOnePromiseToast = new Promise(async (resolve) => {
            /**
             *
             *
             * @description: 下载源文件,mp4
             *
             * 转换为 mp4
             *
             * 连接 worker
             *
             *
             */

            let videoItem = await localforage.getItem(videoItemUUID);
            let fileObject = new File(videoItem.file, videoItem.videoTitle, {
              type: "video/webm",
            });

            let webmToMp4Res = await newFFmpegFactory.webmToMp4(fileObject);
            let fileData = webmToMp4Res;
            const renamedFile = new File([fileData], videoItem.videoTitle, {
              type: "video/mp4",
            });
            /**
             *
             * @description: 下载
             *
             */
            let a = document.createElement("a");
            a.href = URL.createObjectURL(renamedFile);
            a.download = videoItem.videoTitle;
            a.click();
            resolve("success");

            //#region 旧版本
            // let worker = new Worker("/core/ffmpeg-worker.js");
            // worker.postMessage({
            //   type: "webmToMp4",
            //   file: fileObject,
            // });
            // worker.onmessage = async (e) => {
            //   if (e.data.type === "webmToMp4") {
            //     let fileData = e.data.file;
            //     const renamedFile = new File([fileData], videoItem.videoTitle, {
            //       type: "video/mp4",
            //     });
            //     /**
            //      *
            //      * @description: 下载
            //      *
            //      */
            //     let a = document.createElement("a");
            //     a.href = URL.createObjectURL(renamedFile);
            //     a.download = videoItem.videoTitle;
            //     a.click();
            //     resolve("success");
            //   }
            // };
            //#region 旧版本
          });
          window.toast.promise(downloadOnePromiseToast, {
            pending: "Promise is pending",
            success: "Download success 👌",
            error: "Download defeat 🤯",
          });
        },
      },
      {
        title: "Delete",
        onClick: async ({ videoItemUUID, setVideosData, setAnchorEl }) => {
          setAnchorEl(null);

          /**
           *
           *
           * @description: 将 uuid 从视频的列表中转移到回收站列表中
           *
           *
           */
          let deleteVideoListRes = await localforage.getItem("trashList");
          /**
           *
           *
           * @description: 第一次
           *
           *
           */
          if (!deleteVideoListRes) {
            // 添加
            deleteVideoListRes = [videoItemUUID];
            await localforage.setItem("trashList", deleteVideoListRes);

            // 删除
            let videosRes = await localforage.getItem("videos");
            console.log("[ videosRes ] >", videosRes);

            for (let index = 0; index < videosRes.length; index++) {
              if (videosRes[index] === videoItemUUID) {
                videosRes.splice(index, 1);
                await localforage.setItem("videos", videosRes);
                setVideosData(videosRes);
                break;
              }
            }
          } else {
            /**
             *
             *
             * @description: 第n 次
             *
             *
             */
            deleteVideoListRes.push(videoItemUUID);
            await localforage.setItem("trashList", deleteVideoListRes);

            // 删除
            let videosRes = await localforage.getItem("videos");

            for (let index = 0; index < videosRes.length; index++) {
              if (videosRes[index] === videoItemUUID) {
                videosRes.splice(index, 1);
                await localforage.setItem("videos", videosRes);
                setVideosData(videosRes);
                break;
              }
            }
          }
        },
      },
    ];
  else
    return [
      {
        title: "Restore",
        onClick: async ({ videoItemUUID, setTrashesData, setAnchorEl }) => {
          setAnchorEl(null);
          let deleteVideoListRes = await localforage.getItem("trashList");

          //   恢复到视频列表
          let videosRes = await localforage.getItem("videos");
          videosRes.push(videoItemUUID);
          await localforage.setItem("videos", videosRes);

          for (let index = 0; index < deleteVideoListRes.length; index++) {
            if (deleteVideoListRes[index] === videoItemUUID) {
              deleteVideoListRes.splice(index, 1);
              await localforage.setItem("trashList", deleteVideoListRes);
              setTrashesData(deleteVideoListRes);
              break;
            }
          }
        },
      },
      {
        title: "Delete",
        onClick: async ({ videoItemUUID, setTrashesData, setAnchorEl }) => {
          /**
           *
           *
           * @description: 永久删除
           *
           *
           */
          let deleteVideoListRes = await localforage.getItem("trashList");

          setAnchorEl(null);

          // 真正的删除视频的数据
          await localforage.removeItem(videoItemUUID);

          for (let index = 0; index < deleteVideoListRes.length; index++) {
            if (deleteVideoListRes[index] === videoItemUUID) {
              deleteVideoListRes.splice(index, 1);
              await localforage.setItem("trashList", deleteVideoListRes);
              setTrashesData(deleteVideoListRes);
              break;
            }
          }
        },
      },
    ];
};

export default ToolSection;
