import { Box, Slider, Button } from "@mui/material";
import { useMeasure } from "react-use";
import React, { useState, useRef } from "react";

export default function VideoPlay({
  pageSize,
  videoInformation,
  playRateList,
  watermarkInformation,
}) {
  let videoRef = useRef(null);
  // 自己的视频信息
  let [selfVideoInformation, setSelfVideoInformation] = useState({
    ...videoInformation,
    videoCurrentTime: 0,
    isPlay: false,
    playVideoButton: "/images/editor-recorder/play.svg",
  });
  // 设置视频的播放进度
  const changeVideoCurrentTime = (value) => {
    videoRef.current.currentTime = (selfVideoInformation.videoDuration * value) / 100;
    setSelfVideoInformation({
      ...selfVideoInformation,
      videoCurrentTime: (selfVideoInformation.videoDuration * value) / 100,
    });
  };
  let [isOpenPlayRatePopover, setIsOpenPlayRatePopover] = useState(false);
  let [videoPlayRate, setVideoPlayRate] = useState(1);

  const closePlayRatePopover = () => {
    setIsOpenPlayRatePopover(false);
  };
  const openPlayRatePopover = () => {
    setIsOpenPlayRatePopover(true);
  };
  const videoPlayRateFunction = (number) => {
    videoRef.current.playbackRate = number;
    setVideoPlayRate(number);
    setIsOpenPlayRatePopover(false);
  };
  // **----------------**
  //   音量
  let [isOpenVideoVolume, setIsOpenVideoVolume] = useState(false);
  let [videoVolume, setVideoVolume] = useState(50);
  const openVideoVolume = () => {
    setIsOpenVideoVolume(true);
  };
  const closeVideoVolume = () => {
    setIsOpenVideoVolume(false);
  };
  const videoVolumeFunction = (newValue) => {
    videoRef.current.volume = newValue / 100;
    setVideoVolume(newValue);
  };
  //   播放按钮
  //   播放按钮的设置
  const videoPlayButton = () => {
    if (selfVideoInformation.isPlay) {
      // 清除掉监听事件
      videoRef.current.ontimeupdate = null;
      videoRef.current.onended = null;
      //暂停
      selfVideoInformation.isPlay = false;
      videoRef.current.pause();
      selfVideoInformation.playVideoButton = "/images/editor-recorder/play.svg";
    } else {
      videoRef.current.ontimeupdate = () => {
        selfVideoInformation.videoCurrentTime = videoRef.current.currentTime;
        setSelfVideoInformation({
          ...selfVideoInformation,
        });
      };
      videoRef.current.onended = () => {
        selfVideoInformation.videoCurrentTime = 0;
        selfVideoInformation.isPlay = false;
        videoRef.current.currentTime = 0;
        selfVideoInformation.playVideoButton = "/images/editor-recorder/play.svg";
        setSelfVideoInformation({
          ...selfVideoInformation,
        });
      };
      // 开始播放
      selfVideoInformation.isPlay = true;
      videoRef.current.play();
      selfVideoInformation.playVideoButton = "/images/editor-recorder/pause.svg";
    }
    setSelfVideoInformation({
      ...selfVideoInformation,
    });
  };

  // !定位视频的位置
  // 监听视频宽度的变化
  const [positioningRef, positioningRefSize] = useMeasure();
  let [positioningCover, setPositioningCover] = useState({
    videoWidth: 1,
    videoHeight: 1,
  });
  React.useEffect(() => {
    // 默认以高度为基准
    let videoHeight = positioningRefSize.height;
    let videoProportion = videoInformation.videoWidth / videoInformation.videoHeight;
    let videoWidth = videoHeight * videoProportion;
    if (videoWidth > positioningRefSize.width) {
      // 以宽度为基准
      videoWidth = positioningRefSize.width;
      videoHeight = videoWidth / videoProportion;
    }
    setPositioningCover({
      videoWidth,
      videoHeight,
    });
  }, [positioningRefSize, videoInformation]);
  return (
    <Box
      sx={{
        position: "relative",
        width: pageSize.width <= 1095 ? "63%" : "calc(100% - 385px)",
        height: "100%",
        background: "#000000",
      }}
    >
      <video ref={videoRef} width="100%" height="100%" src={selfVideoInformation.videoSrc}></video>
      {/* 定位视频的位置  */}
      <Box
        ref={positioningRef}
        sx={{
          position: "absolute",
          top: "0px",
          left: "0px",
          zIndex: "9",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "none",
        }}
      >
        <Box
          sx={{
            width: positioningCover.videoWidth,
            height: positioningCover.videoHeight,
            position: "relative",
          }}
        >
          {/* 放 水印  */}
          {watermarkInformation.isNeed && (
            <Box
              sx={{
                opacity: watermarkInformation.opacity / 100,
                fontSize: (positioningCover.videoWidth / 888) * 16 + "px",
                position: "absolute",
                color: "rgb(222, 224, 227)",
                top:
                  watermarkInformation.position === 1 || watermarkInformation.position === 0
                    ? 30
                    : null,
                bottom:
                  watermarkInformation.position === 2 || watermarkInformation.position === 3
                    ? 30
                    : null,
                left:
                  watermarkInformation.position === 2 || watermarkInformation.position === 0
                    ? 30
                    : null,
                right:
                  watermarkInformation.position === 1 || watermarkInformation.position === 3
                    ? 30
                    : null,
              }}
            >
              {watermarkInformation.text}
            </Box>
          )}
        </Box>
      </Box>
      {/* 底部编辑控制区域  */}
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          bottom: "0px",
          height: "65px",
        }}
      >
        <Slider
          sx={{
            padding: "3px 0px",
          }}
          size="small"
          value={
            isNaN(
              (selfVideoInformation.videoCurrentTime / selfVideoInformation.videoDuration) * 100,
            )
              ? 0
              : (selfVideoInformation.videoCurrentTime / selfVideoInformation.videoDuration) * 100
          }
          onChange={(event, value) => {
            changeVideoCurrentTime(value);
          }}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            "& button": {
              color: "#ffffff",
            },
          }}
        >
          {/* 左侧  */}
          <Box>
            <Button onClick={videoPlayButton}>
              <img width={"24px"} src={selfVideoInformation.playVideoButton} alt="play" />
            </Button>
          </Box>
          {/* 右侧  */}
          <Box>
            {/* 倍速  */}
            <Button
              variant="text"
              onMouseLeave={closePlayRatePopover}
              onMouseEnter={openPlayRatePopover}
              sx={{
                position: "relative",
              }}
            >
              {videoPlayRate}x
            </Button>
            {isOpenPlayRatePopover && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: "40px",
                  background: "rgba(0,0,0,0.8)",
                  width: "74px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: "20px 0",
                }}
                onMouseEnter={openPlayRatePopover}
                onMouseLeave={closePlayRatePopover}
              >
                {/* 倍速循环  */}
                {playRateList.map((item, index) => {
                  return (
                    <Button
                      onClick={() => {
                        videoPlayRateFunction(item);
                      }}
                      key={index}
                      sx={{
                        color: "#ffffff",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                    >
                      {item}
                    </Button>
                  );
                })}
              </Box>
            )}
            {/* 音量 */}
            <Button onMouseEnter={openVideoVolume} onMouseLeave={closeVideoVolume}>
              <img src="/images/editor-recorder/volume.svg" alt="volume" />
            </Button>
            {isOpenVideoVolume && (
              <Box
                onMouseEnter={openVideoVolume}
                onMouseLeave={closeVideoVolume}
                sx={{
                  position: "absolute",
                  bottom: "40px",
                  height: "140px",
                  right: "6px",
                  padding: "20px 0",
                  background: "rgba(0,0,0,0.8)",
                  width: "64px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Slider
                  onChange={(event, newValue) => {
                    videoVolumeFunction(newValue);
                  }}
                  sx={{
                    '& input[type="range"]': {
                      WebkitAppearance: "slider-vertical",
                    },
                  }}
                  orientation="vertical"
                  value={videoVolume}
                  aria-label="Temperature"
                />
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
