import * as React from "react";
import Box from "@mui/material/Box";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

// import TryDaysFree from "../component/User/TryDaysFree";
import TryDaysFree from "./SubscribeSelect";

import Dialog from "@mui/material/Dialog";

export default function SubscribeDialogs(props) {
  const [openSubscribe, setOpenSubscribe] = React.useState(false);

  const handleClickOpen = () => {
    setOpenSubscribe(true);
  };

  const handleClose = () => {
    setOpenSubscribe(false);
  };

  return (
    <Box>
      <Box onClick={handleClickOpen}>{props.children}</Box>

      <Dialog open={openSubscribe} onClose={handleClose}>
        <DialogContent>
          <DialogContentText></DialogContentText>
          <TryDaysFree plan={props.plan} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
