//模板数据
const templateList = [
  {
    name: "Template-1",
    url: "background-0.png",
    index: 0,
  },
  {
    name: "Template-2",
    url: "background-1.png",
    index: 1,
  },
  {
    name: "Template-3",
    url: "background-2.png",
    index: 2,
  },
  {
    name: "Template-4",
    url: "background-3.png",
    index: 3,
  },
  {
    name: "Template-5",
    url: "background-4.png",
    index: 4,
  },
  {
    name: "Template-6",
    url: "background-5.png",
    index: 5,
  },
];

const templateTools = [
  {
    icon: "icon-1.png",
    title: "Background",
  },
  {
    icon: "icon-1.png",
    title: "Text layout",
  },
  {
    icon: "icon-1.png",
    title: "Template",
  },
];
export { templateList, templateTools };
