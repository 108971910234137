import React from 'react'
import { Box, Grid } from '@mui/material'

export default function VideoSpeed({ setVideoSpeed, style, videoRef }) {
  const speedOptions = [0.8, 1, 1.2, 1.5, 1.7, 2, 2.5];
  return (
    <Grid
      container
      sx={{
        width: "auto",
        padding: "16px",
        alignItems: "center",
        backgroundColor: "#333333",
        borderRadius: "8px",
        ...style,
      }}
    >
      {speedOptions.map((item, index) => (
        <Box
          key={index}
          sx={{
            padding: "5px 12.8px",
            borderRadius: "40% / 100%",
            cursor: "pointer",
            ":hover": {
              backgroundColor: "#5c5c5c",
            },
          }}
        >
          <span
            onClick={() => {
              setVideoSpeed(item * 1);
              videoRef.speed(item * 1);
            }}
            style={{
              fontSize: "14px",
              fontWeight: "700",
              color: "#f7f7f8",
            }}
          >
            {item}x
          </span>
        </Box>
      ))}
    </Grid>
  );
}
