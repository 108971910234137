import {  fetchFile } from "@ffmpeg/util";
import newFFmpegFactory from "./newFFmpeg";

const webmToMp4 = async (file) => {
  let ffmpeg = await newFFmpegFactory.loadFFmpeg();

  /**
   *
   *
   * @description: 写入文件
   *
   *
   */

  await ffmpeg.writeFile(`input.webm`, await fetchFile(file));

  /**
   *
   *
   * @description: 执行命令
   *
   *
   */

  await ffmpeg.exec([
    "-i",
    `input.webm`,
    "-c",
    "copy",
    `output.mp4`,
  ]);

  /**
   *
   *
   * @description: 读取文件
   *
   *
   */
    const data = await ffmpeg.readFile("output.mp4");
    const dataFile = new File([data], "output.mp4", { type: "video/mp4" });

    return dataFile;
};

export default webmToMp4;
