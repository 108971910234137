import localforage from "localforage";
const deleteAll = async (selectedKeys,setVideosData,setSelectedKeys) => {
  console.log(selectedKeys);

  let trashList = await localforage.getItem("trashList");
  let videos = await localforage.getItem("videos");

  /**
   *
   *
   * @description: 第一次删除
   *
   *
   */
  if (!trashList) {
    trashList = selectedKeys;
    await localforage.setItem("trashList", trashList);

    // 删除
    for (let index = 0; index < videos.length; index++) {
      for (let index2 = 0; index2 < selectedKeys.length; index2++) {
        if (videos[index] === selectedKeys[index2]) {
          videos.splice(index, 1);
          await localforage.setItem("videos", videos);
          setVideosData(videos);
          break;
        }
      }
    }
    // 关闭底部工具栏
    setSelectedKeys([]);
  } else {
    /**
     *
     *
     * @description: 第n 次删除
     *
     *
     */
    trashList.push(...selectedKeys);
    await localforage.setItem("trashList", trashList);

    // 删除
    for (let index = 0; index < videos.length; index++) {
      for (let index2 = 0; index2 < selectedKeys.length; index2++) {
        if (videos[index] === selectedKeys[index2]) {
          videos.splice(index, 1);
          await localforage.setItem("videos", videos);
          setVideosData(videos);
          break;
        }
      }
    }
    // 关闭底部工具栏
    setSelectedKeys([]);
  }
};

export default deleteAll;
