import React, { useEffect, useContext } from "react";
import { Box, Grid } from "@mui/material";
import Icon from "../ui/icon";
//
import ToolDialog from "../dialog/tool-dialog";
//
import { secToTime } from "../../utils/editor-recorder/handleTime";
import VideosStorage from "../../storage/videos-storage";
import ToolSection from "./tool-section";
import { GlobalContext } from "../../utils/GlobalContext";
import googleLogin from "../../utils/GoogleOAuth2/googleLogin";
import { HomeContext } from "../../screen/home";

export default function VideoCard({ item, selectVideo, state, setVideosData, setTrashesData,selectedKeys }) {
  // 首页的全局状态
  const { page } = useContext(HomeContext);

  //  视频数据
  const [videoInfo, setVideoInfo] = React.useState({});
  // 判断是否触摸
  const [isTouch, setIsTouch] = React.useState(false);
  // 判断是否选中
  const [isSelect, setIsSelect] = React.useState(false);
  // 文件名称
  const [videoTitle, setVideoTitle] = React.useState("");
  // 是否编辑文件名称
  const [isEditVideoTitle, setIsEditVideoTitle] = React.useState(false);
  // 全局存储当前播放视频的key值
  const { setPlayKey } = React.useContext(GlobalContext);
  const [videoUUID, setVideoUUID] = React.useState("");
  React.useEffect(() => {
    // 获取视频信息
    const getVideoInfo = async () => {
      const info = await VideosStorage.read(item);
      if (info) {
        setVideoInfo(info);
        setVideoTitle(info.videoTitle);
      }
    };

    getVideoInfo();
  }, [item]);

  /**
   *
   * @description: 每次 window onload 的时候需要触发 google login
   *
   */
  useEffect(() => {
    /**
     *
     * @description: 判断 url 是否存在 code
     * 存在则表示 是 google login 重定向,需要获取刷新 token
     *
     */
    let params = new URLSearchParams(window.location.search);
    if (params.get("code")) {
      googleLogin();
    }
  }, []);


  useEffect(()=> {
    if(selectedKeys?.length === 0) setIsSelect(false)
  },[selectedKeys])

  return (
    <Box
      onMouseEnter={() => {
        if (page === "trash") return;
        setIsTouch(true);
      }}
      onMouseLeave={() => setIsTouch(false)}
      sx={{
        boxSizing: "border-box",
        width: "100%",
        borderRadius: "8px",
        border: "2px solid #d3d1d9",
        overflow: "hidden",
        cursor: "pointer",
        position: "relative",
        ":hover": {
          border: "2px solid #2F65F7",
        },
      }}
    >
      {/* 勾选按钮 */}
      {(state === "selected" || isTouch) && (
        <Grid
          onClick={() => {
            if (page === "trash") return;
            if (!isSelect) {
              selectVideo(item, "add");
            }
            if (isSelect) {
              selectVideo(item, "remove");
            }
            setIsSelect(!isSelect);
          }}
          container
          sx={{
            width: "18px",
            height: "18px",
            border: "2px solid #fff",
            borderRadius: "4px",
            backgroundColor: isSelect ? "#fff" : "rgba(9, 9, 9, 0.3)",
            position: "absolute",
            top: "8px",
            left: "8px",
            zIndex: 99,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isSelect && <Icon name={"selected"} width={12} height={12} />}
        </Grid>
      )}
      {/* Video Image */}
      <Grid
        container
        onClick={async () => {
          if (state === "selected") {
            if (!isSelect) {
              selectVideo(item, "add");
            }
            if (isSelect) {
              selectVideo(item, "remove");
            }
            setIsSelect(!isSelect);
          } else {
            console.log(item);
            await setPlayKey(item);
            // 页面跳转至editor
            window.location.href = "/editor-recorder";
          }
        }}
        sx={{
          maxWidth: "100%",
          aspectRatio: 1.73,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#e8e8e8",
          position: "relative",
        }}
      >
        {/* 遮盖层 */}
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            top: 0,
            left: 0,
            backgroundColor:
              isTouch || state === "selected" || isSelect
                ? "rgba(9, 9, 9, 0.3)"
                : "rgba(255, 255, 255, 0)",
          }}
        />
        <img
          src={videoInfo.videoCover}
          alt=""
          style={{
            height: "100%",
          }}
        />
      </Grid>
      {/* Video  Information*/}
      <Grid
        container
        sx={{
          width: "100%",
          padding: "0px 10px",
          height: "98px",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <VideoTitle
          state={isEditVideoTitle ? "edit" : "show"}
          setIsEditVideoTitle={setIsEditVideoTitle}
          videoTitle={videoTitle}
          setVideoTitle={setVideoTitle}
          videoUUID={item}
        />
        <Grid
          container
          sx={{
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            height: "32px",
            marginBottom: "8px",
          }}
        >
          <Grid
            container
            item
            xs={6}
            sx={{
              alignItems: "center",
            }}
          >
            <Icon name={"time"} width={18} height={18} />
            <span
              style={{
                fontSize: "12px",
                fontWeight: "400",
                fontFamily: "Roboto",
                color: "#1F1F1F",
                marginLeft: "4px",
              }}
            >
              {secToTime(videoInfo.videoDuration)}
            </span>
          </Grid>
          <ToolDialog
            videoItem={item}
            data={ToolSection(page)}
            icon="more"
            setIsEditVideoTitle={setIsEditVideoTitle}
            setVideosData={setVideosData}
            setTrashesData={setTrashesData}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

// VideoTitle
function VideoTitle({ state, setIsEditVideoTitle, videoTitle, setVideoTitle, videoUUID }) {
  if (state === "show") {
    return (
      <span
        style={{
          width: "100%",
          marginTop: "8px",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Roboto",
          color: "#1F1F1F",
          lineHeight: "24px",
          // 超出两行显示省略号
          overflow: "hidden",
          textOverflow: "ellipsis",
          display: "-webkit-box",
          WebkitBoxOrient: "vertical",
          WebkitLineClamp: 2,
        }}
      >
        {videoTitle}
      </span>
    );
  }
  if (state === "edit") {
    return (
      <input
        id={videoUUID + "rename"}
        type="text"
        value={videoTitle}
        onChange={(e) => setVideoTitle(e.target.value)}
        // 失去焦点
        onBlur={() => setIsEditVideoTitle(false)}
        style={{
          width: "100%",
          marginTop: "8px",
          fontSize: "14px",
          fontWeight: "500",
          fontFamily: "Roboto",
          color: "#1F1F1F",
          lineHeight: "24px",
          border: "2px solid #d3d1d9",
          outline: "none",
          borderRadius: "4px",
          boxSizing: "border-box",
        }}
      />
    );
  }
}
