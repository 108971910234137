import React, { useEffect } from "react";
import { Box, Popover, Grid } from "@mui/material";
import TextEditor from "../../../utils/TextEditor";
import { GlobalContext } from "../../../utils/GlobalContext";
import { templateList } from "../../../data/templatesData";

//Template0
function Template0() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#ffffff",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "100vw",
          height: "305px",
          position: "absolute",
          left: "0px",
          bottom: "0px",
          background: "url(/images/templates/image9.svg)",
          backgroundSize: "cover",
        }}
      />
      <img
        src="/images/templates/image10.svg"
        alt=""
        style={{
          position: "absolute",
          right: "0px",
          top: "50%",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          right: "40px",
          bottom: "32px",
          height: "calc(100vh - 232px)",
          width: "21px",
          borderRadius: "4px",
          backgroundColor: "#4caeaa",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          right: "40px",
          bottom: "32px",
          width: "calc(100vw - 440px)",
          height: "21px",
          borderRadius: "4px",
          backgroundColor: "#4caeaa",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: "40px",
          top: "32px",
          width: "calc(100vw - 440px)",
          height: "21px",
          borderRadius: "4px",
          backgroundColor: "#4caeaa",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          left: "40px",
          top: "32px",
          height: "calc(100vh - 400px)",
          width: "21px",
          borderRadius: "4px",
          backgroundColor: "#4caeaa",
        }}
      />
      <img
        src="/images/templates/image6.svg"
        alt=""
        style={{
          position: "absolute",
          left: "0px",
          top: "0px",
        }}
      />
      <img
        src="/images/templates/image7.svg"
        alt=""
        style={{
          position: "absolute",
          left: "0px",
          bottom: "0px",
        }}
      />
      <img
        src="/images/templates/image8.svg"
        alt=""
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
        }}
      />
      <Box
        sx={{
          width: "612px",
          height: "92px",
          borderRadius: "14px",
          border: "2px solid #9AD6C1",
          position: "absolute",
          left: "270px",
          top: "147px",
          padding: "10px",
        }}
      >
        <TextEditor />
      </Box>
      <Box
        sx={{
          width: "612px",
          height: "322px",
          borderRadius: "14px",
          border: "2px solid #9AD6C1",
          position: "absolute",
          left: "270px",
          top: "300px",
          padding: "10px",
        }}
      >
        <TextEditor />
      </Box>
    </Box>
  );
}

//Template1
function Template1() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        backgroundColor: "#fce397",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          width: "85%",
          height: "85%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            margin: "10px 0px",
            height: "80px",
            backgroundColor: "#fab16f",
            display: "flex",
            borderRadius: "16px",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src="/images/Templates/image1.svg" style={{ marginLeft: "20px" }} alt="" />
          <Box
            sx={{
              width: "100%",
              height: "50%",
              padding: "10px",
            }}
          >
            <TextEditor />
          </Box>
          <img src="/images/Templates/image2.svg" style={{ marginRight: "20px" }} alt="" />
        </Box>
        <Box
          sx={{
            margin: "10px 0px",
            backgroundColor: "#baa4dd",
            height: "80px",
            borderRadius: "16px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            src="/images/Templates/image3.svg"
            style={{ marginLeft: "20px", marginTop: "20px" }}
            alt=""
          />
          <Box
            sx={{
              width: "100%",
              height: "50%",
              padding: "10px",
            }}
          >
            <TextEditor />
          </Box>
        </Box>
        <Box
          sx={{
            height: "100%",
            margin: "10px 0px",
            backgroundColor: "#ffffff",
            borderRadius: "9px",
            boxShadow: "gba(0, 0, 0, 0.25) 0px 4px 4px",
            display: "flex",
          }}
        >
          <img
            src="/images/Templates/image4.svg"
            style={{
              width: "20px",
              height: "137px",
              marginLeft: "20px",
              marginTop: "26px",
            }}
            alt=""
          />
          <Box
            sx={{
              width: "calc(100% - 130px)",
              height: "calc(100% - 92px)",
              display: "inline-block",
              border: "2px solid #000000",
              borderRadius: "0 16px 16px 0",
              borderLeft: "none",
              marginLeft: "20px",
              marginTop: "26px",
              padding: "20px",
            }}
          >
            <TextEditor />
          </Box>
        </Box>
        <img
          src="/images/templates/image5.svg"
          alt=""
          style={{
            width: "250px",
            position: "absolute",
            left: "1%",
            bottom: "1%",
          }}
        />
      </Box>
    </Box>
  );
}

//Template2
function Template2() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        background: "url(/images/templates/image11.svg)",
        backgroundSize: "cover",
        position: "relative",
      }}
    >
      <img
        src="/images/templates/image12.svg"
        alt=""
        style={{
          width: "800px",
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%,-50%)",
        }}
      />
      <Box
        sx={{
          width: "350px",
          height: "200px",
          position: "absolute",
          left: "calc(50% - 35px)",
          top: "50%",
          transform: "translate(0,-50%)",
        }}
      >
        <TextEditor />
      </Box>
    </Box>
  );
}

//Template3
function Template3() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        backgroundColor: "pink",
      }}
    >
      <Box
        sx={{
          width: "50vw",
          height: "70vh",
          position: "absolute",
          left: "25vw",
          top: "15vh",
        }}
      >
        <TextEditor />
      </Box>
      <img src="/images/templates/image14.png" alt="" />
    </Box>
  );
}

//Template4
function Template4() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        backgroundColor: "#312f52",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
      }}
    >
      <img
        style={{
          width: "100%",
        }}
        src="/images/templates/image15.png"
        alt=""
      />
    </Box>
  );
}

//Template5
function Template5() {
  return (
    <Box
      sx={{
        width: "100vw",
        height: "100vh",
        background: "url(/images/templates/image16.png)",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          margin: "30px",
          maxWidth: "1200px",
          position: "relative",
        }}
      >
        <img
          style={{
            width: "100%",
          }}
          src="/images/templates/image17.png"
          alt=""
        />
        <Box
          sx={{
            position: "absolute",
            left: "15%",
            top: "40px",
            width: "84%",
            height: "calc(100% - 40px)",
          }}
        >
          <TextEditor />
        </Box>
      </Box>
    </Box>
  );
}

export default function Template() {
  // 引入全局属性
  const { getUserTemplate } = React.useContext(GlobalContext);
  // 设置模板
  const [template, setTemplate] = React.useState();
  // 获取用户模板
  useEffect(() => {
    async function getUserTemplateData() {
      const index = await getUserTemplate();
      setTemplate(index);
    }
    getUserTemplateData();
  }, []);
  // 弹出框
  const [isShowPopover, setIsShowPopover] = React.useState(null);
  const popoverOpen = Boolean(isShowPopover);
  const popoverId = popoverOpen ? "simple-popover" : undefined;
  //main
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {template === 0 && <Template0 />}
      {template === 1 && <Template1 />}
      {template === 2 && <Template2 />}
      {template === 3 && <Template3 />}
      {template === 4 && <Template4 />}
      {template === 5 && <Template5 />}
      <Box
        aria-describedby={popoverId}
        onClick={(event) => setIsShowPopover(event.currentTarget)}
        sx={{
          position: "absolute",
          bottom: "30px",
          left: "50%",
          transform: "translateX(-50%)",
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#ffffff",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          ":hover": {
            backgroundColor: "#f7f7f8",
            boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.3)",
          },
        }}
      >
        <img
          style={{
            width: "20px",
            height: "20px",
          }}
          src="/images/icon/icon_more.svg"
          alt=""
        />
      </Box>

      <Popover
        id={popoverId}
        open={popoverOpen}
        anchorEl={isShowPopover}
        onClose={() => setIsShowPopover(null)}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{
          zIndex: 9999,
        }}
      >
        <Grid
          container
          sx={{
            padding: "4px",
            width: "494px",
            height: "210px",
            overflow: "auto",
            "::-webkit-scrollbar": {
              width: "0px",
              height: "0px",
            },
          }}
        >
          {templateList.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  setTemplate(item.index);
                }}
                sx={{
                  width: "150px",
                  height: "85px",
                  margin: "4px",
                  cursor: "pointer",
                  border: "2px solid #ffffff",
                  ":hover": {
                    border: "2px solid #2d64f7",
                  },
                }}
              >
                <img
                  src={"/images/templates/" + item.url}
                  alt=""
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </Box>
            );
          })}
        </Grid>
      </Popover>
    </Box>
  );
}
