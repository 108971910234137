import React from 'react'
import { Grid, Box } from '@mui/material'
// 
import QuickStart from '../quick-start'
import HomeList from '../../../components/home-list'
import VideoCard from '../../../components/video-card'
// 丢弃
// import TrashStorage from '../../../storage/trash-storage'

import localforage from 'localforage'

export default function Trash() {
    const [trashesData, setTrashesData] = React.useState([])

    React.useEffect(() => {
        // 获取videos列表
        const getVideosList = async () => {
            const trashList = await localforage.getItem('trashList')
            console.log(trashList,"trashList");
            if (trashList?.length > 0) {
                setTrashesData(trashList)
            }
        }

        getVideosList();

    }, [])

    // 
    return (
        <Grid
            item
            xs
            sx={{
                width: '100%',
                maxWidth: '1040px',
                height: 'calc(100vh - 64px)',
                boxSizing: 'border-box',
                padding: '0px 50px 64px 50px',
                position: 'relative',
                // 内容超出高度，垂直滚动内容
                overflowY: 'auto',
                // 内容超出宽度，水平滚动内容
                overflowX: 'hidden',
                // 内容超出高度，显示滚动条
                '&::-webkit-scrollbar': {
                    width: '0px',
                    height: '0px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '4px',
                    backgroundColor: '#d3d1d9',
                },
            }}
        >
            <QuickStart
                title='Trash'
                subTitle={`${trashesData?.length} Videos`}
            />

            {
                trashesData?.length === 0 ? <NoVideos /> : <HomeList listData={trashesData} ListItem={VideoCard} minWidth={'252px'} setTrashesData={setTrashesData}/>
            }
        </Grid>
    )
}

// 没有视频
function NoVideos() {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100%',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: '100px',
            }}
        >
            <Box
                sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    fontFamily: 'Roboto',
                    textAlign: 'center',
                    color: '#cfcfcf',
                    marginBottom: '20px',
                }}
            >
                You haven't recorded a video yet.
            </Box>
            <Box
                sx={{
                    fontSize: '20px',
                    fontWeight: '500',
                    fontFamily: 'Roboto',
                    textAlign: 'center',
                    color: '#cfcfcf',
                }}
            >
                You can use the recording function on this page or install plugins for recording
            </Box>
        </Box>
    )
}
