// import MyVideos from "./my-videos"
// import Background from "./background"
// import Trash from "./trash"
// import Setting from "./setting"

//
export const PageList = [
  {
    title: "My Videos",
    value: "videos",
  },
//   {
//     title: "Background",
//     value: "background",
//   },
  {
    title: "Trash",
    value: "trash",
  },
  {
    title: "Setting",
    value: "setting",
  },
];

//
export const ServiceList = [
  {
    title: "About Us",
    value: "about_us",
    link: "/static/about.html",
  },
  {
    title: "Privacy Policy",
    value: "privacy_policy",
    link: "/static/privacy.html",
  },
  {
    title: "Terms of Service",
    value: "terms_service",
    link: "/static/terms.html",
  },
  {
    title: "Contact Support",
    value: "contact_support",
    link: "/static/contact-support.html",
  },
];
