/* eslint-disable no-undef */
import { Box, FormControl, LinearProgress, MenuItem, Select, InputLabel } from "@mui/material";
import React, { useState, useContext } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import { secToTime } from "../../../utils/editor-recorder/handleTime";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import localforage from "localforage";
import { getWaveBlob } from "webm-to-wav-converter";
import { audioToText } from "../../../api/audioToText";
import JWTAnalysis from "../../../utils/JWT";
import { GlobalContext } from "../../../utils/GlobalContext";
import videoToAudio from "../../../utils/ffmpeg/videoToAudio";

export default function EditorRecorderRight({
  dataBank,
  setDataBank,
  setIsTranscriptError,
  isTranscriptError,
  isGet,
  setIsGet,
  setVideoInformation,
  videoInformation,
  videoRef,
}) {
  let { setSummaryState, summaryState } = useContext(GlobalContext);
  // 当前的识别的语音的索引
  let [currentIndex, setCurrentIndex] = useState(0);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const languageData = [
    {
      country: "China",
      language: {
        name: "Simplified Chinese",
        code: "zh-CN",
      },
    },
    {
      country: "Taiwan",
      language: {
        name: "Traditional Chinese",
        code: "zh-TW",
      },
    },
    {
      country: "United States",
      language: {
        name: "English",
        code: "en-US",
      },
    },
    {
      country: "United Kingdom",
      language: {
        name: "English",
        code: "en-GB",
      },
    },
    {
      country: "Canada",
      language: {
        name: "English",
        code: "en-CA",
      },
    },
    {
      country: "Australia",
      language: {
        name: "English",
        code: "en-AU",
      },
    },
    {
      country: "New Zealand",
      language: {
        name: "English",
        code: "en-NZ",
      },
    },
    {
      country: "Ireland",
      language: {
        name: "English",
        code: "en-IE",
      },
    },
    {
      country: "India",
      language: {
        name: "English",
        code: "en-IN",
      },
    },
    {
      country: "Spain",
      language: {
        name: "Spanish",
        code: "es-ES",
      },
    },
    {
      country: "Mexico",
      language: {
        name: "Spanish",
        code: "es-MX",
      },
    },
    {
      country: "France",
      language: {
        name: "French",
        code: "fr-FR",
      },
    },
    {
      country: "Italy",
      language: {
        name: "Italian",
        code: "it-IT",
      },
    },
    {
      country: "Germany",
      language: {
        name: "German",
        code: "de-DE",
      },
    },
    {
      country: "Japan",
      language: {
        name: "Japanese",
        code: "ja-JP",
      },
    },
    {
      country: "South Korea",
      language: {
        name: "Korean",
        code: "ko-KR",
      },
    },
    {
      country: "Brazil",
      language: {
        name: "Portuguese",
        code: "pt-BR",
      },
    },
    {
      country: "Russia",
      language: {
        name: "Russian",
        code: "ru-RU",
      },
    },
  ];

  const [userAudioLanguage, setUserAudioLanguage] = React.useState(navigator.language);
  // 初始化summary的语言
  React.useEffect(() => {
    setSummaryState({
      ...summaryState,
      userAudioLanguage: navigator.language.split("-")[1],
    });
  }, []);
  // 选择语言
  const chooseLanguageFun = async (event) => {
    console.log(event.target.value.split("-")[1], "event.target.value.split('-')[1]");
    console.log(111, event.target.value);
    setUserAudioLanguage(event.target.value);
    await setSummaryState({
      ...summaryState,
      userAudioLanguage: event.target.value.split("-")[1],
    });
    sendConstructEventByEventParams("editor_recorder", {
      select_language: event.target.value,
    });
  };
  // 异常处理函数
  const handleErr = () => {
    setIsTranscriptError(true);
    setIsGet(false);
  };
  const handleTranscript = async () => {
    // 进行视频转音频
    let videoFileWebmBlob = dataBank.file;
    // Blob 转 file文件对象
    let file = new File(videoFileWebmBlob, "video.webm", { type: "video/webm" });
    // videoToAudio
    let signWebmAudio = await videoToAudio("webm", "wav", file);

    // 获取token
    // 从数据库中获取token
    let audioToTextToken;
    try {
      let indexDBToken = await localforage.getItem("audioToTextToken");
      if (indexDBToken) {
        // 存在，判断是否在有效期内
        let exp = JWTAnalysis(indexDBToken) / 1000;
        let now = new Date().getTime() / 1000;
        if (now - exp < 600) {
          // 有效期内
          audioToTextToken = indexDBToken;
        } else {
          // 有效期外，需要重新获取
          audioToTextToken = await audioToText("success", handleErr);

          await localforage.setItem("audioToTextToken", audioToTextToken);
        }
      } else {
        // 不存在，表示第一次使用，需要获取token
        audioToTextToken = await audioToText("success", handleErr);

        // 存入数据库中
        await localforage.setItem("audioToTextToken", audioToTextToken);
      }
    } catch (error) {
      return;
    }
    fromStream();
    // 创建语音配置
    const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(audioToTextToken, "eastus");
    speechConfig.speechRecognitionLanguage = userAudioLanguage;
    console.log("[ userAudioLanguage ] >", userAudioLanguage);
    let autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages([
      "en-US",
      "de-DE",
      "zh-CN",
    ]);

    async function fromStream() {
      setIsTranscriptError(false);
      // 从localforage中获取语音文件
      // let audioFile = dataBank.audioFile;

      let waveBlob = await getWaveBlob(signWebmAudio, false);
      let fileAudio = new File([waveBlob], "audio.wav", {
        type: "audio/wav",
      });
      let audioConfig = sdk.AudioConfig.fromWavFileInput(fileAudio);
      let speechRecognizer = sdk.SpeechRecognizer.FromConfig(
        speechConfig,
        autoDetectSourceLanguageConfig,
        audioConfig,
      );
      speechRecognizer.recognizing = (s, e) => {
        console.log(e);
        console.log(e.rawTimestamp);
        // 设置文本和时间
        dataBank.transcript[currentIndex] = {
          text: e.result.text,
          startTime: e.result.offset,
        };
        setDataBank({ ...dataBank });
        console.log(e.result);
        console.log(`1: Text=${e.result.text}`);
      };

      speechRecognizer.recognized = (s, e) => {
        if (e.result.reason === sdk.ResultReason.RecognizedSpeech) {
          dataBank.transcript[currentIndex] = {
            text: e.result.text,
            startTime: e.result.offset,
          };
          setDataBank({ ...dataBank });
          currentIndex++;
          setCurrentIndex(currentIndex);
          console.log(`2: Text=${e.result.text}`);
        } else if (e.result.reason === sdk.ResultReason.NoMatch) {
          console.log("NOMATCH: Speech could not be recognized.");
        }
      };

      speechRecognizer.canceled = (s, e) => {
        console.log(`CANCELED: Reason=${e.reason}`);

        if (e.reason === sdk.CancellationReason.Error) {
          console.log(`"CANCELED: ErrorCode=${e.errorCode}`);
          console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`);
          console.log("CANCELED: Did you set the speech resource key and region values?");
        }

        speechRecognizer.stopContinuousRecognitionAsync();
      };

      speechRecognizer.sessionStopped = async (s, e) => {
        // 获取完毕 --> 存储到数据库
        await localforage.setItem(dataBank.uuid, dataBank);
        console.log("\n    Session stopped event.");
        speechRecognizer.stopContinuousRecognitionAsync();
        // 请重新尝试一遍，并确保录制的视频中包含音频，并且选择了正确的语言
        handleErr();
      };
      speechRecognizer.startContinuousRecognitionAsync();
    }
  };
  return (
    <React.Fragment>
      {/* // 判断是否已经识别过 */}
      {dataBank.transcript.length === 0 ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {isTranscriptError && (
            <Box
              sx={{
                width: "80%",
                lineHeight: "20px",
                color: "#ff0000",
              }}
            >
              Please try again and ensure that the recorded video contains audio and that the
              correct language is selected.
            </Box>
          )}
          <FormControl
            disabled={isGet}
            variant="standard"
            sx={{
              m: 1,
              minWidth: 140,
              "& label": {
                fontSize: "14px",
                lineHeight: "20px",
              },
            }}
          >
            <InputLabel>Select Language</InputLabel>

            <Select
              sx={{
                fontSize: "20px",
              }}
              value={userAudioLanguage}
              onChange={chooseLanguageFun}
              MenuProps={MenuProps}
            >
              {languageData.map((item, index) => {
                return (
                  <MenuItem
                    style={{ fontSize: "14px", lineHeight: "20px" }}
                    key={index}
                    value={item.language.code}
                  >
                    {item.country}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <LoadingButton
            variant="contained"
            loading={isGet}
            onClick={() => {
              setIsGet(true);
              handleTranscript();
              sendConstructEventByEventParams("editor_recorder", {
                click: "transcript_start",
              });
            }}
          >
            Transcript start
          </LoadingButton>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            overflowY: "scroll",
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#FAFAFA",
              width: "1px",
            },
            "&::-webkit-scrollbar": {
              width: "1px",
              backgroundColor: "#FAFAFA",
            },
          }}
        >
          {isGet && <LinearProgress />}
          <Box
            sx={{
              width: "77px",
              height: "20px",
              opacity: "1",
              fontSize: "20px",
              fontWeight: "500",
              lineHeight: "20px",
              letterSpacing: "0em",
              color: "#3D3D3D",
              marginTop: "20px",
              marginBottom: "20px",
              marginLeft: "20px",
            }}
          >
            Transcript
          </Box>
          {dataBank.transcript.map((item, index) => {
            return (
              <Box
                key={index}
                sx={{
                  width: "100%",
                  margin: "15px 0px",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "24px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0px 20px",
                    boxSizing: "border-box",
                  }}
                >
                  <Box
                    onClick={() => {
                      videoRef.current.currentTime = Math.floor(item.startTime / 10000000);
                      videoInformation.videoCurrentTime = Math.floor(item.startTime / 10000000);
                      setVideoInformation({
                        ...videoInformation,
                      });
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "transcript_time_jump",
                      });
                    }}
                    sx={{
                      fontSize: "12px",
                      color: "#253fe7",
                      cursor: "pointer",
                    }}
                  >
                    {secToTime(Math.floor(item.startTime / 10000000))}
                  </Box>
                  {/* <Box
                    sx={{
                      width: "18px",
                      height: "18px",
                      background: "#4C7AF9",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#ffffff",
                      borderRadius: "50%",
                      fontSize: "12px",
                    }}
                  >
                    {index}
                  </Box> */}
                </Box>
                <Box
                  sx={{
                    padding: "10px 20px",
                    letterSpacing: "0.5px",
                    fontSize: "14px",
                  }}
                >
                  {item.text}
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </React.Fragment>
  );
}
