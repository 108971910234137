

// 用户操作(登录 注册 重置密码)
import axios from "axios";
const {
  saveResetUrl,
  registerUserUrl,
  loginUserUrl,
  verifyLoginTokenUrl,
  forgetUserPasswordUrl,
  saveResetUserPasswordUrl,
} = require("./requestUrl");
// 发送账号注册请求
// 参数:
// username 用户邮箱
// password 用户密码
async function sendRegisterUser(username, password) {
  // 请求参数
  const params = JSON.stringify({ username, password });
  // 发送请求
  const registerResult = await axios
    .post(registerUserUrl, params, {
      headers: { "Content-Type": "application/json" },
    })
    .catch((e) => e);
  // 请求发送成功
  if (registerResult.data != null && registerResult.data) {
    // 请求处理成功
    console.log("register network success:" + registerResult);
    if (registerResult.data.status === 2000) {
      // 注册成功
      return "register_success";
    }
    // 请求处理失败
    else {
      // 注册失败
      return "register_error_user_not_exits";
    }
  }
  // 请求发送失败
  else {
    console.log("register network error:" + registerResult);
    return "register_error_network";
  }
}
//发送账号登录请求
// 参数:
// username 用户邮箱
// password 用户密码
// setUserName 设置用户名方法
// setUserToken 设置用户登录凭证方法
async function sendLoginUser(
  username,
  password,
  setUserName,
  setUserToken,
  setUserSubState
) {
  // 请求参数
  const params = JSON.stringify({
    username,
    password,
  });
  // 发送请求
  const loginResult = await axios
    .post(loginUserUrl, params, {
      headers: { "Content-Type": "application/json" },
    })
    .catch((e) => e);
  //网络请求成功
  if (loginResult.data != null && loginResult.data) {
    console.log("login network success:" + loginResult);
    // 请求处理成功
    if (loginResult.data.code === 3000) {
      //用户登录凭证
      const token = "Bearer " + loginResult.data.data.token;
      //用户登录成功后设置一些必要数据
      setUserLoginSuccessData(
        token,
        setUserToken,
        username,
        setUserName,
      );
      return "login_success";
    }
    // 请求处理失败, 用户名错误
    else if (loginResult.data.code === 3001) {
      //用户登录失败后设置清空一些必要数据
      setUserLoginFailData(
        setUserToken,
        setUserName,
        setUserSubState
      );
      return "login_error_username";
    }
    // 请求处理失败, 密码错误
    else if (loginResult.data.code === 3002) {
      //用户登录失败后设置清空一些必要数据
      setUserLoginFailData(
        setUserToken,
        setUserName,
        setUserSubState
      );
      return "login_error_password";
    }
    // 请求处理失败,未知错误
    else {
      //用户登录失败后设置清空一些必要数据
      setUserLoginFailData(
        setUserToken,
        setUserName,
        setUserSubState
      );
      return "login_error_unknown";
    }
  }
  //网络请求失败
  else {
    console.log("login network error:" + loginResult);
    //用户登录失败后设置清空一些必要数据
    setUserLoginFailData(
      setUserToken,
      setUserName,
      setUserSubState
    );
    return "login_error_network";
  }
}
//校验token是否可用
// 参数:
// userToken 用户邮箱
// setUserName 设置用户名方法
// setUserToken 设置用户登录凭证方法
async function sendUserTokenVerify(
  userToken,
  setUserName,
  setUserToken,
  setUserSubState
) {
  const verifyResult = await axios
    .get(verifyLoginTokenUrl, {
      headers: {
        Authorization: userToken,
        "Content-Type": "application/json",
      },
    })
    .catch((e) => e);
  if (verifyResult.data != null && verifyResult.data) {
    console.log(
      "verify user token network success:" + JSON.stringify(verifyResult.data)
    );
    if (verifyResult.data.code === 4001) {
      //校验失败，用户未登录
      setUserLoginFailData(
        setUserToken,
        setUserName,
        setUserSubState
      );
      return "verify_user_token_error_not_login";
    } else {
      //校验成功
      setUserName(verifyResult.data.username);
      return "verify_user_token_success";
    }
  } else {
    console.log(
      "verify user token network error:" + JSON.stringify(verifyResult)
    );
    return "verify_user_token_error_network";
  }
}
//请求重置密码（让服务器发送邮件）
async function sendForgetUserPassword(username) {
  const params = JSON.stringify({ username });
  const forgetResult = await axios
    .post(forgetUserPasswordUrl, params, {
      headers: { "Content-Type": "application/json" },
    })
    .catch((e) => e);
  if (forgetResult.data != null && forgetResult.data) {
    console.log(
      "forget user password network success:" +
        JSON.stringify(forgetResult.data)
    );
    if (forgetResult.data.status === 3000) {
      return "forget_user_password_success";
    } else {
      return "forget_user_password_error_user_not_exits";
    }
  } else {
    console.log(
      "forget user password network error:" + JSON.stringify(forgetResult)
    );
    return "forget_user_password_error_network";
  }
}

//修改密码
async function sendSaveResetUserPassword(userName, oldPassword, newPassword) {
  const params = JSON.stringify({
    userName: userName,
    oldPassword: oldPassword,
    newPassword: newPassword,
  });
  let saveResetResult = await axios
    .post(saveResetUserPasswordUrl, params, {
      headers: { "Content-Type": "application/json" },
    })
    .catch((e) => e);
  if (saveResetResult.data != null && saveResetResult.data) {
    console.log(
      "save reset user password network success:" +
        JSON.stringify(saveResetResult.data)
    );
    if (saveResetResult.data.status === 3000) {
      return "Password changed successfully";
    } else if (saveResetResult.data.status === 3001) {
      return "user does not exist";
    } else if (saveResetResult.data.status === 3002) {
      return "The old password is incorrect";
    }
  } else {
    console.log(
      "save reset user password network error:" +
        JSON.stringify(saveResetResult)
    );
    return "save_reset_user_password_error_network";
  }
}
//重置密码
async function ResetPassword(token, password) {
  const params = JSON.stringify({ token: token, password: password });
  let ResetResult = await axios
    .post(saveResetUrl, params, {
      headers: { "Content-Type": "application/json" },
    })
    .catch((e) => e);
  if (ResetResult.data != null && ResetResult.data) {
    console.log(
      "save reset user password network success:" +
        JSON.stringify(ResetResult.data)
    );
    if (ResetResult.data.status === 3000) {
      console.log("success");
      return "save_reset_user_password_success";
    } else if (ResetResult.data.status === 3001) {
      console.log("error");
      return "save_reset_user_password_error";
    }
  } else {
    console.log(
      "save reset user password network error:" + JSON.stringify(ResetResult)
    );
    return "save_reset_user_password_error_network";
  }
}
//用户登录成功后设置一些必要数据
function setUserLoginSuccessData(
  userToken,
  setUserToken,
  username,
  setUserName,
) {
  setUserToken(userToken);
  setUserName(username);
}
//用户登录失败后设置清空一些必要数据
function setUserLoginFailData(
  setUserToken,
  setUserName,
  setUserSubState
) {
  setUserToken(null);
  setUserName(null);
  setUserSubState(null);
}
export {
  ResetPassword,
  sendRegisterUser,
  sendLoginUser,
  sendUserTokenVerify,
  sendForgetUserPassword,
  sendSaveResetUserPassword,
};
