import {
  Box,
  Typography,
  Switch,
  TextField,
  Slider,
  Radio,
  FormControl,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import React from "react";
export default function Watermark({ pageSize, watermarkInformation, setWatermarkInformation }) {
  return (
    <Box
      sx={{
        width: pageSize.width <= 1095 ? "37%" : "384px",
      }}
    >
      {/* 顶部是否开启水印  */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px !important",
          padding: "10px",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        {/* title  */}
        <Typography variant="recorderFunctionTitle">Add Watermark</Typography>
        <Switch
          checked={watermarkInformation.isNeed}
          onChange={(event, value) => {
            watermarkInformation.isNeed = value;
            setWatermarkInformation({
              ...watermarkInformation,
            });
          }}
        />
      </Box>
      {/* 底部编辑区域  */}

      {watermarkInformation.isNeed && (
        <Box
          sx={{
            width: "90%",
            marginLeft: "15px !important",
            "& div": {
              fontSize: "18px",
              fontWeight: "700",
              margin: "5px 5px",
            },
          }}
        >
          <Box>Text</Box>
          <TextField
            onChange={(event) => {
              watermarkInformation.text = event.target.value;
              setWatermarkInformation({
                ...watermarkInformation,
              });
            }}
            value={watermarkInformation.text}
          ></TextField>
          <Box>Opacity</Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Slider
              onChange={(event, value) => {
                setWatermarkInformation({
                  ...watermarkInformation,
                  opacity: value,
                });
              }}
              value={watermarkInformation.opacity}
              sx={{
                width: "70%",
              }}
            ></Slider>
            <span
              style={{
                marginLeft: "20px",
              }}
            >
              {watermarkInformation.opacity}%
            </span>
          </Box>
          <Box>Position</Box>
          <Box>
            <FormControl>
              <RadioGroup
                onChange={(event, value) => {
                  watermarkInformation.position = value * 1;
                  setWatermarkInformation({
                    ...watermarkInformation,
                  });
                }}
                value={watermarkInformation.position}
                row
                name="watermark position"
              >
                <FormControlLabel value={0} control={<Radio />} label="Left Top" />
                <FormControlLabel value={1} control={<Radio />} label="Right Top" />
                <FormControlLabel value={2} control={<Radio />} label="Left Bottom" />
                <FormControlLabel value={3} control={<Radio />} label="Right Bottom" />
              </RadioGroup>
            </FormControl>
          </Box>
        </Box>
      )}
    </Box>
  );
}
