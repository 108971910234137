import React, { useEffect } from "react";
import { Box } from "@mui/material";
//
import Tabs from "../../../../components/tabs";
import Transcript from "./transcript";
import Summary from "./summary";
import Edit from "./edit";
import ChangeThumbnail from "./change-thumbnail";

import localforage from "localforage";
import { getSummarySysParams, getTxtSummary } from "../../../../api/summary";
import url from "../../../../api/url";

export default function ToolOptions({
  setToolState,
  setThumbnail,
  changeThumbnail,
  transcriptData,
  transcriptState,
  addSubtitlesHandle,
  isAddSubtitles,
  isAddSubtitleLoading,
  reduceNoiseHandle,
  isReduceNoise,
  isRNoiseLoading,
  videoKey,
}) {
  const [tab, setTab] = React.useState(0);
  const [isChangeThumbnail, setIsChangeThumbnail] = React.useState(false);

  const [threeParagraphSummary, setThreeParagraphSummary] = React.useState([[], [], []]);
  /**
   *
   *
   * @description: 总结 summary
   *
   *
   */
  useEffect(() => {
    (async () => {
      if (transcriptData.length === 0 || transcriptState !== "end") return;
      let videoUUID = videoKey;
      // 判断 url 是否存在 uuid
      let params = new URLSearchParams(window.location.search);
      if (params.get("uuid")) {
        videoUUID = params.get("uuid");
      }

      // 是否已经存储了总结数据
      let videoKeyRes = await localforage.getItem(videoUUID);

      if (videoKeyRes?.summary) {
        setThreeParagraphSummary(videoKeyRes.summary);
      } else {
        let isCanUseGPT = await getSummarySysParams(); //国家是否可以使用
        if (isCanUseGPT) {
          let willSummaryContent = "";
          transcriptData.forEach((item) => {
            willSummaryContent = willSummaryContent + item.text;
          });
          console.log("[ willSummaryContent ] >", willSummaryContent);
          //   开始进行总结
          let getTxtSummaryResult = await getTxtSummary({
            content: willSummaryContent,
            language: navigator.language || navigator.userLanguage,
          });
          if (getTxtSummaryResult) {
            console.log("[ getTxtSummaryResult ] >", getTxtSummaryResult);
            // 分段落
            let getTxtSummaryResultArray = decodeURIComponent(getTxtSummaryResult[0].content).split(
              "\n\n",
            );
            /**
             *
             *
             * @description: 分句子
             *
             *
             */

            getTxtSummaryResultArray.forEach((item, index) => {
              let sentences = item.split(/[.!?]/);
              threeParagraphSummary[index] = sentences.filter((sentence) => sentence.trim() !== "");
            });
            setThreeParagraphSummary([...threeParagraphSummary]);
            window.toast("Summary success 🎉");
            //   存储到对应的视频中
            videoKeyRes.summary = threeParagraphSummary;
            await localforage.setItem(videoUUID, videoKeyRes);
          }
        }
      }
    })();
  }, [transcriptData, transcriptState]);

  return (
    <Box
      sx={{
        width: "360px",
        height: "100%",
        backgroundColor: "#fff",
      }}
    >
      {/* 工具选择栏 */}
      {!isChangeThumbnail && (
        <>
          <Tabs tabsData={["Transcript", "Summary", "Edit"]} tab={tab} setTab={setTab} />
          {tab === 0 && (
            <Transcript transcriptData={transcriptData} transcriptState={transcriptState} />
          )}
          {tab === 1 && threeParagraphSummary[3] !== [] && (
            <Summary
              data={[
                {
                  type: "beginning",
                  content: threeParagraphSummary[0],
                },
                {
                  type: "middle",
                  content: threeParagraphSummary[1],
                },
                {
                  type: "end",
                  content: threeParagraphSummary[2],
                },
              ]}
            />
          )}
          {tab === 2 && (
            <Edit
              setToolState={setToolState}
              toChangeThumbnail={() => setIsChangeThumbnail(true)}
              isRNoiseLoading={isRNoiseLoading}
              addSubtitlesHandle={addSubtitlesHandle}
              isAddSubtitles={isAddSubtitles}
              isAddSubtitleLoading={isAddSubtitleLoading}
              reduceNoiseHandle={reduceNoiseHandle}
              isReduceNoise={isReduceNoise}
            />
          )}
        </>
      )}
      {isChangeThumbnail && (
        <ChangeThumbnail
          toEdit={() => setIsChangeThumbnail(false)}
          setThumbnail={setThumbnail}
          changeThumbnail={changeThumbnail}
        />
      )}
    </Box>
  );
}
