// 请求微软的transcript token
import api from "../../api"
import md5 from "md5"

const requestToken = async () => {
    try {
        const timeStamp = new Date().getTime();
        const str = `${process.env.REACT_APP_SECRET_KEY}timeStamp=${timeStamp}`;
        const signature = md5(str);
        const res = await api({
            url: "/getMSToken/" + timeStamp,
            method: "GET",
            headers: {
                signature,
            },
        });

        if (res?.status === 200) {
            return {
                state: true,
                token: res.data.dataResult.token
            };
        } else {
            return {
                state: false,
                message: 'network error'
            }
        }

    } catch (error) {
        return {
            state: false,
            message: 'network error'
        }
    }

}

export default requestToken;