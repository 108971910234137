import React, { useContext } from "react";
import { Box, Button } from "@mui/material";
import { GlobalContext } from "../../../utils/GlobalContext";
import MyDrawer from "../Home/MyDrawer";
export default function User({ setWhichFunction }) {
  let { userName, userToken, userSubState, whichFunction, setUserName, setUserToken } =
    useContext(GlobalContext);
  return (
    <Box>
      {userName && userToken ? (
        <Button
          onClick={async () => {
            // 判断用户是不是vip
            if (userSubState?.vip) {
              await setWhichFunction(3);
              if (new URL(window.location.href).pathname !== "/") {
                window.location.href = "/";
              }
            } else {
              document.getElementsByClassName("premium")[0].click();
            }
          }}
          sx={{
            height: "40px",
            display: "none",
          }}
        >
          <img width={"24px"} src="/images/editor-recorder/personal.svg" alt="personal" />
        </Button>
      ) : (
        <MyDrawer anchor={"right"} from="navbar" startPage={"login"}>
          <Button
            className="loginUser"
            sx={{
              marginRight: "35px",
              height: "40px",
            }}
          >
            <img width={"24px"} src="/images/editor-recorder/personal.svg" alt="personal" />
          </Button>
        </MyDrawer>
      )}
    </Box>
  );
}
