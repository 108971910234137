
import { fetchFile } from "@ffmpeg/util";
import newFFmpegFactory from "./newFFmpeg";

const videoToAudio = async (file) => {
  let ffmpeg = await newFFmpegFactory.loadFFmpeg();

  /**
   *
   *
   * @description: 写入文件
   *
   *
   */

  await ffmpeg.writeFile(`input.webm`, await fetchFile(file));

  /**
   *
   *
   * @description: 执行命令
   *
   *
   */

  await ffmpeg.exec(["-i", `input.webm`, "-vn", "-acodec", "copy", `output.webm`]);

  /**
   *
   *
   * @description: 读取文件
   *
   *
   */
  const data = await ffmpeg.readFile(`output.webm`);
  let dataFile = new File([data], `output.webm`,{type: "audio/webm"});
  return dataFile;
};

export default videoToAudio;
