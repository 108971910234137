import React, { useState, useRef, useEffect, startTransition } from "react";
import { useMeasure } from "react-use";
import localforage from "localforage";
import VideosStorage from "../storage/videos-storage";
import NavBar from "./components/NavBar";
import getAllUrlParams from "../utils/getAllUrlParams";
// 引入样式模块
import EditorRecorderStyle from "../css/editor-recorder.module.scss";
// 引入MUI
import { Box, Button } from "@mui/material";
import EditorRecorderLeft from "./components/EditorRecorder/EditorRecorderLeft";
import EditorRecorderRight from "./components/EditorRecorder/EditorRecorderRight";
export default function EditorRecorder() {
  // * video播放 控件区域
  //   video ref
  let videoRef = useRef(null);
  //   重制版本
  let [videoInformation, setVideoInformation] = useState({
    //   视频的总时长
    videoDuration: 0,
    //   控制视频的进度
    videoCurrentTime: 0,
    // 播放按钮的src
    playVideoButton: "/images/editor-recorder/play.svg",
    // video视频源
    videoSrc: "",
    videoWidth: 0,
    videoHeight: 0,
    isPlay: false,
    videoTitle:
      "Recording -15:30 03/11 2022 Recording -15:30 03/11 2022 Recording -15:30 03/11 202...",
  });
  // 当前编辑文件的存储的相关信息
  let [dataBank, setDataBank] = useState({
    uuid: 0,
    videoDuration: 0,
    videoWidth: 0,
    videoHeight: 0,
    videoTitle: "",
    editor: {},
    file: "",
    transcript: [],
    summary: "",
  });

  // 控制编辑区域打开哪一个弹出层
  let [whichFunctionOpen, setWhichFunctionOpen] = useState("");
  // 当保存按钮触发的时候，需要将当时候的结构进行保存，用于cancel的使用
  let [tempSaveVideoTrackInformation, setTempSaveVideoTrackInformation] = React.useState([
    {
      startTime: 0,
      endTime: 0,
      minStartTime: 0,
      maxEndTime: 0,
      startTimeProportion: 0,
      endTimeProportion: 0,
    },
  ]);
  const [pageRef, pageSize] = useMeasure();
  // 语音转文本的 存储 是否识别失败
  let [isTranscriptError, setIsTranscriptError] = useState(false);
  // 是否正在进行转换
  let [isGet, setIsGet] = useState(false);
  useEffect(() => {
    // #初始化数据库
    localforage.config({
      name: "videoRecorder",
      version: 1.0,
      storeName: "videoFile", // Should be alphanumeric, with underscores.
    });

    // 根据uuid查询当前的文件
    // 判断是否需要进行初始化的存储
    let uuid = getAllUrlParams(window.location.href).uuid;
    // 现根据uuid进行查询
    // 根据存储的数据进行数据的查找
    localforage.getItem(uuid, function (err, value) {
      if (value) {
        console.log(value, "已经存储过");
        // ?表示数据已经存储过
        // 进行数据的初始化
        videoInformation.videoDuration = value.videoDuration;
        videoInformation.videoWidth = value.videoWidth;
        videoInformation.videoHeight = value.videoHeight;
        videoInformation.videoTitle = value.videoTitle;
        videoInformation.blobFileArray = value.file;
        tempSaveVideoTrackInformation = value.editor;
        // 初始化数据库数据
        dataBank.uuid = uuid;
        dataBank.videoDuration = value.videoDuration;
        dataBank.videoWidth = value.videoWidth;
        dataBank.videoHeight = value.videoHeight;
        dataBank.videoTitle = value.videoTitle;
        dataBank.editor = value.editor;
        dataBank.file = value.file;
        dataBank.summary = value.summary;
        dataBank.transcript = value.transcript ? value.transcript : [];
        // 初始化video标签

        var superBuffer = new Blob(videoInformation.blobFileArray, {
          type: "video/webm",
        });
        videoInformation.videoSrc = URL.createObjectURL(superBuffer);
        setDataBank({ ...dataBank });
        setVideoInformation({ ...videoInformation });
        setTempSaveVideoTrackInformation([...tempSaveVideoTrackInformation]);
        let videoDOM = videoRef.current;
        videoDOM.load();
        videoDOM.oncanplay = () => {
          videoRef.current.volume = 0.5;
          //   设置完成之后就可以清空监听事件
          videoDOM.oncanplay = null;
        };
      } else {
        console.log("未存储过");
        // 存储数据
        // 获取交换数据
        // 交换之前需要知道，是否可以进行交换
        let isCanReadSetIntervalID = setInterval(async () => {
          let isCanRead = await localforage.getItem("isCanRead");

          if (isCanRead) {
            // # 完整的一次性读取逻辑  -- start
            //清除定时器
            clearInterval(isCanReadSetIntervalID);
            // 恢复数据库的数据
            localforage.setItem("isCanRead", false);
            // 初始化时间轨道
            let exchangeFilesLength = await localforage.getItem("exchangeFilesLength");
            videoInformation.videoDuration = exchangeFilesLength - 2;
            setVideoInformation({ ...videoInformation });
            // 初始化title
            let fileTitle = await localforage.getItem("fileTitle");
            videoInformation.videoTitle = fileTitle;
            // 读取文件数据
            let exchangeFiles = await localforage.getItem("exchangeFiles");
            videoInformation.blobFileArray = exchangeFiles;
            // videoInformation.audioFile = await localforage.getItem("audioFile");
            var superBuffer = new Blob(exchangeFiles, {
              type: "video/webm",
            });
            // 设置video的src
            videoInformation.videoSrc = URL.createObjectURL(superBuffer);
            // 让video进行加载
            setVideoInformation({ ...videoInformation });
            let videoDOM = videoRef.current;
            videoDOM.load();
            videoDOM.oncanplay = async () => {
              // 初始化视频的音量
              videoRef.current.volume = 0.5;
              setVideoInformation({
                ...videoInformation,
                videoWidth: videoDOM.videoWidth,
                videoHeight: videoDOM.videoHeight,
              });
              // 初始化数据库
              dataBank.uuid = uuid;
              dataBank.videoDuration = videoInformation.videoDuration;
              dataBank.videoWidth = videoDOM.videoWidth;
              dataBank.videoHeight = videoDOM.videoHeight;
              dataBank.videoTitle = videoInformation.videoTitle;
              dataBank.editor = tempSaveVideoTrackInformation;
              dataBank.file = videoInformation.blobFileArray;
              // dataBank.audioFile = videoInformation.audioFile;
              // 开始获取视频的缩略图
              const video = document.createElement("video");
              video.src = videoInformation.videoSrc;
              video.currentTime = 1;
              video.onseeked = async function () {
                const canvas = document.createElement("canvas");
                const ctx = canvas.getContext("2d");
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                ctx.fillRect(0, 0, video.videoWidth, video.videoHeight);
                ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
                const imageDataURL = canvas.toDataURL("image/jpeg", 0.5);
                dataBank.videoCover = imageDataURL;
                // 数据存储
                localforage.setItem(uuid, dataBank, function (err) { });
                VideosStorage.add(dataBank)
                // 需要存储数据字典
                // 判断是否存在
                let dataDictionary = await localforage.getItem("dataDictionary");
                if (dataDictionary) {
                  // 存在
                  dataDictionary.push({
                    uuid: uuid,
                    videoTitle: videoInformation.videoTitle,
                    videoDuration: videoInformation.videoDuration,
                    videoCover: imageDataURL,
                  });
                  localforage.setItem("dataDictionary", dataDictionary, function (err) { });
                } else {
                  // 不存在
                  // 创建首个数据字典
                  localforage.setItem(
                    "dataDictionary",
                    [
                      {
                        uuid: uuid,
                        videoTitle: videoInformation.videoTitle,
                        videoDuration: videoInformation.videoDuration,
                        videoCover: imageDataURL,
                      },
                    ],
                    function (err) { },
                  );
                }
                setDataBank({ ...dataBank });
              };

              //   设置完成之后就可以清空监听事件
              videoDOM.oncanplay = null;
            };
            // # 完整的一次性读取逻辑  -- end
          }
        }, 200);
      }
    });
  }, []);
  return (
    <Box
      ref={pageRef}
      sx={{
        overflowX: "hidden",
      }}
    >
      {/* 顶部区域 */}
      <NavBar />

      {/* 下部区域  */}
      <Box className={EditorRecorderStyle.bottom}>
        {/* 左侧  */}
        <EditorRecorderLeft
          isGet={isGet}
          setIsGet={setIsGet}
          dataBank={dataBank}
          setDataBank={setDataBank}
          tempSaveVideoTrackInformation={tempSaveVideoTrackInformation}
          setTempSaveVideoTrackInformation={setTempSaveVideoTrackInformation}
          videoInformation={videoInformation}
          videoRef={videoRef}
          setVideoInformation={setVideoInformation}
          pageSize={pageSize}
          whichFunctionOpen={whichFunctionOpen}
          setWhichFunctionOpen={setWhichFunctionOpen}
        />
        {/* 右侧  */}
        {whichFunctionOpen === "Transcript" && (
          <Box
            className={EditorRecorderStyle.bottom_right}
            sx={{
              borderLeft: "1px solid #e8e8e8",
            }}
          >
            <EditorRecorderRight
              videoInformation={videoInformation}
              setVideoInformation={setVideoInformation}
              videoRef={videoRef}
              isGet={isGet}
              setIsGet={setIsGet}
              setIsTranscriptError={setIsTranscriptError}
              isTranscriptError={isTranscriptError}
              dataBank={dataBank}
              setDataBank={setDataBank}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
