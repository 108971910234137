import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

// 创建自定义主题
const theme = createTheme({
  // 调色板
  palette: {
    // 默认主题颜色(按钮)
    primary: {
      main: "#2F65F7",
      light: '#E4EBFF',
    },
    // 次要主题颜色(输入框)
    secondary: {
      main: "#E4EBFF",
      light: "#2F65F7",
    },
    white: {
      main: "#FFFFFF",
    }
  },
  // 媒体查询断点
  breakpoints: {
    // 断点值(屏幕宽度)
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  // 文字铸排
  typography: {
    body1: {
      fontSize: "1.5rem",
      lineHeight: "1.875rem",
      fontWeight: 500,
      "@media (max-width:600px)": {
        fontSize: "1.25rem",
      },
    }, //24px
    // 默认字体
    whiteSmall: {
      opacity: " 1",
      fontSize: " 16px",
      fontWeight: " 500",
      lineHeight: " 20px",
      letterSpacing: " 0em",
      color: " #FFFFFF",
    },
    buttonMiddle: {
      opacity: " 1",
      fontSize: " 16px",
      fontWeight: " 500",
      lineHeight: " 20px",
      letterSpacing: " 0em",
      color: " #000000",
    },
    button: {
      textTransform: "none",
    },
    recorderFunctionTitle: {
      fontSize: "20px",
      fontWeight: 900,
    },
  },
  // 组件样式
  components: {},
});

export default function GlobalTheme(props) {
  // 发布自定义主题
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
}
