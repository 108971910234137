const COLOR_THEME = {
    primary: {
        main: '#2F65F7',
        hover: '#1246D0',
        active: '#0833AA',
    },
    secondary: {
        main: '#D2DEFF',
        hover: '#D2DEFF',
        active: '#D2DEFF',
    },
    gray: {
        main: '#F5F5F5',
        hover: '#EEEEEE',
        active: '#D3D3D3',
    }
}

export default COLOR_THEME;