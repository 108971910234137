import React from 'react'
import { Grid } from '@mui/material'
import LabelButton from '../ui/button/label-button'

export default function List({
    data,
    activeValue = 'none',
    onClick,
}) {
    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: data.length * 40 - 4 + 'px',
                flexDirection: 'column',
                justifyContent: 'space-between',
            }}
        >
            {
                data.map((item, index) => (
                    <LabelButton
                        onClick={() => onClick(item)}
                        key={index}
                        title={item.title}
                        icon={item.value}
                        active={activeValue === item.value}
                    />
                ))
            }
        </Grid>
    )
}
