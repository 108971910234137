import localforage from "localforage";

const storageInit = () => {
  // #初始化数据库
  localforage.config({
    name: "videoRecorder",
    version: 1.0,
    storeName: "videoFile", // Should be alphanumeric, with underscores.
  });
};

const read = async (key) => {
  storageInit();
  return await localforage.getItem(key);
};

const write = async (key, value) => {
  storageInit();
  console.log(key, value);
  return await localforage.setItem(key, value);
};

const remove = async (key) => {
  storageInit();
  return await localforage.removeItem(key);
};

const storage = {
  read,
  write,
  remove,
};

export default storage;
