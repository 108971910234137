/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Grid } from "@mui/material";
import localforage from "localforage";

export default function RecordTool(props) {
  //父组件传参
  const {
    currentRecordMode,
    currentMicrophone,
    setIsStartRecord,
    isShowControl,
    setIsShowControl,
    videoRef,
  } = props;
  //准备录制视频,获取音频流设备
  function prepareRecord(micAble) {
    const constraints = {
      audio: {
        deviceId: currentMicrophone.id,
      },
    };
    // 开始录制麦克风音频流
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then(function (mic) {
        micStream.current = mic;
        micSource.current = audioCtx.current.createMediaStreamSource(mic);
        if (!micAble) {
          mic.getTracks().forEach(function (track) {
            track.enabled = false;
          });
        }
        //开始标签录制
        openSystemRecord();
      })
      .catch(function (error) {
        setIsHasMic(false);
        //开始标签录制
        openSystemRecord();
      });
  }
  //打开系统录屏弹窗
  function openSystemRecord() {
    const constraints = {
      video: {
        displaySurface: currentRecordMode.value,
      },
      audio: true,
      maxframeRate: 30,
    };
    navigator.mediaDevices
      .getDisplayMedia(constraints)
      .then(function (stream) {
        if (stream.getAudioTracks().length === 0) {
          setHasSysAudio(false);
          if (hasMicrophone.current) {
            micSource.current.connect(destination.current);
            recordOutput.current.addTrack(destination.current.stream.getAudioTracks()[0]);
          }
        } else {
          setHasSysAudio(true);
          sysSource.current = audioCtx.current.createMediaStreamSource(stream);
          if (hasMicrophone.current) {
            micSource.current.connect(destination.current);
          }
          sysSource.current.connect(destination.current);
          recordOutput.current.addTrack(destination.current.stream.getAudioTracks()[0]);
        }
        recordOutput.current.addTrack(stream.getVideoTracks()[0]);
        //
        mediaRecorder.current = new MediaRecorder(recordOutput.current, {
          mimeType: "video/webm;codecs=vp8,vp9,opus",
        });
        //
        // readable.pipeTo(streamSaver.current.createWriteStream('screenity.webm'));
        mediaRecorder.current.ondataavailable = async (event) => {
          if (event.data && event.data.size > 0) {
            recordedBlobs.current.push(event.data);
            await localforage.setItem("exchangeFiles", recordedBlobs.current);
          }
        };
        mediaRecorder.current.onstop = async (event) => {
          saveData(stream);
        };
        //
        stream.getVideoTracks()[0].onended = function () {
          mediaRecorder.current.stop();
        };
        //开始录制
        startRecord();
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      })
      .catch(function (error) {
        console.log("error", error);
        setIsStartRecord(false);
      });
  }
  //开关麦克风和系统
  //保存数据
  async function saveData(stream, writer) {
    // Stop tab and microphone streams
    stream.getTracks().forEach(function (track) {
      track.stop();
    });
    if (hasMicrophone.current) {
      micStream.current.getTracks().forEach(function (track) {
        track.stop();
      });
    }
    //不保存数据
    if (isCancel.current) {
      setIsStartRecord(false);
      setIsShowControl(false);
    } else {
      function generateUUID() {
        var d = new Date().getTime();
        var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        });
        return uuid;
      }
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();
      const hours = date.getHours();
      const minutes = date.getMinutes();
      const seconds = date.getSeconds();
      const formattedTime = `Recording - ${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      // 初始化时间轨道
      await localforage.setItem("exchangeFilesLength", recordedBlobs.current.length);
      // 初始化title
      await localforage.setItem("fileTitle", formattedTime);
      // 读取文件数据

      await localforage.setItem("isCanRead", true);
      window.open("/editor-recorder?uuid=" + generateUUID() + "&type=open", "_self");
   
    }
  }
  //开始录制
  function startRecord() {
    mediaRecorder.current.start(1000);
    setIsShowControl(true);
  }
  //暂停录制
  function pauseRecord() {
    mediaRecorder.current.pause();
  }
  //继续录制
  function resumeRecord() {
    mediaRecorder.current.resume();
  }
  //结束录制
  function stopRecord() {
    mediaRecorder.current.stop();
  }
  //打开关闭麦克风和系统音频
  function toggleMicrophone(source, enable) {
    if (source === "mic" && !enable && hasMicrophone.current) {
      //关闭麦克风
      micStream.current.getTracks().forEach(function (track) {
        track.enabled = false;
      });
    } else if (source === "mic" && enable && hasMicrophone.current) {
      //打开麦克风
      micStream.current.getTracks().forEach(function (track) {
        track.enabled = true;
      });
    } else if (source === "tab" && !enable) {
      sysSource.current.disconnect(destination.current);
    } else if (source === "tab" && enable) {
      sysSource.current.connect(destination.current);
    }
  }

  //工具条鼠move按钮鼠标按下事件
  function handleMouseDown(e) {
    //记录鼠标按下时的位置
    mouseDownPosition.current = {
      left: e.clientX,
      top: e.clientY,
    };
    //记录工具条初始位置
    toolInitPosition.current = {
      left: toolPosition.left,
      top: toolPosition.top,
    };
    //添加移动事件
    document.addEventListener("mousemove", handleMouseMove);
    //添加鼠标抬起事件
    document.addEventListener("mouseup", handleMouseUp);
  }
  //工具条鼠move按钮鼠标移动事件
  function handleMouseMove(e) {
    if (toolInitPosition.current) {
      const { left, top } = mouseDownPosition.current;
      const dx = e.clientX - left;
      const dy = e.clientY - top;
      setToolPosition({
        left: toolInitPosition.current.left + dx,
        top: toolInitPosition.current.top + dy,
      });
    }
  }
  //工具条鼠move按钮鼠标抬起事件
  function handleMouseUp() {
    //移除移动事件
    document.removeEventListener("mousemove", handleMouseMove);
    //移除鼠标抬起事件
    document.removeEventListener("mouseup", handleMouseUp);
    //清空数据
    mouseDownPosition.current = null;
    toolInitPosition.current = null;
  }

  //麦克风数据流
  const micStream = React.useRef(null);
  //系统音频源
  const sysSource = React.useRef(null);
  //麦克风音频源
  const micSource = React.useRef(null);
  const audioCtx = React.useRef(new AudioContext());
  const destination = React.useRef(audioCtx.current.createMediaStreamDestination());
  //存储录屏输出数据
  const recordOutput = React.useRef(new MediaStream());
  //媒体数据流
  const mediaRecorder = React.useRef(null);
  //存储最终录屏数据
  const recordedBlobs = React.useRef([]);
  //存储最终音频数据
  const audioRecordedBlobs = React.useRef([]);
  //是否打开麦克风控制样式改变
  const [micAble, setMicAble] = React.useState(true);
  //
  const [isHasMic, setIsHasMic] = React.useState(false);
  //是否有可用的麦克风设备
  const hasMicrophone = React.useRef(false);
  //是否有可用的系统音频
  const [hasSysAudio, setHasSysAudio] = React.useState(false);
  //是否打开系统音频
  const [sysAble, setSysAble] = React.useState(true);
  //当前录屏状态
  const [recordState, setRecordState] = React.useState("running");
  //存储工具条位置
  const [toolPosition, setToolPosition] = React.useState({ left: 10, top: 10 });
  //存储工具条初始位置
  const toolInitPosition = React.useRef({ left: 10, top: 10 });
  //鼠标按下的位置
  const mouseDownPosition = React.useRef({ x: 0, y: 0 });
  //判断取消录屏数据或者保存录屏数据
  const isCancel = React.useRef(false);

  //初始化
  React.useEffect(() => {
    // #初始化数据库
    localforage.config({
      name: "videoRecorder",
      version: 1.0,
    });
    //麦克风设备可用同时选择了麦克风
    if (currentMicrophone.index > -1) {
      setMicAble(true);
      hasMicrophone.current = true;
      setIsHasMic(true);
      //准备录制
      prepareRecord(true);
    }
    //麦克风设备可用同时没有选择麦克风
    if (currentMicrophone.index === -1) {
      setMicAble(false);
      hasMicrophone.current = true;
      setIsHasMic(true);
      //准备录制
      prepareRecord(false);
    }
    //麦克风设备不可用
    if (currentMicrophone.index === -2) {
      setMicAble(false);
      hasMicrophone.current = false;
      setIsHasMic(false);
      //准备录制
      prepareRecord(false);
    }
  }, []);

  //main
  return (
    <Grid
      container
      sx={{
        opacity: isShowControl ? 1 : 0,
        height: "58px",
        width: "271px",
        position: "fixed",
        top: toolPosition.top + "px",
        left: toolPosition.left + "px",
        justifyContent: "space-around",
        alignItems: "center",
        backgroundColor: "#fff",
        borderRadius: "8px",
        boxShadow: "rgba(0, 0, 0, 0.12) 0px 2px 20px 0px",
        display:"none"
      }}
    >
      {/* 移动按钮 */}
      <Grid
        container
        onMouseDown={handleMouseDown}
        sx={{
          width: "32px",
          height: "32px",
          borderRadius: "8px",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#fff",
          ":hover": {
            backgroundColor: "#f2f3f3",
          },
        }}
      >
        <img src="/images/icon/icon_move.svg" alt="" />
      </Grid>
      {/* 播放暂停按钮 */}
      <Grid
        container
        onClick={() => {
          //如果录屏状态为running则暂停录制
          if (recordState === "running") {
            pauseRecord();
            setRecordState("stop");
          }
          //如果录屏状态为stop则继续录制
          if (recordState === "stop") {
            resumeRecord();
            setRecordState("running");
          }
        }}
        sx={{
          width: "36px",
          height: "36px",
          borderRadius: "50%",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#2d89f4",
        }}
      >
        {recordState === "running" && <img src="/images/icon/icon_pause.svg" alt="" />}
        {recordState === "stop" && <img src="/images/icon/icon_play.svg" alt="" />}
      </Grid>
      {/* 麦克风按钮 */}
      <Grid
        container
        onClick={() => {
          //如果录屏状态为running则可以打开关闭麦克风
          if (recordState === "running") {
            //没有可用麦克风设备则返回
            if (!hasMicrophone.current) return;
            toggleMicrophone("mic", !micAble);
            setMicAble(!micAble);
          }
          //如果录屏状态为stop则结束录屏
          if (recordState === "stop") {
            isCancel.current = false;
            stopRecord();
          }
        }}
        sx={{
          width: "36px",
          height: "36px",
          border: "1px solid rgb(237, 237, 237)",
          borderRadius: "50%",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            recordState === "stop" ? "#fff" : !isHasMic ? "#f2f1f4" : micAble ? "#2d89f4" : "#fff",
        }}
      >
        {recordState === "stop" && <img src="/images/icon/icon_complete.svg" alt="" />}
        {recordState === "running" && (
          <img
            src={
              !isHasMic
                ? "/images/icon/no-mic-active.svg"
                : micAble
                ? "/images/icon/mic-active.svg"
                : "/images/icon/mic-off.svg"
            }
            alt=""
          />
        )}
      </Grid>
      {/* 系统音频按钮 */}
      <Grid
        container
        onClick={() => {
          //如果录屏状态为running则可以打开关闭系统音频
          if (recordState === "running") {
            if (!hasSysAudio) return;
            toggleMicrophone("tab", !sysAble);
            setSysAble(!sysAble);
          }
          //如果录屏状态为stop则结束录屏
          if (recordState === "stop") {
            isCancel.current = true;
            stopRecord();
          }
        }}
        sx={{
          width: "36px",
          height: "36px",
          border: "1px solid rgb(237, 237, 237)",
          borderRadius: "50%",
          cursor: "pointer",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor:
            recordState === "stop"
              ? "#fff"
              : !hasSysAudio
              ? "#f2f1f4"
              : sysAble
              ? "#2d89f4"
              : "#fff",
        }}
      >
        {recordState === "stop" && <img src="/images/icon/icon_cancel.svg" alt="" />}
        {recordState === "running" && (
          <img
            src={sysAble ? "/images/icon/tab-audio-off.svg" : "/images/icon/tab-audio-on.svg"}
            alt=""
          />
        )}
      </Grid>
    </Grid>
  );
}
