import React from 'react'
import { Grid } from '@mui/material'
import Icon from "../ui/icon";
import { useNavigate } from "react-router-dom";

export default function HeadTitle() {
  const navigate = useNavigate();
  return (
    <Grid
      container
      onClick={() => {
        navigate("/");
      }}
      sx={{
        width: "100%",
        height: "88px",
        alignItems: "center",
        cursor: "pointer",
      }}
    >
      <Icon
        name="logo"
        width={29}
        height={28}
        style={{
          marginLeft: "4px",
        }}
      />
      <img
        src="./images/SRecorder.png"
        alt=""
        style={{
          width: "107px",
          marginLeft: "15px",
        }}
      />
    </Grid>
  );
}
