import React from 'react'
import { Button, Typography } from '@mui/material'
import COLOR_THEME from '../../../utils/color-scheme'
import Icon from '../icon'

export default function LabelButton({
    title,
    icon,
    active = false,
    onClick,
    style,
}) {
    // 判断鼠标是否触摸
    const [isHover, setIsHover] = React.useState(false)
    return (
        <Button
            onClick={onClick}
            // 鼠标触摸
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            variant='contained'
            sx={{
                width: '100%',
                padding: '6px 12px',
                backgroundColor: active ? '#DFE8FF' : '#F9F9F9',
                justifyContent: 'flex-start',
                lineHeight: '24px',
                borderRadius: '8px',
                boxShadow: 'none',
                fontSize: '14px',
                fontWeight: active ? '700' : '400',
                fontFamily: 'Roboto',
                color: active ? '#2F65F7' : '#000000',
                ":hover": {
                    backgroundColor: COLOR_THEME.secondary.hover,
                    boxShadow: 'none',
                    color: '#2F65F7',
                    // fontWeight: '700',
                },
                ...style,
            }}
        >
            <Icon
                name={isHover || active ? `${icon}_active` : icon}
                color='#000000'
                style={{
                    marginRight: '7px',
                }} />
            {title}
        </Button>
    )
}
