import React from 'react'
import { Box, Grid } from '@mui/material'
import IconButton from '../ui/button/icon-button'
import { fileSizeFormat } from "../../utils/fileSizeFormat";
export default function ProgressBar({
  item,
  index,
  setGlobalUploadFileToDrive,
  globalUploadFileToDrive,
}) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "56px",
        borderRadius: "4px",
        backgroundColor: "rgba(247, 247, 247, 1)",
        position: "relative",
        cursor: "pointer",
        boxSizing: "border-box",
        marginTop: "16px",
      }}
    >
      {/* 进度显示条 */}
      <Box
        sx={{
          width: item.progress + "%",
          height: "100%",
          borderRadius: "4px",
          backgroundColor: "rgba(47, 101, 247, 0.2)",
          position: "absolute",
          left: "0px",
          top: "0px",
        }}
      />

      {/* 文件信息 */}
      <Grid
        container
        sx={{
          width: "100%",
          height: "100%",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "4px 16px",
          boxSizing: "border-box",
        }}
      >
        {/* 文件信息 */}
        <Box
          sx={{
            width: "280px",
            height: "100%",
          }}
        >
          <Box
            sx={{
              with: "100%",
              lineHeight: "24px",
              fontSize: "14px",
              fontWeight: "500",
              fontFamily: "Roboto",
              color: "#1F1F1F",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {item?.videoTitle}
          </Box>
          <Box
            sx={{
              width: "100%",
              lineHeight: "24px",
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.6)",
            }}
          >
            {fileSizeFormat(item?.videoSize)}
          </Box>
        </Box>

        {/* 按钮 */}
        <Grid
          container
          sx={{
            height: "100%",
            width: "auto",
            alignItems: "center",
          }}
        >
          <IconButton
            icon="upload_cancel"
            onClick={() => {
              item?.xhr?.abort();
              globalUploadFileToDrive?.splice(globalUploadFileToDrive.length - 1 - index, 1);
              setGlobalUploadFileToDrive([...globalUploadFileToDrive]);
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
