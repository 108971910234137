import {  fetchFile } from "@ffmpeg/util";
import newFFmpegFactory from "./newFFmpeg";

const trimVideoFFmpegCommand = (splitArr, videoFile) => {
  let ssTo = [];
  let inputFile = [];
  let stream = "";
  let videoStream = "";
  let audioStream = "";
  let videoAudioMap = "";

  // -ss 00:00:00 -t 00:00:10 -c copy 11.mp4

  for (let index = 0; index < splitArr.length; index++) {
    let start = splitArr[index].position;
    let end = splitArr[index].position + splitArr[index].length;

    videoStream = videoStream + `[0:v]trim=${start}:${end},setpts=PTS-STARTPTS[v${index}];`;
    audioStream = audioStream + `[0:a]atrim=${start}:${end},asetpts=PTS-STARTPTS[a${index}];`;
    videoAudioMap = videoAudioMap + `[v${index}][a${index}]`;

  }

  // 拼接命令
  let ffmpeg_command =
    videoStream + audioStream + videoAudioMap + `concat=n=${splitArr.length}:v=1:a=1[out]`;

  return {
    ssTo,
    inputFile,
    concatStream: stream,
    concat: "concat=n=" + splitArr.length + ":v=1:a=1[out]",
    ffmpeg_command,
  };
};

export { trimVideoFFmpegCommand };









const splitVideo = async (file, ffmpeg_command) => {
  let ffmpeg = await newFFmpegFactory.loadFFmpeg();

  /**
   *
   *
   * @description: 写入文件
   *
   *
   */

  await ffmpeg.writeFile(`input.mp4`, await fetchFile(file));



  /**
   *
   *
   * @description: 执行命令
   *
   *
   */

  await ffmpeg.exec([
   "-i",
   "input.mp4",
   "-preset",
   "ultrafast",
   "-filter_complex",
   ffmpeg_command.ffmpeg_command,
   "-map",
   "[out]",
   `output.mp4`,
  ]);

  /**
   *
   *
   * @description: 读取文件
   *
   *
   */
  
    const data = await ffmpeg.readFile("output.mp4");
    const dataFile = new File([data], "output.mp4", { type: "video/mp4" });

    return dataFile;
};

export default splitVideo;
