import React, { useContext } from 'react'
import { Dialog, Box, Grid } from "@mui/material";
import IconButton from '../../../components/ui/button/icon-button';
// 
import VideosStorage from '../../../storage/videos-storage';


export default function VideoDialog({
    isOpen,
    onClose,
    addSplit,
}) {
    // 视频列表
    const [videoList, setVideoList] = React.useState([])

    // 初始化读取视频数据
    React.useEffect(() => {
        // 获取videos列表
        const getVideosList = async () => {
            const videosList = await VideosStorage.getAll()
            if (videosList.length > 0) {
                setVideoList(videosList.videos)
            }
        }

        getVideosList();

    }, [])


    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
        >
            <Box
                sx={{
                    width: '556px',
                    height: '500px',
                    boxSizing: 'border-box',
                }}
            >
                <Box
                    sx={{
                        padding: '20px 40px',
                        width: '100%',
                        boxSizing: 'border-box',
                    }}
                >
                    <IconButton
                        onClick={onClose}
                        icon='close'
                        style={{
                            width: '32px',
                            height: '32px',
                            position: 'absolute',
                            top: '8px',
                            right: '8px',
                        }}
                    />
                    {/* title */}
                    <span
                        style={{
                            fontSize: '18px',
                            fontWeight: '700',
                        }}
                    >
                        Add a clip
                    </span>
                </Box>

                {/* 分割线 */}
                <Box
                    sx={{
                        width: '100%',
                        backgroundColor: '#e1e0e7',
                        height: '1px',
                        margin: '8px 0px',
                    }} />

                {/* 选择视频 */}
                <Box
                    sx={{
                        height: '415px',
                        padding: '0px 40px',
                        // 内容超出高度，垂直滚动内容
                        overflowY: 'auto',
                        // 内容超出宽度，水平滚动内容
                        overflowX: 'hidden',
                        // 内容超出高度，显示滚动条
                        '&::-webkit-scrollbar': {
                            width: '0px',
                            height: '0px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                            borderRadius: '4px',
                            backgroundColor: '#d3d1d9',
                        },
                    }}
                >
                    {
                        videoList.map((item, index) =>
                            <VideoItem
                                key={index}
                                videoKey={item}
                                onClick={(src, duration,videoSize) => {
                                    addSplit(src, duration,videoSize);
                                    onClose()
                                }}
                            />)
                    }

                </Box>
            </Box>
        </Dialog>
    )
}

function VideoItem({
    videoKey,
    onClick,
}) {
    const [videoTitle, setVideoTitle] = React.useState('')
    const [imageSrc, setImageSrc] = React.useState('')
    const [videoTime, setVideoTime] = React.useState('')
    const [videoDuration, setVideoDuration] = React.useState('')
    const [videoSize,setVideoSize] = React.useState({
        videoWidth:0,
        videoHeight:0,
    })
    const [videoSrc, setVideoSrc] = React.useState('')

    // 初始化读取数据
    React.useEffect(() => {
        const getVideoInfo = async () => {
            const info = await VideosStorage.read(videoKey)

            setVideoTitle(info?.videoTitle);
            setImageSrc(info?.videoCover);
            setVideoDuration(info?.videoDuration);
            setVideoTime(formatSeconds(info?.videoDuration));
            setVideoSize({
                videoWidth:info?.videoWidth,
                videoHeight:info?.videoHeight,
            })

            // 获取src
            const src = URL.createObjectURL(new Blob(info?.file, { type: "video/webm" }));
            setVideoSrc(src)

        }

        getVideoInfo();

    }, [videoKey])

    return (
        <Grid
            container
            onClick={() => onClick(videoSrc, videoDuration,videoSize)}
            sx={{
                width: '100%',
                height: '104px',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer',
                borderRadius: '8px',
                padding: '8px',
                marginTop: '8px',
                ":hover": {
                    backgroundColor: '#DFE8FF',
                }
            }}
        >
            <img src={imageSrc} alt=''
                style={{
                    height: '100%',
                    maxWidth: '189px',
                    borderRadius: '4px',
                }}
            ></img>

            <Box
                sx={{
                    width: '260px',
                    height: '100%',
                }}
            >
                <p
                    style={{
                        width: '100%',
                        fontSize: '14px',
                        fontWeight: '700',
                        lineHeight: '16px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    }}
                >
                    {videoTitle}
                </p>

                <Grid
                    container
                    sx={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '56px',
                        height: '26px',
                        borderRadius: '8px',
                        backgroundColor: '#212121',
                        marginTop: '8px',
                    }}
                >
                    <p
                        style={{
                            fontSize: '12px',
                            fontWeight: '400',
                            color: '#fff',

                        }}
                    >
                        {videoTime}
                    </p>
                </Grid>

            </Box>

        </Grid>
    )
}

//时间转换，将秒转换为h,m,s格式,向下取整,只保留最大单位
function formatSeconds(value) {
    let secondTime = parseInt(value);// 秒
    let minuteTime = 0;// 分
    let hourTime = 0;// 小时
    if (secondTime > 60) {//如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
            //获取小时，获取分钟除以60，得到整数小时
            hourTime = parseInt(minuteTime / 60);
            //获取小时后取佘的分，获取分钟除以60取佘的分
            minuteTime = parseInt(minuteTime % 60);
        }
    }

    if (hourTime > 0) {
        return parseInt(hourTime) + " h";
    }

    if (minuteTime > 0) {
        return parseInt(minuteTime) + " m";
    }

    return parseInt(secondTime) + " s";
}