import { RefreshToken, isRefreshToken } from "./RefreshToken";
import axios from "axios";
import { AES } from "crypto-js";
import { encryptText } from "./AES";
async function googleLogin() {
  /**
   *
   * @description: 判断是否登陆过
   *
   */
  let params = new URLSearchParams(window.location.search);
  let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
  if (googleAuthInfo && JSON.parse(googleAuthInfo)?.refresh_token) {
    /**
     *
     * @description: 已经授权过
     *
     */
    if (isRefreshToken()) {
      await RefreshToken();
    }
    return true;
  } else {
    /**
     *
     * @description: 首次授权的重定向
     *
     */
    if (params.get("code")) {
      let codeAuthorization = await axios.post(
        `https://accounts.google.com/o/oauth2/token?code=${params.get("code")}&client_id=${
          process.env.REACT_APP_CLIENT_ID
        }&client_secret=${process.env.REACT_APP_DRIVE_SECRET_KEY}&redirect_uri=${
          process.env.REACT_APP_REDIRECT_URI
        }&grant_type=authorization_code`,
      );

      /**
       *
       * @description: 加密
       *
       */
      codeAuthorization.data.refresh_token = encryptText(codeAuthorization.data.refresh_token);
      codeAuthorization.data.access_token = encryptText(codeAuthorization.data.access_token);

      let codeAuthorizationData = {
        ...codeAuthorization.data,
        expires_in: new Date().getTime() + codeAuthorization.data.expires_in * 1000,
      };
      window.localStorage.setItem("googleAuthInfo", JSON.stringify(codeAuthorizationData));
      window.location.href = process.env.REACT_APP_REDIRECT_URI;
      return false;
    } else {
      window.location.href = `https://accounts.google.com/o/oauth2/auth/oauthchooseaccount?access_type=offline&client_id=${process.env.REACT_APP_CLIENT_ID}&include_granted_scopes=true&prompt=consent&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=code&scope=${process.env.REACT_APP_SCOPES_AUTHORIZATION}&service=lso&o2v=1&flowName=GeneralOAuthFlow`;
      return false;
    }
  }
}

export default googleLogin;
