class OperateHistory {
    constructor(setIsUndo, setIsRedo) {
        this.setIsUndo = setIsUndo;
        this.setIsRedo = setIsRedo;
    }

    undoList = [];
    redoList = [];

    current = null;

    operateHandle = (item) => {
        if (this.current) {
            this.undoList.push(this.current);
            this.current = item;
            this.setIsUndo(true);
        } else {
            this.current = item;
        }

        if (this.redoList.length > 0) {
            this.redoList = [];
            this.setIsRedo(false);
        };

    }

    undo = (callBack) => {
        if (this.undoList.length === 0) return;

        this.redoList.push(this.current);

        this.current = this.undoList[this.undoList.length - 1];
        callBack(this.current);

        this.undoList.pop();

        if (this.undoList.length === 0) this.setIsUndo(false)

    }

    redo = (callBack) => {
        if (this.redoList.length === 0) return;

        this.undoList.push(this.current);

        this.current = this.redoList[this.redoList.length - 1];
        callBack(this.current);

        this.redoList.pop();

        if (this.redoList.length === 0) this.setIsRedo(false)
    }
}

export default OperateHistory;