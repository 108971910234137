import React from 'react'
import { Grid, Box } from '@mui/material'
import TextButton from '../../../components/ui/button/text-button'
// 
import VideoDialog from './video-dialog'
import TimePeriod from './time-period';
import VideoControls from '../../../components/video-controls';
// 
import { randomKey } from '../../../utils/random';
import OperateHistory from '../../../common/operate-history';
import executeSaveButton from "../../../utils/ffmpeg/stitchVideoFFmpeg"; //导入 stitch 按钮保存函数

export default function StitchPage({ setToolState, videoInfo }) {
  // 是否打开弹窗
  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  // 视频拼接数据
  const [stitchData, setStitchData] = React.useState([]); // [{key, src, duration}]
  // 每个时间段的长度
  const [periodWidth, setPeriodWidth] = React.useState(0);
  // 活跃id
  const [activeId, setActiveId] = React.useState(null);
  const [activeVideo, setActiveVideo] = React.useState(null); // { src, duration}
  // 是否可以删除
  const [isDelete, setIsDelete] = React.useState(false);
  // 整条进度dom
  const timeBarRef = React.useRef(null);
  //
  const videoRef = React.useRef(null);
  //
  const operateHistory = React.useRef(null);
  const [isUndo, setIsUndo] = React.useState(false);
  const [isRedo, setIsRedo] = React.useState(false);

  React.useEffect(() => {
    operateHistory.current = new OperateHistory(setIsUndo, setIsRedo);
  }, []);

  // 初始化
  React.useEffect(() => {
    if (!videoInfo) return;
    const videoSrc = URL.createObjectURL(new Blob(videoInfo.file, { type: "video/webm" }));
    //
    const key = randomKey();
    setStitchData([
      {
        key: key,
        src: videoSrc,
        duration: videoInfo.videoDuration,
        videoWidth: videoInfo.videoWidth,
        videoHeight: videoInfo.videoHeight,
      },
    ]);

    setActiveId(key);
    setActiveVideo({
      src: videoSrc,
      duration: videoInfo.videoDuration,
    });

    setPeriodWidth(timeBarRef.current.offsetWidth);
  }, [videoInfo]);

  React.useEffect(() => {
    if (!activeId) return;

    if (!stitchData.find((item) => item.key === activeId)) setIsDelete(false);

    if (stitchData.length === 1) setIsDelete(false);

    if (activeId && stitchData.length > 1) setIsDelete(true);

    if (stitchData.length < 1) setIsDelete(false);
  }, [activeId, stitchData]);

  // 添加时间段
  const addSplit = React.useCallback(
    (src, duration,{videoWidth,videoHeight}) => {
      setStitchData([
        ...stitchData,
        {
          key: randomKey(),
          src: src,
          duration: duration,
          videoWidth,
          videoHeight
        },
      ]);

      setPeriodWidth(
        (timeBarRef.current.offsetWidth - 5 * stitchData.length) / (stitchData.length + 1),
      );
    },
    [stitchData],
  );

  // 删除时间段
  const deleteSplit = React.useCallback(() => {
    if (!activeId) return;

    const activeIndex = stitchData.findIndex((item) => item.key === activeId);

    const newSplitData = stitchData.filter((item) => item.key !== activeId);
    setStitchData(newSplitData);

    // 修改数据
    if (newSplitData.length === 1) {
      setPeriodWidth(timeBarRef.current.offsetWidth);
    } else {
      setPeriodWidth(
        (timeBarRef.current.offsetWidth - 5 * newSplitData.length - 1) / newSplitData.length,
      );
    }

    // 修改activeId
    if (activeIndex === 0) {
      setActiveId(newSplitData[0].key);
      setActiveVideo({
        src: newSplitData[0].src,
        duration: newSplitData[0].duration,
      });
    } else {
      setActiveId(newSplitData[activeIndex - 1].key);
      setActiveVideo({
        src: newSplitData[activeIndex - 1].src,
        duration: newSplitData[activeIndex - 1].duration,
      });
    }
  }, [activeId, stitchData]);

  // main
  return (
    <Grid
      xs
      item
      sx={{
        width: "100%",
        height: "calc(100% - 65px)",
      }}
    >
      {/* 弹窗 */}
      <VideoDialog
        isOpen={isOpenDialog}
        onClose={() => setIsOpenDialog(false)}
        addSplit={addSplit}
      />
      {/* video */}
      <Grid
        container
        sx={{
          width: "100%",
          height: "calc(100% - 100px)",
          backgroundColor: "#000",
          position: "relative",
        }}
      >
        <video
          ref={videoRef}
          src={activeVideo?.src}
          style={{
            height: "100%",
            width: "100%",
            objectFit: "contain",
          }}
        />
        <VideoControls videoRef={videoRef} videoDuration={activeVideo?.duration} />
      </Grid>

      {/* 工具栏 */}
      <Grid
        container
        sx={{
          height: "78px",
          width: "100%",
          padding: "0px 28px",
          boxSizing: "border-box",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* 左侧 */}
        <Grid
          container
          sx={{
            height: "36px",
            width: "auto",
          }}
        >
          <TextButton
            onClick={() => setIsOpenDialog(true)}
            title="Add clip"
            icon="add"
            style={{
              marginRight: "16px",
            }}
          />
          <TextButton
            onClick={deleteSplit}
            disabled={!isDelete}
            title="Delete clip"
            icon="delete"
          />
          <Box
            sx={{
              width: "1px",
              height: "36px",
              backgroundColor: "#d7d5dd",
              margin: "0px 16px",
            }}
          />

          {/* 保存按钮  */}
          <TextButton
            onClick={() => {
            //   console.log(stitchData);
                executeSaveButton(stitchData);
            }}
            title="Save"
            icon="save"
            style={{
              marginRight: "16px",
            }}
          />
          <TextButton onClick={() => setToolState("play")} title="Cancel" icon="cancel" />
        </Grid>

        {/* 右侧 */}
        <Grid
          container
          sx={{
            height: "36px",
            width: "auto",
          }}
        >
          <TextButton
            disabled={!isUndo}
            title="Undo"
            onClick={() => operateHistory.current.undo()}
          />
          <Box
            sx={{
              width: "1px",
              height: "36px",
              backgroundColor: "#d7d5dd",
              margin: "0px 16px",
            }}
          />
          <TextButton
            disabled={!isRedo}
            title="Redo"
            onClick={() => operateHistory.current.redo()}
          />
        </Grid>
      </Grid>

      {/* 时间条 */}
      <Box
        sx={{
          width: "100%",
          height: "48px",
          padding: "0px 28px",
          boxSizing: "border-box",
        }}
      >
        <Grid
          ref={timeBarRef}
          container
          sx={{
            width: "100%",
            height: "100%",
            boxSizing: "border-box",
            position: "relative",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {stitchData.map((item, index) => (
            <TimePeriod
              key={index}
              width={periodWidth}
              isActive={item.key === activeId}
              onClick={() => {
                setActiveId(item.key);
                setActiveVideo({
                  src: item.src,
                  duration: item.duration,
                });
              }}
            />
          ))}
        </Grid>
      </Box>
    </Grid>
  );
}
