import React from 'react'
import { Grid } from '@mui/material'
// 
import QuickStart from '../quick-start'
import HomeList from '../../../components/home-list'
import TemplateCard from '../../../components/template-card'

export default function Background() {
    return (
        <Grid
            item
            xs
            sx={{
                width: '100%',
                maxWidth: '1040px',
                height: 'calc(100vh - 64px)',
                boxSizing: 'border-box',
                padding: '0px 50px 64px 50px',
                position: 'relative',
                // 内容超出高度，垂直滚动内容
                overflowY: 'auto',
                // 内容超出宽度，水平滚动内容
                overflowX: 'hidden',
                // 内容超出高度，显示滚动条
                '&::-webkit-scrollbar': {
                    width: '0px',
                    height: '0px',
                },
                '&::-webkit-scrollbar-thumb': {
                    borderRadius: '4px',
                    backgroundColor: '#d3d1d9',
                },
            }}
        >
            <QuickStart
                title='Templates'
                subTitle={'8 Templates'}
            />
            <HomeList
                listData={[1, 2, 3, 4, 5, 6, 7, 8]}
                ListItem={TemplateCard}
                minWidth={'254px'}
            />
        </Grid>
    )
}
