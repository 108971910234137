import React, { useContext } from "react";
import { Box, Button, Grid, Popover } from "@mui/material";
import Icon from "../ui/icon";
import IconButton from "../ui/button/icon-button";
import MessageDialog from "../dialog/message-dialog";
import { Link } from "react-router-dom";
import UploadCard from "../upload-card";
import AccountCard from "../account-card";
import { GlobalContext } from "../../utils/GlobalContext";
import MyDrawer from "../../page/components/Home/MyDrawer";
import localforage from "localforage";

export default function Navbar({
  type,
  openMenu,
  videoTitle,
  isNavbarTitleEditor,
  setVideoTitle = () => {},
  setIsNavbarTitleEditor,
  videoKey,
  globalCurrentVideo,
}) {
  let { userToken } = useContext(GlobalContext);
  const navbarTitle = React.useRef(null);
  const [editorMoreAnchorEl, setEditorMoreAnchorEl] = React.useState(null); // 编辑器右侧更多按钮 popover

  const editorMoreIsOpen = Boolean(editorMoreAnchorEl);
  const handleEditorMoreClose = () => {
    setEditorMoreAnchorEl(null);
  };

  return (
    <Grid
      container
      sx={{
        width: "100%",
        height: "64px",
        justifyContent: type === "editor" ? "space-between" : "flex-end",
      }}
    >
      {/* 左侧 */}
      {type === "editor" && (
        <Grid
          container
          sx={{
            height: "100%",
            width: "auto",
            alignItems: "center",
          }}
        >
          {/* menu */}
          <IconButton
            icon={"menu"}
            onClick={openMenu}
            width={24}
            height={24}
            style={{
              marginLeft: "16px",
            }}
          />
          <Link to={"/"}>
            <Icon
              name={"logo"}
              width={29}
              height={28}
              style={{
                marginLeft: "26px",
                cursor: "pointer",
              }}
            />
          </Link>

          {/* <span

            style={{
              marginLeft: "16px",
              fontSize: "20px",
              fontWeight: "700",
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {videoTitle}
          </span> */}
          <input
            ref={navbarTitle}
            disabled={!isNavbarTitleEditor}
            style={{
              marginLeft: "16px",
              fontSize: "20px",
              fontWeight: "700",
              maxWidth: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              border: "none",
              background: "none",
            }}
            type="text"
            value={videoTitle}
            onBlur={async () => {
              setIsNavbarTitleEditor(false);
              /**
               *
               *
               * @description: 修改 indexdb 中视频的名称
               *
               *
               */
              let videoKeyVideoInfo = await localforage.getItem(videoKey);
              videoKeyVideoInfo.videoTitle = videoTitle;
              await localforage.setItem(videoKey, videoKeyVideoInfo);
            }}
            onChange={(e) => {
              setVideoTitle(e.target.value);
            }}
          />
        </Grid>
      )}
      {/* 右侧 */}
      <Grid
        container
        sx={{
          height: "100%",
          width: "auto",
          alignItems: "center",
        }}
      >
        {type === "editor" && (
          <IconButton
            onClick={(e) => {
              setEditorMoreAnchorEl(e.currentTarget);
            }}
            icon="more"
            width={24}
            height={24}
            style={{
              marginRight: "16px",
            }}
          />
        )}
        <Popover
          open={editorMoreIsOpen}
          anchorEl={editorMoreAnchorEl}
          onClose={handleEditorMoreClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "12px 8px",
              "& button": {
                color: "rgba(0, 0, 0, 0.87)",
                fontWeight: "500",
              },
            }}
          >
            <Button
              onClick={() => {
                setIsNavbarTitleEditor(true);
                setEditorMoreAnchorEl(null);

                setTimeout(() => {
                  navbarTitle.current.focus();
                }, 0);
              }}
            >
              Rename
            </Button>
            <Button
              onClick={() => {
                setEditorMoreAnchorEl(null);
                const a = document.createElement("a");
                a.href = URL.createObjectURL(globalCurrentVideo.videoFile);
                a.download = videoTitle;
                a.click();
                URL.revokeObjectURL(a.href);
              }}
            >
              Download
            </Button>
          </Box>
        </Popover>

        <img
          src="/images/chrome.png"
          alt=""
          style={{
            width: "132px",
            height: "36px",
            cursor: "pointer",
            marginRight: "16px",
          }}
        />
        {/* 上传按钮  */}

        {type !== "editor" && (
          <MessageDialog
            icon="upload"
            title="Upload"
            style={{
              marginRight: "24px",
            }}
          >
            <UploadCard />
          </MessageDialog>
        )}

        {/* 登录按钮 */}
        {!userToken ? (
          <MyDrawer anchor={"right"} from="navbar" startPage={"login"}>
            <IconButton
              icon="account"
              width={24}
              height={24}
              onClick={(e) => {
                console.log(e);
              }}
              style={{
                marginRight: "24px",
              }}
            />
          </MyDrawer>
        ) : (
          <MessageDialog
            title="My Profile"
            icon="account"
            style={{
              marginRight: "24px",
            }}
          >
            <AccountCard />
          </MessageDialog>
        )}
      </Grid>
    </Grid>
  );
}
