import React from 'react'
import { Box } from '@mui/material'


export default function HomeList({
    listData,
    ListItem,
    minWidth,
    selectVideo,
    state = 'normal',
    setVideosData,
    setTrashesData,
    selectedKeys
}) {


    return (
      <Box
        sx={{
          width: "100%",
          // Grid布局
          display: "grid",
          gridTemplateColumns: `repeat(auto-fill, minmax(${minWidth}, 1fr))`,
          gridTemplateRows: "auto",
          gap: "20px",
        }}
      >
        {listData?.map((item, index) => (
          <ListItem key={index} item={item} selectVideo={selectVideo} state={state} setVideosData={setVideosData} setTrashesData={setTrashesData} selectedKeys={selectedKeys}/>
        ))}
      </Box>
    );
}
