/* eslint-disable no-undef */
import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { withStyles } from "@material-ui/styles";
import { verifyPasswordFormat } from "../../../utils/verifyFormat";
import { ResetPassword } from "./userAction";
import { GlobalContext } from "../../../utils/GlobalContext";

const styles = (theme) => ({
  popoverPaper: {
    width: "100%",
    maxHeight: "unset",
    left: "0 !important",
    right: "0",
    maxWidth: "100% !important",
  },
});

const ForgetPassAppBar = (props) => {
  const { userSubState } = React.useContext(GlobalContext);
  //code 值
  const codeValues = ["3000", "3001", "3002", "3003"];
  //code可变化的变量
  const [CodeValue, setCodeValue] = React.useState(3000);
  //token 可变化量
  const [token, setToken] = React.useState();
  //error 判断是否正确
  const [error, setError] = React.useState(false);
  //错误的原因提示文字
  const [tips2, setTips2] = React.useState("");
  // 默认密码为空
  const [firstPassword, setFirstPassword] = React.useState("");
  const [secondPassword, setSecondPassword] = React.useState("");
  const [submitButtonDisable, setSubmitButtonDisable] = React.useState(false);
  //open 打开弹窗
  const [open, setOpen] = React.useState(false);
  const [message, setMessaget] = React.useState("");
  // 发送post请求保存重置的密码密码
  function sendResetPasswordRequest() {
    sendConstructEventByEventParams("forget_password_page", {
      click: "forget",
    });
    if (firstPassword.length < 8 || firstPassword !== secondPassword) {
      return;
    }
    const params = `token=${token}&password=${firstPassword}`;
    ResetPassword(token, firstPassword).then((res) => {
      switch (res) {
        case "save_reset_user_password_error_network":
          sendConstructEventByEventParams("forget_password_page", {
            forget_result: "fail",
            forget_fail_reason: "network error",
          });
          setOpen(true);
          setMessaget("network error");
          break;
        case "save_reset_user_password_error":
          sendConstructEventByEventParams("forget_password_page", {
            forget_result: "fail",
            forget_fail_reason: "reset password error",
          });
          setOpen(true);
          setMessaget("reset password error");
          break;
        case "save_reset_user_password_success":
          setOpen(true);
          setMessaget("reset ok");
          sendConstructEventByEventParams("forget_password_page", {
            forget_result: "success",
          });
          setTimeout(() => {
            document.getElementsByClassName("gohome")[0].click();
          }, 3000);
          break;
        default:
          break;
      }
    });
  }

  //3秒过后关闭弹窗
  React.useEffect(() => {
    setTimeout(function () {
      if (!open) {
        return;
      }
      setOpen(false);
    }, 3000);
  }, [open]);

  React.useEffect(() => {
    //获取url地址token 和code 参数
    const url = window.location.href; //http://localhost:3000/edu/edu_teacher?type=2&a=3
    const urlObj = {};
    if (url.indexOf("?") != -1) {
      const arr = url.slice(url.indexOf("?") + 1).split("&");
      arr.forEach((item) => {
        let param = item.split("=");
        urlObj[param[0]] = param[1];
      });
    }
    //判断当前token 不能为空 或者 code 不可以 或者 token和code都不可以
    if (!urlObj.token || !urlObj.code || (!urlObj.code && !urlObj.token)) {
      document.getElementsByClassName("gohome")[0].click();
    } else {
      setCodeValue(urlObj.code);
      setToken(urlObj.token);
    }
  }, []);

  //密码发送变化时，检测密码
  React.useEffect(() => {
    checkPassword();
  }, [firstPassword, secondPassword]);

  // 要求：由数字和字母组成，并且要同时含有数字和字母，且长度要在8-16位之间。
  const checkFirstPassword = (event) => {
    setFirstPassword(event.target.value);
  };
  //判断第二次密码是否和第一次一致
  const checkSecondPassword = (event) => {
    setSecondPassword(event.target.value);
  };
  //判断第一第二次密码不能为空
  const checkPassword = () => {
    if (firstPassword.length === 0 && secondPassword.length === 0) {
      setError(false);
      setTips2(null);
      setSubmitButtonDisable(true);
      return;
    }
    let checkFirstPasswordResult = verifyPasswordFormat(firstPassword);
    if (checkFirstPasswordResult && firstPassword === secondPassword) {
      setError(false);
      setSubmitButtonDisable(false);
      setTips2(null);
    } else if (!checkFirstPasswordResult) {
      setError(true);
      setSubmitButtonDisable(true);
      setTips2("the length should be 8-16 bits");
    } else {
      setError(true);
      setSubmitButtonDisable(true);
      setTips2("two passwords are inconsistent");
    }
  };
  return (
    <Box>
      <a href="/" className="gohome" position="profile_to_home" />
      {CodeValue === codeValues[0] && (
        <Box>
          <Container maxWidth="sm">
            <Box
              width="150px"
              marginTop="100px"
              sx={{
                display: "flex",
                alignItems: "center",
                marginBottom: "15px",
              }}
            >
              <img width="40px" src="/images/editor-recorder/logo.svg" />
              <span
                style={{
                  marginLeft: "10px",
                  fontSize: "20px",
                  fontWeight: "600",
                }}
              >
                SRecorder
              </span>
            </Box>
            <Box
              sx={{
                bgcolor: "#ffffff",
                height: "40vh",
                boxShadow: 1,
              }}
            >
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: 1 },
                  paddingTop: "20px",
                  paddingLeft: "50px",
                  paddingRight: "50px",
                  marginTop: "20px",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  sx={{
                    "& label": {
                      fontSize: "16px",
                      marginTop: "8px",
                    },
                  }}
                  id="new-password-input"
                  label="New Password"
                  variant="outlined"
                  type="password"
                  size={"small"}
                  error={error}
                  onChange={checkFirstPassword}
                  value={firstPassword}
                ></TextField>
              </Box>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: 1 },
                  paddingLeft: "50px",
                  paddingRight: "50px",
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  sx={{
                    "& label": { fontSize: "16px", marginTop: "8px" },
                  }}
                  id="filled-adornment-password"
                  label="Repeat Password"
                  error={error}
                  type="password"
                  size={"small"}
                  autoComplete="current-password"
                  helperText={tips2}
                  onChange={checkSecondPassword}
                  value={secondPassword}
                />
              </Box>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: 1 },
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
                noValidate
                autoComplete="off"
              >
                <FormControlLabel
                  sx={{
                    "& span": { fontSize: "14px" },
                  }}
                  control={<Checkbox defaultChecked />}
                  label="Sign out from other devices"
                />
              </Box>

              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: 1 },
                  paddingLeft: "55px",
                  paddingRight: "55px",
                }}
                noValidate
                autoComplete="off"
              ></Box>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": { m: 1, width: 1 },
                  paddingLeft: "55px",
                  paddingRight: "55px",
                  textAlign: "center",
                }}
                noValidate
                autoComplete="off"
              >
                <Button
                  onClick={() => {
                    sendResetPasswordRequest();
                  }}
                  variant="contained"
                  sx={{
                    textTransform: "none",
                    borderRadius: "24px",
                    width: "360px",
                    height: "48px",
                    outline: "none",
                    marginTop: "30px",
                  }}
                >
                  <Typography variant="body5">Submit</Typography>
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      )}
      {CodeValue === codeValues[1] && (
        <Box>
          <Typography variant="h1">The page has been reset</Typography>
        </Box>
      )}
      {CodeValue === codeValues[2] && (
        <Box>
          <Typography variant="h1">Expired validity period</Typography>
        </Box>
      )}
      {CodeValue === codeValues[3] && (
        <Box>
          <Typography variant="h1">Illegal link</Typography>
        </Box>
      )}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert
          icon={false}
          sx={{
            width: "100%",
            backgroundColor: "#253fe7",
            color: "#FFFFFF",
            display: "flex",
            alignItems: "center",
          }}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};
//修改
export default withStyles(styles)(ForgetPassAppBar);
