import React, { useContext } from "react";
import { Box, Grid } from "@mui/material";
import TextButton from "../ui/button/text-button";
import { GlobalContext } from "../../utils/GlobalContext";

export default function AccountCard() {
  let { userName, setUserToken, setUserName, setUserSubState, setUserSubPlan } =
    useContext(GlobalContext);

  return (
    <Grid
      container
      sx={{
        height: "228px",
        width: "100%",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* 头像 */}
      <Grid
        container
        sx={{
          width: "96px",
          height: "96px",
          borderRadius: "50%",
          border: "1px solid #D7DADF",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "20px",
        }}
      >
        <span
          style={{
            fontSize: "40px",
            fontWeight: "400",
            color: "#1F1F1F",
          }}
        >
          {userName.slice(1, 3)}
        </span>
      </Grid>

      {/* 用户名 */}
      <Box
        sx={{
          width: "200px",
          height: "24px",
          fontSize: "16px",
          fontWeight: "400",
          color: "#1F1F1F",
          marginBottom: "20px",
          textAlign: "center",
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {userName.replace(/"/g, "")}
      </Box>
      {/* 标记 退出登录  */}
      <TextButton
        onClick={async () => {
          await setUserToken(null);
          await setUserName(null);
          await setUserSubState(null);
          await setUserSubPlan(null);
        }}
        title="Logout"
      />
    </Grid>
  );
}
