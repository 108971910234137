/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Box } from '@mui/material'

export default function TimePeriod({
  position,
  length,
  minLength,
  maxLength,
  minPosition,
  update,
  setSplitPosition,
  id,
  isActive,
  setActiveId,
  initWidth,
  timeLineCD,
  mouseUp,
  videoDuration,
  videoRef
}) {
  const itemRef = React.useRef(null);
  // 鼠标在时间条中的位置
  const [mousePosition, setMousePosition] = React.useState(0);
  const [isShowMousePointer, setIsShowMousePointer] = React.useState(false);
  // 鼠标按下的初始坐标
  const mouseInitialPosition = React.useRef(0);
  // 是否处于拖拽状态
  const [isDrag, setIsDrag] = React.useState(false);

  // 时间段拖拽事件
  // 左侧拖拽
  const periodDragLeftMove = React.useCallback(
    (e) => {
      const distance = e.clientX - mouseInitialPosition.current;

      const newWidth = length - distance;
      if (newWidth < minLength) return;

      const newPosition = position + distance;
      if (newPosition < minPosition) return;

      update(id, {
        position: newPosition + 2,
        length: newWidth,
      });
      document.onmouseup = () => {
        mouseUp();
      };
    },
    [length, position, minLength],
  );

  // 右侧拖拽
  const periodDragRightMove = React.useCallback(
    (e) => {
      const distance = e.clientX - mouseInitialPosition.current;
      const newWidth = length + distance;
      if (newWidth < minLength) return;
      if (newWidth + position > maxLength) return;

      update(id, {
        length: newWidth,
      });
      document.onmouseup = () => {
        mouseUp();
      };
    },
    [length, update, minLength],
  );

  // 鼠标松开事件
  const periodDragUp = React.useCallback(() => {
    setIsDrag(false);

    window.removeEventListener("mousemove", periodDragLeftMove);
    window.removeEventListener("mousemove", periodDragRightMove);
    window.removeEventListener("mouseup", periodDragUp);
  }, [periodDragLeftMove, periodDragRightMove]);

  // 鼠标按下事件
  const periodDragDown = React.useCallback(
    (e, type) => {
      setIsDrag(true);

      mouseInitialPosition.current = e.clientX;

      if (type === "left") window.addEventListener("mousemove", periodDragLeftMove);
      if (type === "right") window.addEventListener("mousemove", periodDragRightMove);

      window.addEventListener("mouseup", periodDragUp);
    },
    [periodDragLeftMove, periodDragUp, periodDragRightMove],
  );
  return (
    <Box
      ref={itemRef}
      sx={{
        height: "100%",
        width: length * (timeLineCD.width / initWidth) + "px",
        position: "absolute",
        left: position * (timeLineCD.width / initWidth) + "px",
        top: "-2px",
        zIndex: isActive ? "10" : "auto",
      }}
    >
      {/* 左侧托块 */}
      {isActive && (
        <Box
          onMouseEnter={() => setIsShowMousePointer(false)}
          onMouseDown={(e) => periodDragDown(e, "left")}
          sx={{
            height: "100%",
            position: "absolute",
            left: 0 * (timeLineCD.width / initWidth) + "px",
            top: "2px",
            cursor: "col-resize",
          }}
        >
          <img
            src="/images/editor-recorder/track-left.svg"
            alt=""
            style={{
              height: "100%",
            }}
          />
        </Box>
      )}
      {/* 中间 */}
      <Box
        onMouseEnter={() => setIsShowMousePointer(true)}
        onMouseLeave={() => setIsShowMousePointer(false)}
        onMouseMove={(e) => {
          setMousePosition((e.clientX - position - 30) );
        }}
        onClick={(e) => {
          setActiveId(id);
          setSplitPosition(e.clientX - 30);
          // 根据长度计算时间 
          videoRef.current.currentTime = (e.clientX - 30) / timeLineCD.width * videoDuration;
        }}
        sx={{
          height: "100%",
          width: "100%",
          backgroundColor: isActive ? "#D2DEFF" : "#ffffff",
          borderRadius: "8px",
          border: isActive ? "2px solid #2F65F7" : "2px solid #c4c2ce",
        }}
      />
      {/* 右侧托块 */}
      {isActive && (
        <Box
          onMouseLeave={() => setIsShowMousePointer(false)}
          onMouseDown={(e) => periodDragDown(e, "right")}
          sx={{
            height: "100%",
            position: "absolute",
            right: -4 + "px",
            top: "2px",
            cursor: "col-resize",
          }}
        >
          <img
            src="/images/editor-recorder/track-right.svg"
            alt=""
            style={{
              height: "100%",
            }}
          />
        </Box>
      )}
      {/* 鼠标指针 */}
      {isShowMousePointer && !isDrag && (
        <Box
          sx={{
            width: "3px",
            height: "78px",
            position: "absolute",
            left: mousePosition * (timeLineCD.width / initWidth) + "px",
            top: "-15px",
            backgroundColor: "rgba(247, 101, 47, 0.3)",
            zIndex: "99",
            pointerEvents: "none",
          }}
        />
      )}
    </Box>
  );
}
