import React from 'react'
import ToolOptions from './tool-options'
import VideoPlay from './video-play'

export default function PlayPage({
    videoSrc,
    setToolState,
    videoDuration,
    changeThumbnail,
    transcriptData,
    transcriptState,
    addSubtitlesHandle,
    isAddSubtitles,
    isAddSubtitleLoading,
    reduceNoiseHandle,
    isReduceNoise,
    isRNoiseLoading,
    videoKey
}) {
    // 封面图片
    const [thumbnail, setThumbnail] = React.useState(null)


    return (
        <>
            <VideoPlay
                videoSrc={videoSrc}
                videoDuration={videoDuration}
                thumbnail={thumbnail}
            />

            {/* 视频编辑工具栏 */}
            <ToolOptions
                setToolState={setToolState}
                setThumbnail={setThumbnail}
                changeThumbnail={() => changeThumbnail(thumbnail)}
                transcriptData={transcriptData}
                transcriptState={transcriptState}
                addSubtitlesHandle={addSubtitlesHandle}
                isAddSubtitles={isAddSubtitles}
                isAddSubtitleLoading={isAddSubtitleLoading}
                reduceNoiseHandle={reduceNoiseHandle}
                isReduceNoise={isReduceNoise}
                isRNoiseLoading={isRNoiseLoading}
                videoKey={videoKey}
            />
        </>
    )
}
