import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
// 重置所有的样式
import "reset-css";
// 引入主题
import GlobalTheme from "./utils";
import "./css/global.scss";
import { GlobalContextProvider } from "./utils/GlobalContext";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const root = ReactDOM.createRoot(document.getElementById("root"));
window.toast = toast;
root.render(
  <React.Fragment>
    <GlobalContextProvider>
      <GlobalTheme>
        <BrowserRouter>
          <App />
        <ToastContainer />
        </BrowserRouter>
      </GlobalTheme>
    </GlobalContextProvider>
  </React.Fragment>,
);
