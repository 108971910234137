import React from 'react'
import { Box, Grid, Popover } from '@mui/material'
import IconButton from '../ui/button/icon-button'

export default function MessageDialog({
  title,
  icon,
  style,
  children,

}) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButton
        icon={icon}
        width={24}
        height={24}
        onClick={(e) => {
          // 打开浮窗
          setAnchorEl(e.currentTarget);
        }}
        style={style}
      />
      <Popover
        id={title + "popover"}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "8px",
          },
        }}
      >
        <Box
          sx={{
            width: "444px",
            height: "292px",
          }}
        >
          <Grid
            container
            sx={{
              width: "100%",
              height: "64px",
              padding: "0px 24px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                fontSize: "20px",
                fontWeight: "500",
                fontFamily: "Roboto",
                color: "rgba(31, 31, 31, 1)",
              }}
            >
              {title}
            </span>
            <IconButton icon="close" onClick={() => setAnchorEl(null)} />
          </Grid>
          {children}
        </Box>
      </Popover>
    </>
  );
}
