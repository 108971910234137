import loadFFmpeg from "./loadFFmpeg";
import videoToAudio from "./videoToAudio";
import webmArrayToMp4 from "./webmArrayToMp4FFmpeg";
import webmToMp4 from "./webmToMp4FFmpeg";
import noiseReduction from "./noiseReductionFFmpeg";
import splitVideo,{trimVideoFFmpegCommand} from "./splitVideoFFmpeg";

const newFFmpegFactory = {
    loadFFmpeg,
    videoToAudio,
    webmToMp4,
    webmArrayToMp4,
    noiseReduction,
    splitVideo,
    trimVideoFFmpegCommand
};

export default newFFmpegFactory;
