
import getToken from "./token";
import { getWaveBlob } from "webm-to-wav-converter";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import newFFmpegFactory from "../../utils/ffmpeg/newFFmpeg";

const transcript = async (videoFile, callback) => {
  let storeCurrentText = [];
  // 分离音频
  const audioFile = await newFFmpegFactory.videoToAudio(videoFile);

  // 获取token
  const token = await getToken();

  if (!audioFile || !token) {
    callback({
      type: "error",
      content: "获取音频失败",
    });
    return;
  }

  // 创建语音配置
  const speechConfig = sdk.SpeechConfig.fromAuthorizationToken(token, "eastus");
  speechConfig.speechRecognitionLanguage = navigator.language || navigator.userLanguage;
  // 创建语音识别器
  let autoDetectSourceLanguageConfig = sdk.AutoDetectSourceLanguageConfig.fromLanguages([
    "en-US",
    "de-DE",
    "zh-CN",
  ]);
  // 处理音频
  let waveBlob = await getWaveBlob(audioFile, false);
  let fileAudio = new File([waveBlob], "audio.wav", {
    type: "audio/wav",
  });
  let audioConfig = sdk.AudioConfig.fromWavFileInput(fileAudio);

  // 音频转换
  let speechRecognizer = sdk.SpeechRecognizer.FromConfig(
    speechConfig,
    autoDetectSourceLanguageConfig,
    audioConfig,
  );

  //
  speechRecognizer.recognizing = (s, e) => {
    storeCurrentText.push(e.result.text);
    console.log({
      text: e.result.text,
      time: e.result.offset,
    },);
    callback({
      type: "update",
      content: {
        text: e.result.text,
        time: e.result.offset,
      },
    });
  };

  //
  speechRecognizer.recognized = (s, e) => {
    callback({
      type: "add",
      content: [],
    });
  };

  //
  speechRecognizer.canceled = (s, e) => {
    if (e.reason === sdk.CancellationReason.Error) {
      callback({
        type: "error",
        content: e.errorDetails,
      });
    }

    speechRecognizer.stopContinuousRecognitionAsync();
  };

  //
  speechRecognizer.sessionStopped = (s, e) => {
    console.log(storeCurrentText, "storeCurrentText");
    if (storeCurrentText.length > 0) {
      callback({
        type: "end",
        content: "",
      });
    } else {
      callback({
        type: "null",
        content: {
       
        },
      });
      callback({
        type: "end",
        content: "",
      });
    }

    speechRecognizer.stopContinuousRecognitionAsync();
  };

  //
  speechRecognizer.startContinuousRecognitionAsync();
};

export default transcript;
