/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Box, Grid, Typography, Button } from '@mui/material'
//引入录屏悬浮工具条
import RecordTool from './RecordTool'
//引入模板数据
import { templateList } from '../../../data/templatesData'
//引入全局context
import { GlobalContext } from "../../../utils/GlobalContext";

export default function RecordPopover(props) {
    const { isOpenPopover, setIsOpenPopover } = props;
    // 引入全局属性
    const { setUserTemplate } = React.useContext(GlobalContext);
    //使用navigator.mediaDevices.getUserMedia获取可使用的音频设备
    function getMicrophoneList() {
        navigator.mediaDevices
            .getUserMedia({ audio: true })
            .then(function (stream) {
                navigator.mediaDevices.enumerateDevices().then(function (devices) {
                    const microphoneDevicesList = [...microphoneList];
                    devices.forEach(function (device, index) {
                        if (device.kind === "audioinput") {
                            microphoneDevicesList.push({ label: device.label, id: device.deviceId, index });
                            //存储可用的音频设备
                            setMicrophoneList(microphoneDevicesList);
                            //默认选中第一个音频设备
                            setCurrentMicrophone(microphoneDevicesList[1]);
                        }
                    });
                });
            })
            .catch(function (error) {
                console.log("navigator.getUserMedia error: ", error);
                setCurrentMicrophone({label:"No Microphones Available",id:"disabled",index:-2})
            });
    }
    //判断鼠标是否进入dom
    const [isMouseEnter, setIsMouseEnter] = React.useState(false);
    //判断是否打开录屏模式选择弹窗
    const [isOpenRecordMode, setIsOpenRecordMode] = React.useState(false);
    //判断是否打开麦克风选择弹窗
    const [isOpenMicrophone, setIsOpenMicrophone] = React.useState(false);
    //判断是否打开模板弹窗
    const [isOpenTemplate, setIsOpenTemplate] = React.useState(false);
    //可供选择的录屏模式
    const recordModeList = [{
        value: "monitor",
        name: "Full Screen",
        url: "/images/icon/full-screen.svg",
        activeUrl: "/images/icon/full-screen-active.svg",
        selectedUrl: "/images/icon/full-screen-selected.svg",
    }, {
        value: "window",
        name: "Window",
        url: "/images/icon/window.svg",
        activeUrl: "/images/icon/window-active.svg",
        selectedUrl: "/images/icon/window-selected.svg",
    }, {
        value: "browser",
        name: "Tab",
        url: "/images/icon/tab.svg",
        activeUrl: "/images/icon/tab-active.svg",
        selectedUrl: "/images/icon/tab-selected.svg",
    }]
    //可选择的麦克风设备
    const [microphoneList, setMicrophoneList] = React.useState([{ label: "No Microphone", id: "disabled", index: -1 }]);
    //当前选择的录屏模式
    const [currentRecordMode, setCurrentRecordMode] = React.useState(recordModeList[0]);
    //当前选择的麦克风设备
    const [currentMicrophone, setCurrentMicrophone] = React.useState(microphoneList[0]);
    //当前选择的模板
    const [currentTemplate, setCurrentTemplate] = React.useState({name:"no template",url:"no",index:-1});
    //判断是否开始准备录屏
    const [isStartRecord, setIsStartRecord] = React.useState(false);
    //是否显示隐藏控制面板和视频标签
    const [isShowControl, setIsShowControl] = React.useState(false);
    //video标签
    const videoRef = React.useRef(null);

    //获取可用的麦克风设备
    React.useEffect(() => {
        if (isOpenMicrophone && microphoneList.length === 1) {
            getMicrophoneList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpenMicrophone])

    //弹窗关闭时关闭所有弹窗
    React.useEffect(() => {
        //弹窗打开时，获取可用的音频设备
        if (isOpenPopover && microphoneList.length === 1) {
            getMicrophoneList();
        }
        //弹窗关闭时关闭所有弹窗
        if (!isOpenPopover || isStartRecord) {
            setIsOpenRecordMode(false);
            setIsOpenMicrophone(false);
            setIsOpenTemplate(false);
        }
    }, [isOpenPopover, isStartRecord])

    //选择模板
    React.useEffect(() => {
        async function openTemplate() {
            await setUserTemplate(currentTemplate.index);
            window.open("/template", "_blank");
        }
        if (currentTemplate.index !== -1) {
            openTemplate()
        }
    }, [currentTemplate])


    //main
    return (
        <Box
            sx={{
                width: "100vw",
                height: "100vh",
                position: "fixed",
                top: "0",
                left: "0",
                zIndex: "999",
                pointerEvents: isOpenPopover ? "all" : "none",
            }}>
            {/* 弹窗背景遮盖层 */}
            <Box
                sx={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "rgba(255,255,255,0.8)",
                    display: isOpenPopover ? "block" : "none",
                    zIndex: "999",
                }}
                onClick={() => {
                    if (isStartRecord) return;
                    setIsOpenPopover(false)
                }}
            />
            {/* 录屏弹窗 */}
            <Grid
                container
                flexDirection={"column"}
                sx={{
                    width: "280px",
                    height: "300px",
                    justifyContent: "space-between",
             
                    backgroundColor: "#fff",
                    borderRadius: "12px",
                    boxShadow: "0 0 12px -3px rgba(0,0,0,0.15) ",
                    position: "absolute",
                    top: "25px",
                    right: (isOpenPopover && !isStartRecord) ? "25px" : "-300px",
                    transition: "all 0.3s ease",
                    zIndex: "1000",
                    padding: "30px 20px",
                }}>
                {/* 图标 */}
                <Grid
                    container
                    sx={{
                        width: "100%",
                        height: "32px",
                        alignItems: "center",
                    }}>
                    <img style={{ height: "40px", marginRight: "10px" }} src="/images/logo-title.svg" alt="logo-title" />
                </Grid>
                {/* 录屏模式选择 */}
                <Grid
                    container
                    //鼠标进入事件
                    onMouseEnter={() => setIsMouseEnter("recordMode")}
                    //鼠标离开事件
                    onMouseLeave={() => setIsMouseEnter(false)}
                    //点击事件
                    onClick={() => {
                        setIsOpenRecordMode(!isOpenRecordMode)
                        setIsOpenMicrophone(false)
                        setIsOpenTemplate(false)
                    }}
                    sx={{
                        width: "100%",
                        height: "48px",
                        alignItems: "center",
                        padding: "8px 16px",
                        borderRadius: "24px",
                        backgroundColor: isOpenRecordMode ? "#b9c8f0" : "#f2f1f4",
                        cursor: "pointer",
                        color: isOpenRecordMode ? "#2d63f7" : "#000",
                        ":hover": {
                            backgroundColor: "#2d63f7",
                            color: "#fff",
                        }
                    }}>
                    <img alt=""
                        style={{ width: "18px", marginRight: "10px" }}
                        src={isMouseEnter === "recordMode" ? currentRecordMode.activeUrl :
                            isOpenRecordMode ? currentRecordMode.selectedUrl : currentRecordMode.url} />
                    <Typography
                        sx={{
                            fontSize: "14px",
                            fontWeight: "500",
                        }}>
                        {currentRecordMode.name}
                    </Typography>
                </Grid>
                {/* 麦克风设备选择 */}
                <Grid
                    container
                    //鼠标进入事件
                    onMouseEnter={() => setIsMouseEnter("microphone")}
                    //鼠标离开事件
                    onMouseLeave={() => setIsMouseEnter(false)}
                    //点击事件
                    onClick={() => {
                        if(currentMicrophone.index === -2) return;
                        setIsOpenMicrophone(!isOpenMicrophone)
                        setIsOpenRecordMode(false)
                        setIsOpenTemplate(false)
                    }}
                    sx={{
                        width: "100%",
                        height: "48px",
                        alignItems: "center",
                        padding: "8px 16px",
                        borderRadius: "24px",
                        backgroundColor: isOpenMicrophone ? "#b9c8f0" : "#f2f1f4",
                        cursor: "pointer",
                        color: isOpenMicrophone ? "#2d63f7" : "#000",
                        ":hover": {
                            backgroundColor: "#2d63f7",
                            color: "#fff",
                        }
                    }}>
                    {currentMicrophone.index < 0 && <img style={{ width: "18px", marginRight: "10px" }}
                        src={isMouseEnter === "microphone" ? "/images/icon/no-mic-active.svg" :
                            isOpenMicrophone ? "/images/icon/no-mic-selected.svg" :
                            "/images/icon/no-mic.svg"} alt="" />}
                    {currentMicrophone.index > -1 && <img style={{ width: "18px", marginRight: "10px" }}
                        src={isMouseEnter === "microphone" ? "/images/icon/mic-active.svg" :
                            isOpenMicrophone ? "/images/icon/mic-selected.svg" :
                            "/images/icon/mic.svg"} alt="" />}
                    <Typography
                        sx={{
                            width: "170px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}>
                        {currentMicrophone.label}
                    </Typography>
                </Grid>
                {/* 模板选择 */}
                {/* <Button variant="contained" color="primary"
                    endIcon={<img src="/images/icon/background-active.svg" alt="" />}
                    onClick={() => {
                        setIsOpenTemplate(!isOpenTemplate)
                        setIsOpenRecordMode(false)
                        setIsOpenMicrophone(false)
                    }}
                    sx={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "24px",
                        boxShadow:"none",

                    }}>
                    Select Template
                </Button> */}
                {/* <Grid
                    container
                    //鼠标进入事件
                    onMouseEnter={() => setIsMouseEnter("template")}
                    //鼠标离开事件
                    onMouseLeave={() => setIsMouseEnter(false)}
                    //点击事件
                    onClick={() => {
                        setIsOpenTemplate(!isOpenTemplate)
                        setIsOpenRecordMode(false)
                        setIsOpenMicrophone(false)
                    }}
                    sx={{
                        width: "100%",
                        height: "48px",
                        alignItems: "center",
                        padding: "8px 16px",
                        borderRadius: "24px",
                        backgroundColor: isOpenTemplate ? "#b9c8f0" : "#f2f1f4",
                        cursor: "pointer",
                        color: isOpenTemplate ? "#2d63f7" : "#000",
                        ":hover": {
                            backgroundColor: "#2d63f7",
                            color: "#fff",
                        }
                    }}
                >
                    <img alt="" style={{width:"18px",marginRight:"10px"}} 
                        src={isMouseEnter === "template" ? "/images/icon/background-active.svg" : 
                            isOpenTemplate ? "/images/icon/background-selected.svg" : 
                            "/images/icon/background.svg"}/>
                    <Typography
                        sx={{
                            width: "170px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            fontSize: "14px",
                            fontWeight: "500",
                        }}>
                        {currentTemplate.name}
                    </Typography>
                </Grid> */}
                {/* 开始录屏 */}
                <Button
                    onClick={() => {
                        setIsStartRecord(true)
                    }}
                    variant='contained'
                    endIcon={<img src="/images/icon/record.svg" alt="" />}
                    sx={{
                        width: "100%",
                        height: "48px",
                        borderRadius: "24px",
                        boxShadow:"none",
                        backgroundColor: "#f7623e",
                        ":hover": {
                            backgroundColor: "#f5380d",
                        }
                    }}
                >
                    Start Recording
                </Button>
                {/* 录屏模式选择弹窗 */}
                <Box
                    sx={{
                        width: "270px",
                        height: "144px",
                        position: "absolute",
                        left: isOpenRecordMode ? "-280px" : "-150px",
                        top: "92px",
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        opacity: isOpenRecordMode ? "1" : "0",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 20px, rgba(0, 0, 0, 0.35) 0px 25px 30px;",
                        transition: "opacity 200ms ease-out 0s, left 200ms ease-out 0s, right 200ms ease-out 0s;",
                        pointerEvents: isOpenRecordMode ? "auto" : "none",
                    }}>
                    {recordModeList.map((item, index) => {
                        return (
                            <Grid container key={index}
                                //鼠标进入事件
                                onMouseEnter={() => setIsMouseEnter(item.value)}
                                //鼠标离开事件
                                onMouseLeave={() => setIsMouseEnter(false)}
                                //点击事件
                                onClick={() => {
                                    setCurrentRecordMode(item)
                                    setIsOpenRecordMode(false)
                                }}
                                sx={{
                                    width: "100%",
                                    height: "48px",
                                    backgroundColor: currentRecordMode.value === item.value ? "#b9c8f0" : "#fff",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "0 16px",
                                    color: currentRecordMode.value === item.value ? "#2c63f7" : "#000",
                                    position: "relative",
                                    borderRadius: index === 0 ? "16px 16px 0 0" :
                                        index === recordModeList.length - 1 ? "0 0 16px 16px" : "0",
                                    ":hover": {
                                        backgroundColor: "#2c63f7",
                                        color: "#fff"
                                    }
                                }}>
                                <img alt=""
                                    style={{ width: "18px" }}
                                    src={isMouseEnter === item.value ? item.activeUrl : currentRecordMode.value === item.value ? item.selectedUrl : item.url} />
                                <Typography sx={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                    marginLeft: "10px",
                                }}>
                                    {item.name}
                                </Typography>
                                {currentRecordMode.value === item.value &&
                                    <img style={{ position: "absolute", right: "10px" }}
                                        src={isMouseEnter === item.value ?
                                            "images/icon/selected-active.svg" : "/images/icon/selected.svg"} alt="" />}
                            </Grid>
                        )
                    })}
                </Box>
                {/* 麦克风选择弹窗 */}
                <Box
                    sx={{
                        width: "270px",
                        height: 48 * microphoneList.length + "px",
                        position: "absolute",
                        left: isOpenMicrophone ? "-280px" : "-150px",
                        top: "92px",
                        backgroundColor: "#fff",
                        borderRadius: "16px",
                        opacity: isOpenMicrophone ? "1" : "0",
                        boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 20px, rgba(0, 0, 0, 0.35) 0px 25px 30px;",
                        transition: "opacity 200ms ease-out 0s, left 200ms ease-out 0s, right 200ms ease-out 0s;",
                        pointerEvents: isOpenMicrophone ? "auto" : "none",
                    }}>
                    {microphoneList.map((item, index) => {
                        return (
                            <Grid container key={index}
                                //鼠标进入事件
                                onMouseEnter={() => setIsMouseEnter(item.label)}
                                //鼠标离开事件
                                onMouseLeave={() => setIsMouseEnter(false)}
                                //点击事件
                                onClick={() => {
                                    setCurrentMicrophone(item)
                                    setIsOpenMicrophone(false)
                                }}
                                sx={{
                                    width: "100%",
                                    height: "48px",
                                    backgroundColor: currentMicrophone.index === item.index ? "#b9c8f0" : "#fff",
                                    alignItems: "center",
                                    cursor: "pointer",
                                    padding: "0 16px",
                                    color: currentMicrophone.index === item.index ? "#2c63f7" : "#000",
                                    position: "relative",
                                    borderRadius: microphoneList.length === 1 ? "16px" :
                                        index === 0 ? "16px 16px 0 0" :
                                            index === microphoneList.length - 1 ? "0 0 16px 16px" : "0",
                                    ":hover": {
                                        backgroundColor: "#2c63f7",
                                        color: "#fff"
                                    }
                                }}>
                                {index === 0 && <img alt=""
                                    src={isMouseEnter === item.label ? "/images/icon/no-mic-active.svg" :
                                        currentMicrophone.index === item.index ? "/images/icon/no-mic-selected.svg" :
                                            "/images/icon/no-mic.svg"} />}
                                {index !== 0 && <img alt=""
                                    src={isMouseEnter === item.label ? "/images/icon/mic-active.svg" :
                                        currentMicrophone.index === item.index ? "/images/icon/mic-selected.svg" :
                                            "/images/icon/mic.svg"} />}
                                <Typography sx={{
                                    width: "180px",
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    fontSize: "14px",
                                    fontWeight: "400",
                                    marginLeft: "10px",
                                }}>
                                    {item.label}
                                </Typography>
                                {currentMicrophone.index === item.index &&
                                    <img style={{ position: "absolute", right: "10px" }}
                                        src={isMouseEnter === item.label ?
                                            "images/icon/selected-active.svg" : "/images/icon/selected.svg"} alt="" />}
                            </Grid>
                        )
                    })}
                </Box>
                {/* 模板弹窗 */}
                <Grid
                container
                sx={{
                    width: "270px",
                    height: "260px",
                    position: "absolute",
                    left: isOpenTemplate ? "-280px" : "-150px",
                    top: "48px",
                    backgroundColor: "#fff",
                    borderRadius: "16px",
                    opacity: isOpenTemplate ? "1" : "0",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 20px, rgba(0, 0, 0, 0.35) 0px 25px 30px;",
                    transition: "opacity 200ms ease-out 0s, left 200ms ease-out 0s, right 200ms ease-out 0s;",
                    pointerEvents: isOpenTemplate ? "auto" : "none",
                    padding: "10px",
                    justifyContent: "space-around",
                    alignItems: "center",
                }}>
                    {templateList.map((item, index) => {
                        return(
                            <Grid key={index} container
                            onClick={()=>{
                                setCurrentTemplate(item)
                                setIsOpenTemplate(false)
                            }}
                            sx={{
                                width:"100px",
                                height:"58px",
                                cursor: "pointer",
                                borderRadius: "8px",
                                border:currentTemplate.name === item.name ? "2px solid #2c63f7" : "2px solid #fff",
                                ":hover":{
                                    border:"2px solid #2c63f7"
                                },
                                justifyContent:"center",
                                alignItems:"center",
                            }}>
                                <img alt='' style={{width:"96px",borderRadius:"8px"}} src={`/images/templates/${item.url}`}/>
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            {/* 同步录屏播放视角 */}
            <video ref={videoRef} src=""
                style={{
                    position: "fixed",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%,-50%)",
                    borderRadius: "10px",
                    maxHeight: "80vh",
                    maxWidth:"80vw",
                    boxShadow: "rgba(99, 99, 99, 1) 0px 2px 8px 0px",
                    opacity: isShowControl ? "1" : "0",
                    pointerEvents: isShowControl ? "auto" : "none",
                }}></video>
            {/* 录屏悬浮工具 */}
            {isStartRecord && <RecordTool
                currentRecordMode={currentRecordMode}
                currentMicrophone={currentMicrophone}
                setIsStartRecord={setIsStartRecord}
                isShowControl={isShowControl}
                setIsShowControl={setIsShowControl}
                videoRef={videoRef} />}
        </Box>
    )
}
