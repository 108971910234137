import React from 'react'
import { Box } from "@mui/material";

import ProgressBar from "./progress-bar";
import { GlobalContext } from "../../utils/GlobalContext";
export default function UploadCard() {
  let { globalUploadFileToDrive, setGlobalUploadFileToDrive } = React.useContext(GlobalContext);
  React.useEffect(() => {
    console.log("globalUploadFileToDrive", globalUploadFileToDrive);
  }, [globalUploadFileToDrive]);
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        boxSizing: "border-box",
        padding: "0px 24px",
      }}
    >
      {globalUploadFileToDrive
        .slice()
        .reverse()
        .map((item, index) => {
          return (
            <ProgressBar
              key={index}
              item={item}
              index={index}
              globalUploadFileToDrive={globalUploadFileToDrive}
              setGlobalUploadFileToDrive={setGlobalUploadFileToDrive}
            />
          );
        })}
    </Box>
  );
}
