import React, { useState, useRef, useEffect } from "react";
import { Box, Divider } from "@mui/material";
import "../css/TextEditor.css";

export default function TextEditor() {
  //tool list
  const toolList = ["bold", "italic", "underline", "deleteLine"];
  const [toolValue, setToolValue] = useState({
    bold: false,
    italic: false,
    underline: false,
    deleteLine: false,
    textAlign: "center",
  });
  //tool show
  const [isToolShow, setIsToolShow] = useState(false);
  //检测点击事件
  const myRef = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    }
  }, []);
  const handleClickOutside = (event) => {
    if (myRef.current && !myRef.current.contains(event.target)) {
      setIsToolShow(false);
    }
  }
  //main
  return (
    <Box
      ref={myRef}
      sx={{
        width: "100%",
        height: "100%",
        zIndex: 9998,
        position: "relative",
      }}
    >
      {isToolShow && (
        <Box
          onClick={() => {
            setIsToolShow(true);
          }}
          sx={{
            width: "300px",
            height: "34px",
            border: "1px solid #e2e1e6",
            borderRadius: "4px",
            boxShadow: "0px 2px 5px 0px rgba(0, 0, 0, 0.12)",
            padding: "6px",
            backgroundColor: "#f7f7f7",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            position: "absolute",
            left: toolValue.textAlign === "left" ? "0px" :
              toolValue.textAlign === "center" ? "calc(50% - 150px)" : "calc(100% - 300px)",
            top: "-50px",
          }}
        >
          {toolList.map((item, index) => {
            return (
              <Box
                key={index}
                onClick={() => {
                  setToolValue({ ...toolValue, [item]: !toolValue[item] });
                }}
                sx={{
                  width: "32px",
                  height: "32px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  borderRadius: "100%",
                  backgroundColor: toolValue[item] ? "#2d64f7" : "#ffffff",
                  ":hover": {
                    backgroundColor: toolValue[item] ? "#2d64f7" : "#ebebed",
                  },
                }}
              >
                <img
                  src={toolValue[item] ? `/images/icon/${item}-active.svg` : `/images/icon/${item}.svg`}
                  style={{ width: "18px", height: "18px" }}
                  alt=""
                />
              </Box>
            );
          })}
          <Divider orientation="vertical" />
          {
            ["left", "center", "right"].map((item, index) => {
              return (
                <Box
                  key={index}
                  onClick={() => {
                    setToolValue({ ...toolValue, textAlign: item });
                  }}
                  sx={{
                    width: "32px",
                    height: "32px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    borderRadius: "100%",
                    backgroundColor: toolValue.textAlign === item ? "#2d64f7" : "#ffffff",
                    ":hover": {
                      backgroundColor: toolValue.textAlign === item ? "#2d64f7" : "#ebebed",
                    },
                  }}
                >
                  <img
                    src={toolValue.textAlign === item ? `/images/icon/text_${item}_active.svg` : `/images/icon/text_${item}.svg`}
                    style={{ width: "18px", height: "18px" }}
                    alt=""
                  />
                </Box>
              )
            })
          }
        </Box>
      )}
      <textarea
        className="text-editor-area"
        placeholder="click here to edit text"
        onFocus={() => {
          setIsToolShow(true);
        }}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
          backgroundColor: "transparent",
          resize: "none",
          fontSize: "28px",
          fontFamily: "Roboto",
          fontWeight: toolValue.bold ? "bold" : "normal",
          fontStyle: toolValue.italic ? "italic" : "normal",
          textDecoration:
            toolValue.underline && toolValue.deleteLine
              ? "underline line-through"
              : toolValue.underline && !toolValue.deleteLine
                ? "underline"
                : !toolValue.underline && toolValue.deleteLine
                  ? "line-through"
                  : "none",
          color: "#000000",
          textAlign: toolValue.textAlign,
          overflow: "auto",
        }}
      />
    </Box>
  );
}
