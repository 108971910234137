import { Box,Button } from "@mui/material";
import React from "react";
import Typography from "@mui/material/Typography";
import Subscribe from "./Subscribe";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function SubscribePage({ handleClosePricing, setWhichFunction }) {
  return (
    <Box
      sx={{
        width: { xs: "550px", md: "calc(100vw - 80px)", lg: "calc(100vw - 80px)" },
        marginBottom: "40px",
        position: "relative",
      }}
    >
      {/* 标题 */}
      <Box
        sx={{
          marginTop: "100px",
       
        }}
      >
        <Button
        onClick={handleClosePricing}
        sx={{
          position:"absolute",
          top:"30px",
          right:"30px",
          padding:"0px",
          minWidth:"auto"
        }}
        >
          <HighlightOffIcon/>
        </Button>
        <Typography
          sx={{
            fontFamily:"Roboto-Medium",
            textAlign: "center",
            lineHeight: "40px",
            fontSize: {
              xl: "3.125rem",
              lg: "3rem",
              md: "2.8rem",
              sm: "2.5rem",
              xs: "2rem",
            },
            marginTop: { lg: "50px", md: "50px", sm: "50px", xs: "25px" },
            marginBottom: { lg: "50px", md: "40px", sm: "25px", xs: "20px" },
          }}
        >
          Software That Fits Your Budget
        </Typography>
        <Subscribe setWhichFunction={setWhichFunction} handleClosePricing={handleClosePricing} />
      </Box>
    </Box>
  );
}
