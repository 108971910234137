import React from 'react'
import { Box, Grid } from '@mui/material'
import { FileUploader } from "react-drag-drop-files";
import IconButton from '../../../../components/ui/button/icon-button'
import TextButton from '../../../../components/ui/button/text-button'
import Icon from '../../../../components/ui/icon'

export default function ChangeThumbnail({
    toEdit,
    setThumbnail,
    changeThumbnail,
}) {
    // 
    const [isDelete, setIsDelete] = React.useState(false)
    const [isSave, setIsSave] = React.useState(false)

    // 获取用户文件
    const uploadFile = React.useCallback((file) => {
        if (!file) return;

        setIsDelete(true)
        setIsSave(true)

        // 将file转换为base64
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            const base64 = reader.result;
            setThumbnail(base64)
        }

    }, [setThumbnail])

    // 删除封面
    const deleteThumbnail = React.useCallback(() => {
        setThumbnail(null)
        setIsDelete(false)
        setIsSave(false)
    }, [setThumbnail])


    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: '100%',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    height: 'auto',
                }}
            >
                {/* title */}
                <Grid
                    container
                    sx={{
                        width: '100%',
                        height: '42px',
                        alignItems: 'center',
                    }}
                >
                    <IconButton icon='back'
                        onClick={toEdit}
                        style={{
                            margin: '0px 8px',
                        }}
                    />

                    <span
                        sx={{
                            fontSize: '14px',
                            fontWeight: '500',
                            FontFamily: 'Roboto',
                            color: '#333333',
                        }}
                    >
                        Change thumbnail
                    </span>
                </Grid>

                <Box
                    sx={{
                        width: '100%',
                        padding: '0px 16px',
                        boxSizing: 'border-box',
                    }}
                >
                    {/* pick file */}
                    <FileUploader
                        name="file"
                        children={<FileUploadArea />}
                        handleChange={uploadFile}
                        types={['png', 'jpeg', 'jpg']}
                    />
                </Box>
            </Box>

            {/* button */}
            <Grid
                container
                sx={{
                    width: '100%',
                    height: 'auto',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginBottom: '36px',
                }}
            >
                {/* delete */}
                <TextButton
                    onClick={deleteThumbnail}
                    disabled={!isDelete}
                    title='Delete'
                    icon={'delete'}
                    style={{
                        margin: '0px 24px',
                    }}
                />

                {/* save */}
                <TextButton
                    disabled={!isSave}
                    onClick={() => {
                        changeThumbnail();
                        toEdit();
                        setThumbnail(null);
                    }}
                    title='Save'
                    style={{
                        margin: '0px 24px'
                    }}
                />
            </Grid>
        </Grid>
    )
}

function FileUploadArea() {
    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: '240px',
                padding: '16px 0px',
                margin: '16px auto',
                border: '1px dashed rgba(0, 0, 0, 0.38)',
                borderRadius: '10px',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    textAlign: 'center',
                    maxWidth: '215px',
                }}
            >
                <Icon name='thumbnail' style={{ marginBottom: '13px' }} />
                <Box
                    sx={{
                        lineHeight: '24px',
                        marginBottom: '4px',
                        fontSize: '14px',
                        fontWeight: '500',
                    }}
                >
                    <span
                        style={{
                            color: 'rgba(61, 61, 61, 1)',
                        }}
                    >
                        {`Drag and Drop or ${''}`}
                    </span>
                    <span
                        style={{
                            textDecoration: 'underline',
                            color: 'rgba(47, 101, 247, 1)',
                            cursor: 'pointer',
                        }}
                    >
                        Choose file
                    </span>
                </Box>
                <Box
                    sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        fontFamily: 'Roboto',
                        color: 'rgba(0, 0, 0, 0.38)',
                        marginBottom: '4px',
                    }}
                >
                    Recommend 1780x 1000px or 16:9 files in .png or .jpg
                </Box>
                <Box
                    sx={{
                        fontSize: '12px',
                        fontWeight: '400',
                        fontFamily: 'Roboto',
                        color: 'rgba(0, 0, 0, 0.38)',
                        lineHeight: '24px',
                    }}
                >
                    Maximum size:5MB
                </Box>
            </Box>

        </Grid>
    )
}
