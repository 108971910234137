/* eslint-disable no-undef */
import React, { useEffect, useRef, useState } from "react";
import { useMeasure, useMedia } from "react-use";
import FileSaver from "file-saver";
import localforage from "localforage";
import VideosStorage from "../../../storage/videos-storage";
// 引入样式模块
import EditorRecorderLeftStyle from "../../../css/editor-recorder-left.module.scss";
// 引入MUI
import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Dialog,
  Slider,
  Typography,
  Popover,
} from "@mui/material";
// 引入时间转化模块
import { secToTime } from "../../../utils/editor-recorder/handleTime";
import { GlobalContext } from "../../../utils/GlobalContext";
import VideoPlay from "./VideoPlay";
import Watermark from "./Watermark";
import Subtitle from "./Subtitle";
import VideoTrack from "./VideoTrack";
import { getSummarySysParams, getTxtSummary } from "../../../api/summary";
// 全局的延迟定时器
let isShowTopMaskTimeoutID;
export default function EditorRecorderLeft({
  videoInformation,
  videoRef,
  setVideoInformation,
  pageSize,
  whichFunctionOpen,
  setWhichFunctionOpen,
  tempSaveVideoTrackInformation,
  setTempSaveVideoTrackInformation,
  dataBank,
  setDataBank,
  isGet,
  setIsGet,
}) {
  let { summaryState, userSubState } = React.useContext(GlobalContext);
  const isWide = useMedia("(min-width: 665px)");
  // !title 区域
  // **重命名he下载弹出层
  const [renameDownloadAnchor, setRenameDownloadAnchor] = React.useState(null);
  const openRenameDownload = (event) => {
    setRenameDownloadAnchor(event.currentTarget);
  };
  console.log(videoInformation.videoSrc);
  const closeRenameDownload = () => {
    setRenameDownloadAnchor(null);
  };

  const isOpenRenameDownload = Boolean(renameDownloadAnchor);
  const renameDownloadPopoverID = isOpenRenameDownload ? "renameDownloadID" : undefined;
  // #重命名  弹出层

  const [isOpenRename, setIsOpenRename] = React.useState(false);

  const handleClickOpenRename = () => {
    sendConstructEventByEventParams("editor_recorder", {
      click: "rename",
    });
    setIsOpenRename(true);
  };
  // 取消保存
  const handleCloseRename = (value) => {
    setIsOpenRename(false);
    renameTextValue = videoInformation.videoTitle;
    setRenameTextValue(renameTextValue);
  };
  // 存储输入框的数值
  let [renameTextValue, setRenameTextValue] = useState(videoInformation.videoTitle);
  // #重命名  弹出层 --- end

  const downloadVideo = () => {
    sendConstructEventByEventParams("editor_recorder", {
      click: "download",
    });
    //   首先判断用户是否需要使用ffmpeg进行处理
    // !是
    if (videoTrackInformation[0].startTime === 0 && videoTrackInformation[0].endTime === 0) {
      // 不需要进行剪辑
      FileSaver.saveAs(videoInformation.videoSrc, videoInformation.videoTitle + ".webm");
    } else {
      // !否
      // 进行时间的转换
      let splitArray = [];
      for (let index = 0; index < videoTrackInformation.length; index++) {
        let a =
          (videoTrackInformation[index].startTime / positioningRefSize.width) *
          videoInformation.videoDuration;
        let b =
          ((positioningRefSize.width - videoTrackInformation[index].endTime) /
            positioningRefSize.width) *
          videoInformation.videoDuration;
        splitArray.push(videoInformation.blobFileArray.slice(Math.round(a), Math.round(b)));
      }
      // 拼接数据
      for (let index = 0; index < splitArray.length; index++) {
        splitArray[0] = splitArray[0].concat(splitArray[0 + 1]);
        splitArray.splice(0 + 1, 1);
      }
      let superBuffer = new Blob(splitArray[0], {
        type: "video/webm",
      });
      FileSaver.saveAs(superBuffer, videoInformation.videoTitle + ".webm");
    }
  };
  // **重命名he下载弹出层  -- end
  // **--------------------------**
  // **视频右侧的编辑按钮
  const videoEditorRight = [
    {
      title: "Edit",
      isVip: false,

      icon: "/images/editor-recorder/split.svg",
    },
    // {
    //   title: "Cover",
    //   icon: "/images/editor-recorder/cover.svg",
    // },
    // {
    //   title: "Subtitle",
    //   icon: "/images/editor-recorder/subtitle.svg",
    // },
    // {
    //   title: "Watermark",
    //   icon: "/images/editor-recorder/watermark.svg",
    // },
    {
      title: "Transcript",
      isVip: true,
      icon: "/images/editor-recorder/translate.svg",
    },
  ];
  // 是否隐藏文字
  let [isShowEditorTitle, setIsShowEditorTitle] = useState(false);

  const handleWhichFunctionOpen = (title) => {
    setWhichFunctionOpen(title);
  };
  const handleWhichFunctionClose = () => {
    setWhichFunctionOpen("");
  };

  // **视频右侧的编辑按钮  -- end

  // **--------------------------**
  // **播放速率弹出层
  // 速率列表
  let playRateList = [3, 2, 1.5, 1.25, 1, 0.75, 0.5];
  // 设置视频的播放速度
  let [videoPlayRate, setVideoPlayRate] = useState(1);
  const videoPlayRateFunction = (number) => {
    videoRef.current.playbackRate = number;
    setVideoPlayRate(number);
    setIsOpenPlayRatePopover(false);
  };
  let [isOpenPlayRatePopover, setIsOpenPlayRatePopover] = useState(false);
  const openPlayRatePopover = () => {
    setIsOpenPlayRatePopover(true);
  };
  const closePlayRatePopover = () => {
    setIsOpenPlayRatePopover(false);
  };
  // **播放速率弹出层  -- end

  // **声音弹出层
  let [videoVolume, setVideoVolume] = useState(50);
  const videoVolumeFunction = (newValue) => {
    videoRef.current.volume = newValue / 100;
    setVideoVolume(newValue);
  };
  let [isOpenVideoVolume, setIsOpenVideoVolume] = useState(false);
  const openVideoVolume = () => {
    setIsOpenVideoVolume(true);
  };
  const closeVideoVolume = () => {
    setIsOpenVideoVolume(false);
  };
  // **声音速率弹出层 -end

  // **全屏按钮
  let fullscreenAreaRef = useRef(null);
  let [isFullScreen, setIsFullScreen] = useState(false);
  // 按钮退出全屏
  const fullscreenVideo = () => {
    // 进入全屏
    if (!isFullScreen) {
      setIsFullScreen(true);
      const elem = fullscreenAreaRef.current; // 获取要全屏显示的DOM元素
      if (elem.requestFullscreen) {
        elem.requestFullscreen(); // W3C标准
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen(); // Firefox
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen(); // Chrome和Safari
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen(); // IE/Edge
      }
    } else {
      // 退出全屏
      setIsFullScreen(false);
      if (document.exitFullscreen) {
        document.exitFullscreen(); // W3C标准
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen(); // Firefox
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen(); // Chrome和Safari
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen(); // IE/Edge
      }
    }
  };
  React.useEffect(() => {
    if (!isFullScreen) {
      document.addEventListener("fullscreenchange", exitHandler);
      document.addEventListener("webkitfullscreenchange", exitHandler);
      document.addEventListener("mozfullscreenchange", exitHandler);
      document.addEventListener("MSFullscreenChange", exitHandler);
    }
  }, [isFullScreen]);
  //  退出全屏的回调函数
  function exitHandler() {
    if (
      !document.fullscreenElement &&
      !document.webkitFullscreenElement &&
      !document.mozFullScreenElement &&
      !document.msFullscreenElement
    ) {
      // 执行退出全屏后的回调函数
      setIsFullScreen(false);
    }
  }
  // 设置视频的播放进度
  const changeVideoCurrentTime = (value) => {
    videoRef.current.currentTime = (videoInformation.videoDuration * value) / 100;
    setVideoInformation({
      ...videoInformation,
      videoCurrentTime: (videoInformation.videoDuration * value) / 100,
    });
  };
  //   播放按钮的设置
  const videoPlayButton = () => {
    if (videoInformation.isPlay) {
      // 清除掉监听事件
      videoRef.current.ontimeupdate = null;
      videoRef.current.onended = null;
      //暂停
      videoInformation.isPlay = false;
      videoRef.current.pause();
      videoInformation.playVideoButton = "/images/editor-recorder/play.svg";
    } else {
      // 关闭顶部的遮罩层
      setIsShowTopMask(false);
      // 检查时间的合法性
      videoTrackInformation.sort((a, b) => a.startTime - b.startTime);
      // 计算法所有的合法时间
      let legalTime = [];
      if (positioningRefSize.width !== 0) {
        videoTrackInformation.forEach((item, index) => {
          legalTime.push({
            startTime: (item.startTime / positioningRefSize.width) * videoInformation.videoDuration,
            endTime:
              ((positioningRefSize.width - item.endTime) / positioningRefSize.width) *
              videoInformation.videoDuration,
          });
        });
      }

      videoRef.current.ontimeupdate = () => {
        if (positioningRefSize.width !== 0) {
          // 判断当前面的时间是否合法
          // 如果合法则不进行操作
          // !不合法则寻找下一个合法的时间
          const isExist = legalTime.some(
            (obj) =>
              obj.startTime <= videoRef.current.currentTime &&
              obj.endTime >= videoRef.current.currentTime,
          );
          if (!isExist) {
            const legalTimeIndex = legalTime.findIndex(
              (obj) => obj.startTime > videoRef.current.currentTime,
            );
            if (legalTimeIndex !== -1) {
              videoRef.current.currentTime = legalTime[legalTimeIndex].startTime;
            } else {
              videoRef.current.currentTime = 0;
              videoRef.current.pause();
            }
          }
        }

        // if(videoRef.current.currentTime)
        videoInformation.videoCurrentTime = videoRef.current.currentTime;
        setVideoInformation({
          ...videoInformation,
        });
      };

      videoRef.current.onpause = () => {
        // videoInformation.videoCurrentTime = 0;
        videoInformation.isPlay = false;
        // videoRef.current.currentTime = 0;
        videoInformation.playVideoButton = "/images/editor-recorder/play.svg";
        setVideoInformation({
          ...videoInformation,
        });
      };
      // 开始播放
      videoInformation.isPlay = true;
      videoRef.current.play();
      videoInformation.playVideoButton = "/images/editor-recorder/pause.svg";
    }
    setVideoInformation({
      ...videoInformation,
    });
  };
  // **------------------------**
  // 是否隐藏 顶部的这遮罩层
  let [isShowTopMask, setIsShowTopMask] = useState(true);

  // **------------------------**
  // !定位视频的位置
  // 监听视频宽度的变化
  const [positioningRef, positioningRefSize] = useMeasure();
  let [positioningCover, setPositioningCover] = useState({
    videoWidth: 1,
    videoHeight: 1,
  });
  React.useEffect(() => {
    // 默认以高度为基准
    let videoHeight = positioningRefSize.height;
    let videoProportion = videoInformation.videoWidth / videoInformation.videoHeight;
    let videoWidth = videoHeight * videoProportion;
    if (videoWidth > positioningRefSize.width) {
      // 以宽度为基准
      videoWidth = positioningRefSize.width;
      videoHeight = videoWidth / videoProportion;
    }
    setPositioningCover({
      videoWidth,
      videoHeight,
    });
  }, [positioningRefSize, videoInformation]);
  // **------------------**
  //  !水印区域
  let [watermarkInformation, setWatermarkInformation] = useState({
    isNeed: false,
    text: "Please Input",
    opacity: 50,
    position: 1, //代表位置属于右上方
  });

  // **------------------**
  // ?~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // **------------------**
  //  !字幕区域
  let [subtitleInformation, setSubtitleInformation] = useState({
    isNeed: false,
    subtitleList: [
      {
        startTime: "00:00",
        text: "cs",
      },
    ],
  });

  // **------------------**

  // #~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

  // 确定之后的 红色位置
  let [pointerTruePosition, setPointerTruePosition] = React.useState({
    isShow: false,
    left: 0,
    computeLeft: 0,
  });
  // **------------------**
  // 视频轨道的编辑区域
  // 初始化轨道数据设置
  let [videoTrackInformation, setVideoTrackInformation] = useState(tempSaveVideoTrackInformation);
  React.useEffect(() => {
    setVideoTrackInformation([...tempSaveVideoTrackInformation]);
  }, [tempSaveVideoTrackInformation]);
  // ?底部的按钮是否可用
  let [editButtonEnable, setEditButtonEnable] = useState({
    split: true,
    delete: true,
    redo: true,
    undo: true,
  });
  let [whichTrackIsSelect, setWhichTrackIsSelect] = useState({});
  // !分割按钮，将选中的进行分割
  const splitTrackFun = () => {
    undoStack.isUndoStack = false;
    setUndoStack(JSON.parse(JSON.stringify(undoStack)));
    // 修改原本索引的数组，并新增
    // 修改
    let selectTrackCopy = JSON.parse(
      JSON.stringify(videoTrackInformation[whichTrackIsSelect.index]),
    );
    videoTrackInformation[whichTrackIsSelect.index] = {
      startTime: selectTrackCopy.startTime,
      endTime: positioningRefSize.width - whichTrackIsSelect.offsetX,
      minStartTime: selectTrackCopy.startTime,
      maxEndTime: positioningRefSize.width - whichTrackIsSelect.offsetX,
      startTimeProportion: selectTrackCopy.startTime / positioningRefSize.width,
      endTimeProportion:
        (positioningRefSize.width - whichTrackIsSelect.offsetX) / positioningRefSize.width,
    };
    // 新增
    videoTrackInformation.push({
      startTime: whichTrackIsSelect.selectPosition + selectTrackCopy.startTime + 15,
      endTime: selectTrackCopy.endTime,
      minStartTime: whichTrackIsSelect.selectPosition + selectTrackCopy.startTime + 15,
      maxEndTime: selectTrackCopy.endTime,
      startTimeProportion:
        (whichTrackIsSelect.selectPosition + selectTrackCopy.startTime + 15) /
        positioningRefSize.width,
      endTimeProportion: selectTrackCopy.endTime / positioningRefSize.width,
    });
    setVideoTrackInformation([...videoTrackInformation]);
  };
  // !删除按钮
  const deleteTrackFun = () => {
    let tempArray = JSON.parse(JSON.stringify(videoTrackInformation));
    tempArray.splice(whichTrackIsSelect.index, 1);
    setVideoTrackInformation([...tempArray]);
  };
  // redo undo 区域
  let [undoStack, setUndoStack] = useState({
    isUndoStack: false,
    information: [],
  });
  // 接受undo弹出的
  let [tempUndoRedo, setTempUndoRedo] = useState([]);
  let [redoStack, setRedoStack] = useState({
    isUndoStack: false,
    information: [],
  });

  // 监听全局videoTrackInformation的变化
  function arraysEqual(arr1, arr2) {
    return JSON.stringify(arr1) === JSON.stringify(arr2);
  }
  useEffect(() => {
    if (!undoStack.isUndoStack) {
      // 每次更新需要确定是否与上一个数据相同
      if (
        !arraysEqual(undoStack.information[undoStack.information.length - 1], videoTrackInformation)
      ) {
        undoStack.information.push(JSON.parse(JSON.stringify(videoTrackInformation)));
        setUndoStack(JSON.parse(JSON.stringify(undoStack)));
      }
    }
  }, [videoTrackInformation]);
  const undoTrackFun = () => {
    undoStack.isUndoStack = true;
    // 取出undo中的最后一个数组
    let lastArray = undoStack.information.pop();
    setTempUndoRedo([...lastArray]);
    setVideoTrackInformation([...undoStack.information[undoStack.information.length - 1]]);
    setUndoStack(JSON.parse(JSON.stringify(undoStack)));
  };
  useEffect(() => {
    let copyTempUndoRedo = JSON.parse(JSON.stringify(tempUndoRedo));
    redoStack.information.push(copyTempUndoRedo);
    setRedoStack(JSON.parse(JSON.stringify(redoStack)));
  }, [tempUndoRedo]);
  // redo
  const redoTrackFun = () => {
    // 取出redo中的最后一个数组
    let lastArray = JSON.parse(JSON.stringify(redoStack.information.pop()));
    setVideoTrackInformation(lastArray);
    // 需要将吐出来的数据在放回
    undoStack.information.push(lastArray);
    setUndoStack(JSON.parse(JSON.stringify(undoStack)));
    setRedoStack(JSON.parse(JSON.stringify(redoStack)));
  };
  const originTrackFun = (type) => {
    if (type === "save" || type === "origin") {
      tempUndoRedo = [];
      redoStack = {
        isUndoStack: false,
        information: [[]],
      };
      undoStack = {
        isUndoStack: false,
        information: [],
      };
    }
    if (type === "origin") {
      setVideoTrackInformation([
        {
          startTime: 0,
          endTime: 0,
          minStartTime: 0,
          maxEndTime: 0,
        },
      ]);
    } else {
      setVideoTrackInformation(tempSaveVideoTrackInformation);
    }

    setUndoStack({
      ...undoStack,
    });
    setRedoStack({
      ...redoStack,
    });
  };

  // **------------------**

  // #快捷键区域
  useEffect(() => {
    // 只有在没有弹出层的时候才生效
    if (renameDownloadAnchor === null) {
      document.onkeydown = (event) => {
        // !功能播放或者暂停
        if (event.code === "Space") {
          // videoPlayButton();
        }
      };
    }
  }, [renameDownloadAnchor]);
  // # summary 区域
  let [summaryStatePart, setSummaryStatePart] = useState({
    isLoading: false,
    isGPTSupport: true,
    isNetworkError: false,
    message: "",
  });
  function failedCallback() { }
  const getSummary = async () => {
    // 开始loading
    summaryStatePart.isLoading = true;
    setSummaryStatePart({
      ...summaryStatePart,
    });
    let isCanUseGPT = await getSummarySysParams({
      userAudioLanguage: summaryState.userAudioLanguage,
      failedCallback: setSummaryStatePart,
      summaryStatePart,
    });
    let willSummaryContent = "";
    // 将 dataBank.transcript 数组片接为一个字符串
    for (let i = 0; i < dataBank.transcript.length; i++) {
      let element = dataBank.transcript[i];
      willSummaryContent += element.text;
    }
    if (isCanUseGPT) {
      let getTxtSummaryResult = await getTxtSummary({
        content: willSummaryContent,
        failedCallback: setSummaryStatePart,
        summaryStatePart,
        language: summaryState.userAudioLanguage,
      });
      console.log(getTxtSummaryResult);
      // 判断 getTxtSummaryResult 是否是字符串
      if (typeof getTxtSummaryResult === "string") {
        dataBank.summary = getTxtSummaryResult;
      } else {
        let summaryResult = "";
        for (let i = 0; i < getTxtSummaryResult.length; i++) {
          let element = getTxtSummaryResult[i].content;
          summaryResult += element;
          dataBank.summary = summaryResult;
        }
      }
      // 根据 uuid存储到数据库中
      setDataBank({
        ...dataBank,
      });
      // 获取完毕 --> 存储到数据库
      await localforage.setItem(dataBank.uuid, dataBank);
      summaryStatePart.isLoading = false;
      setSummaryStatePart({
        ...summaryStatePart,
      });
    }
  };
  return (
    <div>
      {/* 左侧  */}
      <Box
        sx={{
          width: whichFunctionOpen === "Transcript" ? "calc(100vw - 362px)" : "calc(100vw)",
        }}
        className={EditorRecorderLeftStyle.bottom_left}
      >
        {/* title  */}
        <Box className={EditorRecorderLeftStyle.bottom_left_title}>
          <Box>{videoInformation.videoTitle}</Box>
          <Button onClick={openRenameDownload}>
            <img width="24px" src="/images/editor-recorder/more.svg" alt="more" />
          </Button>
          <Popover
            id={renameDownloadPopoverID}
            open={isOpenRenameDownload}
            anchorEl={renameDownloadAnchor}
            onClose={closeRenameDownload}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                "& button": {
                  margin: "10px 0px",
                  textTransform: "none",
                  padding: "10px 15px",
                },
              }}
            >
              <Button variant="text" onClick={handleClickOpenRename}>
                <Typography variant="buttonMiddle">Rename</Typography>
              </Button>
              {/* 重命名的弹出层  */}
              <Dialog onClose={handleCloseRename} open={isOpenRename}>
                {/* <DialogTitle>Set backup account</DialogTitle> */}
                <Box
                  sx={{
                    width: "350px",
                    height: "200px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* 重命名的文本输入框 */}
                  <TextField
                    value={renameTextValue}
                    onChange={(event) => {
                      renameTextValue = event.target.value;
                      setRenameTextValue(renameTextValue);
                    }}
                    sx={{
                      width: "240px",
                      "& input": {
                        fontSize: "16px",
                      },
                    }}
                    label="Rename"
                    variant="outlined"
                  />
                  <Box
                    sx={{
                      marginTop: "20px",
                      width: "240px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button onClick={handleCloseRename} variant="outlined">
                      Cancel
                    </Button>
                    <Button
                      disabled={renameTextValue === "" ? true : false}
                      onClick={async () => {
                        dataBank.videoTitle = renameTextValue;
                        // 根据uuid查找数据修改数据的
                        let dataDictionary = await localforage.getItem("dataDictionary");
                        let uuidDataDictionary = dataDictionary.findIndex(
                          (item) => item.uuid === dataBank.uuid,
                        );
                        dataDictionary[uuidDataDictionary].videoTitle = renameTextValue;
                        localforage.setItem("dataDictionary", dataDictionary);
                        // 存储数据
                        localforage.setItem(dataBank.uuid, dataBank);
                        setDataBank({ ...dataBank });
                        // 获取文本输入框的值保存
                        setVideoInformation({
                          ...videoInformation,
                          videoTitle: renameTextValue,
                        });
                        // 关闭弹出层
                        setIsOpenRename(false);
                      }}
                      variant="contained"
                    >
                      Rename
                    </Button>
                  </Box>
                </Box>
              </Dialog>
              <Button variant="text" onClick={downloadVideo}>
                <Typography variant="buttonMiddle">Download</Typography>
              </Button>
            </Box>
          </Popover>
        </Box>
        {/* 视频播放区域  */}
        <Box
          onMouseMove={(event) => {
            setIsShowTopMask(true);

            clearTimeout(isShowTopMaskTimeoutID);
            isShowTopMaskTimeoutID = setTimeout(() => {
              setIsShowTopMask(false);
            }, 3000);
          }}
          onMouseLeave={() => {
            if (videoInformation.isPlay) {
              setIsShowTopMask(false);
            }
          }}
          ref={fullscreenAreaRef}
          className={EditorRecorderLeftStyle.bottom_left_wrap}
        >
          {/* {videoInformation.videoWidth === 0 && (
            <Box
              sx={{
                backgroundColor: "rgba(255,255,255,1)",
                position: "absolute",
                top: "0px",
                zIndex: "99",
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton />
              <Skeleton animation="wave" />
            </Box>
          )} */}

          <video ref={videoRef} src={videoInformation.videoSrc}></video>
          {/* 顶层遮罩 99  */}
          {isShowTopMask && (
            <Box className={EditorRecorderLeftStyle.bottom_left_video_cover}>
              {/* 中间按钮的播放  */}
              <Button
                variant="text"
                onClick={() => {
                  videoPlayButton();
                }}
              >
                <img src={videoInformation.playVideoButton} alt="playOrPause" />
              </Button>
              {/* 右侧的编辑按钮  */}
              <Box
                onMouseEnter={() => {
                  // 清除全局的关闭事件
                  clearTimeout(isShowTopMaskTimeoutID);
                  setIsShowEditorTitle(true);
                }}
                onMouseMove={() => {
                  clearTimeout(isShowTopMaskTimeoutID);
                }}
                onMouseLeave={() => {
                  setIsShowEditorTitle(false);
                }}
                className={EditorRecorderLeftStyle.bottom_left_video_cover_right}
              >
                {videoEditorRight.map((item, index) => {
                  return (
                    <Button
                      disabled={
                        process.env.REACT_APP_SWITCH_SUBSCRIBE === "true"
                          ? userSubState?.vip
                            ? false
                            : true
                          : false
                      }
                      onClick={() => {
                        // 设置打开哪一个弹出层
                        handleWhichFunctionOpen(item.title);
                        // // 暂停视频
                        videoRef.current.pause();
                        clearTimeout(isShowTopMaskTimeoutID);
                      }}
                      variant="text"
                      sx={{
                        width: "auto !important",
                        background: "none !important",
                        justifyContent: "space-between",
                      }}
                      key={index}
                    >
                      {isShowEditorTitle && (
                        <Typography component="span" variant="whiteSmall">
                          {item.title}
                          {item.isVip && (
                            <span
                              style={{
                                marginLeft: "20px",
                                background: "#253fe7",
                                padding: "3px 8px",
                                borderRadius: "4px",
                              }}
                            >
                              vip
                            </span>
                          )}
                        </Typography>
                      )}
                      <img src={item.icon} alt={item.icon} />
                    </Button>
                  );
                })}
                {/* 弹出层区域  */}
                {whichFunctionOpen !== "Edit" && whichFunctionOpen !== "Transcript" && (
                  <Dialog
                    onMouseMove={(event) => {
                      event.stopPropagation();
                    }}
                    sx={{
                      "& div": {
                        margin: "0px",
                      },
                    }}
                    maxWidth={false}
                    onClose={handleWhichFunctionClose}
                    open={Boolean(whichFunctionOpen)}
                  >
                    <Box
                      sx={{
                        width: pageSize.width <= 1095 ? pageSize.width - 60 : "1046px",
                        height: "550px",
                        display: "flex",
                        overflow: "hidden",
                      }}
                    >
                      <VideoPlay
                        pageSize={pageSize}
                        videoInformation={videoInformation}
                        playRateList={playRateList}
                        watermarkInformation={watermarkInformation}
                      />
                      {whichFunctionOpen === "Subtitle" && (
                        <Subtitle
                          pageSize={pageSize}
                          subtitleInformation={subtitleInformation}
                          setSubtitleInformation={setSubtitleInformation}
                        />
                      )}
                      {whichFunctionOpen === "Watermark" && (
                        <Watermark
                          watermarkInformation={watermarkInformation}
                          setWatermarkInformation={setWatermarkInformation}
                          pageSize={pageSize}
                        />
                      )}
                    </Box>
                  </Dialog>
                )}
              </Box>
            </Box>
          )}
          {/* 定位video标签的位置  */}
          <Box
            ref={positioningRef}
            sx={{
              position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: "9",
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: positioningCover.videoWidth,
                height: positioningCover.videoHeight,
              }}
            >
              {/* 放 水印  */}
              {watermarkInformation.isNeed && (
                <Box
                  sx={{
                    opacity: watermarkInformation.opacity / 100,
                    fontSize: (positioningCover.videoWidth / 888) * 16 + "px",
                    position: "absolute",
                    color: "rgb(222, 224, 227)",
                    top:
                      watermarkInformation.position === 1 || watermarkInformation.position === 0
                        ? 30
                        : null,
                    bottom:
                      watermarkInformation.position === 2 || watermarkInformation.position === 3
                        ? 30
                        : null,
                    left:
                      watermarkInformation.position === 2 || watermarkInformation.position === 0
                        ? 30
                        : null,
                    right:
                      watermarkInformation.position === 1 || watermarkInformation.position === 3
                        ? 30
                        : null,
                  }}
                >
                  {watermarkInformation.text}
                </Box>
              )}
            </Box>
          </Box>
          {/* 视频控件下方  */}
          {isShowTopMask && (
            <Box className={EditorRecorderLeftStyle.bottom_left_wrap_control}>
              {/* 进度条  */}
              <Slider
                color="secondary"
                sx={{
                  padding: "3px 0px",
                }}
                className={EditorRecorderLeftStyle.bottom_left_wrap_progress}
                size="small"
                value={
                  isNaN((videoInformation.videoCurrentTime / videoInformation.videoDuration) * 100)
                    ? 0
                    : (videoInformation.videoCurrentTime / videoInformation.videoDuration) * 100
                }
                onChange={(event, value) => {
                  changeVideoCurrentTime(value);
                }}
              />
              {/* 播放区域  */}
              <Box className={EditorRecorderLeftStyle.bottom_left_video_bottom}>
                {/* 播放区域的左侧  */}
                <Box>
                  <Button
                    variant="text"
                    onClick={() => {
                      videoPlayButton();
                    }}
                  >
                    <img src={videoInformation.playVideoButton} alt="playOrPause" />
                  </Button>
                  <Typography
                    className={EditorRecorderLeftStyle.bottom_left_video_time}
                    component="span"
                    variant="whiteSmall"
                  >
                    {secToTime(videoInformation.videoCurrentTime)}/
                    {secToTime(videoInformation.videoDuration)}
                  </Typography>
                </Box>
                {/* 播放区域的右侧  */}
                <Box className={EditorRecorderLeftStyle.bottom_left_video_bottom_right}>
                  {/* 倍速  */}
                  <Button
                    sx={{
                      position: "relative",
                    }}
                    onMouseLeave={closePlayRatePopover}
                    onMouseEnter={openPlayRatePopover}
                  >
                    <Box
                      sx={{
                        // background: "red",
                        width: "64px",
                        height: "64px",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    ></Box>
                    {videoPlayRate}x
                  </Button>
                  {isOpenPlayRatePopover && (
                    <Box
                      onMouseEnter={openPlayRatePopover}
                      onMouseLeave={closePlayRatePopover}
                      className={EditorRecorderLeftStyle.bottom_left_video_bottom_right_play_rate}
                    >
                      {/* 倍速循环  */}
                      {playRateList.map((item, index) => {
                        return (
                          <Button
                            onClick={() => {
                              videoPlayRateFunction(item);
                            }}
                            key={index}
                            sx={{
                              padding: "2px 0px",
                              color: "#ffffff",
                              fontWeight: 700,
                              fontSize: "16px",
                            }}
                          >
                            {item}
                          </Button>
                        );
                      })}
                    </Box>
                  )}

                  {/* 音量 */}
                  <Button
                    sx={{
                      position: "relative",
                    }}
                    onMouseEnter={openVideoVolume}
                    onMouseLeave={closeVideoVolume}
                  >
                    <Box
                      sx={{
                        width: "64px",
                        height: "64px",
                        position: "absolute",
                        bottom: "0px",
                      }}
                    ></Box>
                    <img src="/images/editor-recorder/volume.svg" alt="volume" />
                  </Button>
                  {isOpenVideoVolume && (
                    <Box
                      onMouseEnter={openVideoVolume}
                      onMouseLeave={closeVideoVolume}
                      className={
                        EditorRecorderLeftStyle.bottom_left_video_bottom_right_video_volume
                      }
                    >
                      <Slider
                        size="small"
                        onChange={(event, newValue) => {
                          videoVolumeFunction(newValue);
                        }}
                        sx={{
                          color: "#ffffff",
                          '& input[type="range"]': {
                            WebkitAppearance: "slider-vertical",
                          },
                        }}
                        orientation="vertical"
                        value={videoVolume}
                        aria-label="Temperature"
                      />
                    </Box>
                  )}

                  {/* 全屏按钮  */}
                  <Button onClick={fullscreenVideo}>
                    {isFullScreen ? (
                      <img
                        src="/images/editor-recorder/end-full-screen.svg"
                        alt="end-full-screen"
                      />
                    ) : (
                      <img src="/images/editor-recorder/full-screen.svg" alt="full-screen" />
                    )}
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
        </Box>
        {/* 底部编辑区域  */}
        <Box
          sx={{
            marginTop: "25px",
          }}
        >
          {/* 编辑功能  */}
          {whichFunctionOpen === "Edit" && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              {/* 轨道区域  */}
              <Box
                sx={{
                  position: "relative",
                }}
              >
                {/* 底层浅色  */}
                <Box
                  sx={{
                    height: "80px",
                    opacity: "0.2",
                    background: "#4C7AF9",
                    borderRadius: "10px",
                  }}
                ></Box>
                {/* 播放的时间 -- 真*/}
                {pointerTruePosition.isShow && (
                  <Box
                    className="test"
                    sx={{
                      position: "absolute",
                      left: pointerTruePosition.computeLeft,
                      top: "0px",
                      height: "100%",
                      width: "2px",
                      background: "#FF0909",
                    }}
                  ></Box>
                )}
                {videoTrackInformation.map((item, index) => {
                  return (
                    <VideoTrack
                      videoInformation={videoInformation}
                      setVideoInformation={setVideoInformation}
                      pageSize={pageSize}
                      pointerTruePosition={pointerTruePosition}
                      setPointerTruePosition={setPointerTruePosition}
                      key={index}
                      videoTrackIndex={index}
                      signTrackInformation={item}
                      videoTrackInformation={videoTrackInformation}
                      setVideoTrackInformation={setVideoTrackInformation}
                      videoRef={videoRef}
                      videoDuration={videoInformation.videoDuration}
                      positioningRefSize={positioningRefSize}
                      setEditButtonEnable={setEditButtonEnable}
                      editButtonEnable={editButtonEnable}
                      whichTrackIsSelect={whichTrackIsSelect}
                      setWhichTrackIsSelect={setWhichTrackIsSelect}
                      undoStack={undoStack}
                      setUndoStack={setUndoStack}
                    />
                  );
                })}
              </Box>
              {/* 底部按钮区域  */}
              <Box
                sx={{
                  margin: "20px 0 0 0",
                  display: "flex",
                  justifyContent: "space-between",
                  flexWrap: "wrap",
                }}
              >
                <Box
                  sx={{
                    marginTop: "20px",
                    "& button": {
                      margin: "0px 10px",
                    },
                  }}
                >
                  {/* undo  */}
                  <Button
                    onClick={() => {
                      undoTrackFun();
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "undo",
                      });
                    }}
                    disabled={undoStack.information.length === 1}
                    sx={{
                      marginLeft: "0px !important",
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "0px",
                      opacity: undoStack.information.length === 1 ? "0.4" : "1",
                    }}
                  >
                    <img
                      width={"100%"}
                      src="/images/editor-recorder/track-undo.svg"
                      alt="track-split"
                    />
                  </Button>
                  {/* redo  */}
                  <Button
                    onClick={() => {
                      redoTrackFun();
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "redo",
                      });
                    }}
                    disabled={redoStack.information.length === 1}
                    sx={{
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "0px",
                      opacity: redoStack.information.length === 1 ? "0.4" : "1",
                    }}
                  >
                    <img
                      width={"100%"}
                      src="/images/editor-recorder/track-redo.svg"
                      alt="track-split"
                    />
                  </Button>
                  {/* 删除按钮  */}
                  <Button
                    onClick={() => {
                      deleteTrackFun();
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "delete_track",
                      });
                    }}
                    disabled={videoTrackInformation.length === 1}
                    sx={{
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "0px",
                      opacity: videoTrackInformation.length === 1 ? "0.4" : "1",
                    }}
                  >
                    <img
                      width={"100%"}
                      src="/images/editor-recorder/track-delete.svg"
                      alt="track-split"
                    />
                  </Button>
                  {/* 恢复到最初的样子  */}
                  <Button
                    onClick={() => {
                      originTrackFun("origin");
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "reset",
                      });
                    }}
                    sx={{
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "0px",
                    }}
                  >
                    <img
                      width={"100%"}
                      src="/images/editor-recorder/track-origin.svg"
                      alt="track-split"
                    />
                  </Button>
                  {/* 分割按钮  */}
                  <Button
                    onClick={() => {
                      splitTrackFun();
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "split_track",
                      });
                    }}
                    disabled={editButtonEnable.split}
                    sx={{
                      minWidth: "40px",
                      minHeight: "40px",
                      padding: "0px",
                      opacity: editButtonEnable.split ? "0.4" : "1",
                    }}
                  >
                    <img
                      width={"100%"}
                      src="/images/editor-recorder/track-split.svg"
                      alt="track-split"
                    />
                  </Button>
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                  }}
                >
                  <Button
                    onClick={() => {
                      originTrackFun("cancel");
                      setWhichFunctionOpen("");
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "cancel",
                      });
                    }}
                    sx={{
                      width: "86px",
                      minHeight: "40px",
                      padding: "7px 24px",
                      boxSizing: "border-box",
                      color: "#3D3D3D",
                      marginRight: "25px",
                      border: " 1px solid #E5E5E5",
                      boxShadow:
                        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    onClick={() => {
                      tempSaveVideoTrackInformation = JSON.parse(
                        JSON.stringify(videoTrackInformation),
                      );
                      // 根据uuii修改数据
                      dataBank.editor = tempSaveVideoTrackInformation;
                      // 数据库存储
                      localforage.setItem(dataBank.uuid, dataBank);
                      setDataBank({ ...dataBank });
                      setTempSaveVideoTrackInformation([...tempSaveVideoTrackInformation]);
                      originTrackFun("save");
                      setWhichFunctionOpen("");
                      sendConstructEventByEventParams("editor_recorder", {
                        click: "save",
                      });
                    }}
                    variant="contained"
                    sx={{
                      width: "148px",
                      minHeight: "40px",
                      padding: "7px 24px",
                      boxSizing: "border-box",
                      color: "#ffffff",
                    }}
                  >
                    Save Changes
                  </Button>
                </Box>
              </Box>
            </Box>
          )}

          {whichFunctionOpen === "Transcript" &&
            dataBank.transcript.length !== 0 &&
            !isGet &&
            !summaryStatePart.isLoading &&
            !Boolean(dataBank.summary) && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  onClick={() => {
                    getSummary();
                    sendConstructEventByEventParams("editor_recorder", {
                      click: "summary",
                    });
                  }}
                  sx={{
                    marginTop: "20px",
                  }}
                  variant="contained"
                >
                  <img
                    style={{
                      marginRight: "8px",
                    }}
                    width={"24px"}
                    src="/images/editor-recorder/summary.svg"
                    alt=""
                  />
                  summary
                </Button>
              </Box>
            )}
          {/* 显示summary  */}
          {whichFunctionOpen === "Transcript" &&
            (summaryStatePart.isLoading || dataBank.summary !== "") && (
              <Box
                sx={{
                  maxHeight: "200px",
                  width: "100%",
                  border: Boolean(dataBank.summary) ? "1px solid #E5E5E5" : "none",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  overflowY: "scroll",
                  "&::-webkit-scrollbar-track": {
                    backgroundColor: "#FAFAFA",
                    width: "1px",
                  },
                  "&::-webkit-scrollbar": {
                    width: "1px",
                    backgroundColor: "#FAFAFA",
                  },
                }}
              >
                {/* 国家不支持  */}
                {summaryStatePart.isLoading && (
                  <Box
                    sx={{
                      width: "100%",
                    }}
                  >
                    <LinearProgress />
                  </Box>
                )}
                {
                  // 错误信息处理
                  summaryStatePart.message !== "" && (
                    <Box
                      sx={{
                        fontSize: "18px",
                        fontWeight: "500",
                        textAlign: "center",
                      }}
                    >
                      {summaryStatePart.message}
                    </Box>
                  )
                }
                {/* 显示结果 */}
                {Boolean(dataBank.summary) && (
                  <Box
                    sx={{
                      width: "90%",
                      fontSize: "16px",
                      wordBreak: "break-all",
                      padding: "10px",
                      height: "100%",
                    }}
                  >
                    <Box className={EditorRecorderLeftStyle.summaryBefore}>
                      <Box
                        sx={{
                          paddingLeft: "12px",
                        }}
                      >
                        {dataBank.summary}
                      </Box>
                    </Box>
                  </Box>
                )}
              </Box>
            )}
        </Box>
      </Box>
    </div>
  );
}
