import { summary } from "./url";
import api, { response } from ".";
import md5 from "md5";
import axios from "axios";
function getElementText(response, elementName) {
  return response.getElementsByTagName(elementName)[0].innerHTML;
}
export async function getSummarySysParams() {
  try {
    // # 新
    let fetchResult = await fetch("https://api.hostip.info");
    let xmlResult = await fetchResult.text();
    let xmlDOC = await new window.DOMParser().parseFromString(xmlResult, "text/xml");
    let country = getElementText(xmlDOC, "countryAbbrev");
    console.log(getElementText(xmlDOC, "countryAbbrev"));
    // 获取时间戳
    const timeStamp = new Date().getTime();
    const str = `${process.env.REACT_APP_SECRET_KEY}timeStamp=${timeStamp}`;
    const signature = md5(str);
    const res = await api({
      url: summary.getSysParams + "/" + country + "/" + timeStamp,
      method: "GET",
      headers: {
        signature,
      },
    }).catch((e) => e);
    if (res.status === 200) {
      // 该国家支持chatGpt
      if (res.data.dataResult.isGPTSupport === 0) {
        window.toast("Start to summary 🚀")
        return true;
      } else {
        // 标记国家不支持
        window.toast.warn("Your country does not support. 🙅‍♂️")

        return false;
      }
    } else {
      //  标记网络错误
      window.toast.warn("Network error. 🙅‍♂️")
      return false;
    }
  } catch (e) {
    // 标记 错误
    window.toast.warn("Network error. 🙅‍♂️")

    return false;
  }
}

export async function getTxtSummary({ content, language }) {
  try {
    // 获取时间戳
    const timeStamp = new Date().getTime();
    const str = `${process.env.REACT_APP_SECRET_KEY}timeStamp=${timeStamp}`;
    const signature = md5(str);
    const res = await api({
      url: summary.txtSummary,
      method: "POST",
      data: {
        timeStamp: timeStamp,
        content,
        language,
      },
      headers: {
        signature,
      },
    }).catch((e) => e);
    console.log(res, "res");
    console.log(content);
    if (res.status === 201) {
      return res.data.dataResult.summary;
    } else {
      // 标记网络错误
      window.toast.warn("Network error. 🙅‍♂️")
    }
  } catch (e) {
    window.toast.warn("Network error. 🙅‍♂️")
  }
}
