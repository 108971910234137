const fullEndScreen = (isFullScreen, fullscreenAreaRef) => {
  // 进入全屏
  if (!isFullScreen) {
    // 开始 esc 键监听

    const elem = fullscreenAreaRef.current; // 获取要全屏显示的DOM元素
    if (elem.requestFullscreen) {
      elem.requestFullscreen(); // W3C标准
    } else if (elem.mozRequestFullScreen) {
      elem.mozRequestFullScreen(); // Firefox
    } else if (elem.webkitRequestFullscreen) {
      elem.webkitRequestFullscreen(); // Chrome和Safari
    } else if (elem.msRequestFullscreen) {
      elem.msRequestFullscreen(); // IE/Edge
    }
  } else {
    // 退出全屏
    if (document.exitFullscreen) {
      document.exitFullscreen(); // W3C标准
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen(); // Firefox
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen(); // Chrome和Safari
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen(); // IE/Edge
    }
  }
};

export default fullEndScreen;
