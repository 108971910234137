
//服务器API请求baseUrl

 let serverBaseUrl = process.env.REACT_APP_SERVER_BASEURL;


///订阅相关
//同步服务器可用的订阅请求地址
const syncSubPlanUrl = serverBaseUrl + "/sub/getPlan";
//同步服务器用户订阅状态
const syncUserSubStateUrl = serverBaseUrl + "/sub/subState";
//验证订阅的有效性
const verifyUserSubIdUrl = serverBaseUrl + "/sub/verifySub";
//暂停订阅
const suspendUserSubUrl = serverBaseUrl + "/sub/suspend/";
//恢复订阅
const renewUserSubUrl = serverBaseUrl + "/sub/renew/";

//用户登录相关
//登录
const loginUserUrl = serverBaseUrl + "/user/login";
//忘记密码（让服务器发送邮件）
const forgetUserPasswordUrl=serverBaseUrl+"/user/resetPassSubmit"
//修改密码
const saveResetUserPasswordUrl = serverBaseUrl + "/user/updatePassword";
//保存重置密码
const saveResetUrl = serverBaseUrl + "/user/resetPassSave"
//注册账号
const registerUserUrl = serverBaseUrl + "/user/register";
//校验登录状态
const verifyLoginTokenUrl = serverBaseUrl + "/user/profile";

//转化相关
//创建文件转化任务
const createFileConvertJobUrl = serverBaseUrl + "/convert/submit/pdf2office";
//查询转化任务转台
const checkConvertJobStateUrl = serverBaseUrl + "/convert/state/";
//获取转化任务的上传url、key
const getConvertKeyUrl=serverBaseUrl+"/convert/uploadurl/";
const CompressPDFConvertUrl = serverBaseUrl + "/convert/submit/pdfCompress";
//创建office2转pdf文件转换任务
const createOfficeConversionUrl = serverBaseUrl+"/convert/submit/office2pdf";

// 分享
// 获取文件的上传地址
const getShareFileUrl = serverBaseUrl + "/share/uploadurl"
const createShareFileUrl = serverBaseUrl + "/share/createLink"
const getFileFromShareUrl = serverBaseUrl + "/share/view"



export {
    saveResetUrl,
    syncSubPlanUrl,
    syncUserSubStateUrl,
    verifyUserSubIdUrl,
    suspendUserSubUrl,
    renewUserSubUrl,
    loginUserUrl,
    forgetUserPasswordUrl,
    saveResetUserPasswordUrl,
    registerUserUrl,
    verifyLoginTokenUrl,
    createFileConvertJobUrl,
    checkConvertJobStateUrl,
    getConvertKeyUrl,
    CompressPDFConvertUrl,
    createOfficeConversionUrl,
    getShareFileUrl,
    createShareFileUrl,
    getFileFromShareUrl
}