/* eslint-disable no-useless-escape */
// 格式验证
//验证邮箱格式是否合法
function verifyEmailFormat(emailAddress) {
  // 邮箱不能为空
  if (emailAddress == null || !emailAddress) {
    return false;
  }
  // 只允许英文字母、数字、下划线、英文句号、以及中划线组成
  // 举例：zhangsan-001@gmail.com
  const reg =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return reg.test(emailAddress);
}
//验证密码格式是否合法
function verifyPasswordFormat(passwordValue) {
  // 密码不能为空
  if (passwordValue == null || !passwordValue) {
    return false;
  }
  // 要求：由数字和字母组成，长度要在8-16位之间。
  const regs = /^[0-9A-Za-z]{8,16}$/;
  return regs.test(passwordValue);
}
// 抛出方法
export { verifyEmailFormat, verifyPasswordFormat };
