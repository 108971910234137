export const basePath = '/icon/'

export const iconList = {
  // 首页侧边栏
  videos: "videos.svg",
  videos_active: "videos_active.svg",
  background: "background.svg",
  background_active: "background_active.svg",
  trash: "trash.svg",
  trash_active: "trash_active.svg",
  setting: "setting.svg",
  setting_active: "setting_active.svg",
  about_us: "about_us.svg",
  about_us_active: "about_us_active.svg",
  privacy_policy: "privacy_policy.svg",
  privacy_policy_active: "privacy_policy_active.svg",
  terms_service: "terms_service.svg",
  terms_service_active: "terms_service_active.svg",
  contact_support: "contact_support.svg",
  contact_support_active: "contact_support_active.svg",
  // 其他
  upload: "upload.svg",
  close: "close.svg",
  logo: "logo.svg",
  SRecord: "SRecorder.svg",
  time: "time.svg",
  more: "more.svg",
  selected: "selected.svg",
  menu: "menu.svg",
  account: "account.svg",
  delete: "delete.svg",
  download: "download.svg",
  arrow: "arrow.svg",
  back: "back.svg",
  // 编辑页编辑栏
  noise: "noise.svg",
  subtitle: "subtitle.svg",
  thumbnail: "thumbnail.svg",
  split: "split.svg",
  stitch: "stitch.svg",
  edit: "edit.svg",
  // 视频播放按钮
  video_play: "video_play.svg",
  video_pause: "video_pause.svg",
  audio: "audio.svg",
  full_screen: "full_screen.svg",
  end_of_full_screen: "end_full_screen.svg",
  // 文件上传相关
  upload_cancel: "upload_cancel.svg",
  upload_pause: "upload_pause.svg",
  upload_resume: "upload_resume.svg",
  upload_complete: "upload_complete.svg",
  //
  editor_split: "editor_split.svg",
  add: "add.svg",
  undo: "undo.svg",
  redo: "redo.svg",
  save: "save.svg",
  cancel: "cancel.svg",
};

export default function Icon({
    name,
    width = '24px',
    height = '24px',
    color = '#000000',
    style,
}) {
    return (
      <img
        src={`${basePath}${iconList[name]}`}
        alt=""
        width={width}
        height={height}
        style={{
          color,
          ...style,
        }}
      />
    );
}