import React from "react";
import { Box, Grid } from "@mui/material";
//
import VideoControls from "../../../components/video-controls";
import "./loading.css";
export default function VideoPlay({ videoSrc, videoDuration, thumbnail }) {
  // video ref
  const videoRef = React.useRef(null);
  const fullscreenAreaRef = React.useRef(null);

  return (
    <Box
      sx={{
        height: "100%",
        width: "calc(100% - 360px)",
        aspectRatio: "2/1",
        backgroundColor: "rgba(228, 228, 228, 1)",
        padding: "32px",
        boxSizing: "border-box",
      }}
    >
      {videoSrc ? (
        <Grid
          ref={fullscreenAreaRef}
          container
          sx={{
            width: "100%",
            aspectRatio: "2/1",
            backgroundColor: "#000",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <video
            ref={videoRef}
            src={videoSrc}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
          />

          {thumbnail && (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                top: "0px",
                left: "0px",
                zIndex: "999",
              }}
            >
              <img
                src={thumbnail}
                alt=""
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </Box>
          )}
          <VideoControls
            fullscreenAreaRef={fullscreenAreaRef}
            videoRef={videoRef}
            videoDuration={videoDuration}
          />
        </Grid>
      ) : (
        <Box
          sx={{
            width: "100%",
            aspectRatio: "2/1",
            backgroundColor: "#000",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
            display: "flex",
          }}
        >
          <div className="div">
            <span className="span"></span>
            <span className="span"></span>
            <span className="span"></span>
            <span className="span"></span>
          </div>
        </Box>
      )}
    </Box>
  );
}
