export const editorList = [
  {
    icon: "noise",
    title: "Noise Reduction",
    type: "switch",
  },
  {
    icon: "subtitle",
    title: "Add subtitles",
    type: "switch",
  },
  {
    icon: "thumbnail",
    title: "Change thumbnail",
    type: "button",
  },
  {
    icon: "split",
    title: "Split and trim",
    type: "button",
  },
    {
      icon: "stitch",
      title: "Stitch videos",
      type: "button",
    },
  //   {
  //     icon: "edit",
  //     title: "Edit",
  //     type: "button",
  //   },
];
