import { isRefreshToken, RefreshToken } from "./RefreshToken";
import { decryptText } from "./AES";
const saveToDrive = async (file, globalUploadFileToDrive, setGlobalUploadFileToDrive) => {
  if (isRefreshToken()) {
    await RefreshToken();
  }
  let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
  googleAuthInfo = JSON.parse(googleAuthInfo);
  googleAuthInfo.access_token = decryptText(googleAuthInfo.access_token);
  if (globalUploadFileToDrive)
    window.uploadFileToGoogleDrive(
      file,
      googleAuthInfo.access_token,
      globalUploadFileToDrive,
      setGlobalUploadFileToDrive,
      globalUploadFileToDrive.length - 1,
    );
  else
    window.uploadFileToGoogleDrive(
      file,
      googleAuthInfo.access_token,
    
    );
};

export default saveToDrive;
