import Home from "../screen/home";
// import Home from "../page/Home";
import EditorRecorder from "../page/EditorRecorder";
import Template from "../page/components/Background/Template";
import ForgetPassWeb from "../page/components/Setting/ForgetPassWeb";
import Editor from "../screen/editor";
const routes = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/editor-recorder",
    element: <Editor />,
  },
  {
    path: "/template",
    element: <Template />,
  },
  {
    path: "/forget-pass-web",
    element: <ForgetPassWeb />,
  },
  {
    path: "/editor",
    element: <EditorRecorder />,
  },
];

export default routes;
