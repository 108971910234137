import CryptoJS from "crypto-js";
// 加密文本
const encryptText = (plainText) => {
  const encrypted = CryptoJS.AES.encrypt(
    plainText,
    process.env.REACT_APP_DRIVE_SECRET_KEY,
  ).toString();

  return encrypted;
};

// 解密文本
const decryptText = (encryptedText) => {
  const decrypted = CryptoJS.AES.decrypt(
    encryptedText,
    process.env.REACT_APP_DRIVE_SECRET_KEY,
  ).toString(CryptoJS.enc.Utf8);
  return decrypted;
};

export { encryptText, decryptText };
