import { alphaHexMap, RgbToHex, secToTime, subtitleStyle } from "./mock.js";
const url = new URL(window.location.href);

function addSubtitles(videoFile, subtitlesData, callback) {
  // Ass文件格式拼接
  // 初始化信息
  let assSubtitle = `[Script Info]\n;\n Script generated by \nScriptType: v4.00+\nPlayResX: 384\nPlayResY: 288\nWrapStyle: 2\nScaledBorderAndShadow: yes\n\n[V4+ Styles]\nFormat: Name, Fontname, Fontsize, PrimaryColour, SecondaryColour, OutlineColour, BackColour, Bold, Italic, Underline, StrikeOut, ScaleX, ScaleY, Spacing, Angle, BorderStyle, Outline, Shadow, Alignment, MarginL, MarginR, MarginV, Encoding\nStyle:Default,Roboto,16,&H00ffffff,&H00ffffff,&H4D000000,0,0,0,0,0,100,100,0,0,3,1,0,2,10,10,10,0\n\n[Events]\nFormat: Layer, Start, End, Style, Name, MarginL, MarginR, MarginV, Effect, Text\n`;

  //  拼接字幕内容`
  let textInform = "";
  for (let index = 0; index < subtitlesData.length; index++) {
    let element = subtitlesData[index];
    textInform =
      textInform +
      `Dialogue: 0,${secToTime(element.time / 10000000)}.00,${secToTime(
        element.time / 10000000 + 3,
      )}.00,Default,,0,0,0,,${element.text.replace(/\n/g, "\\n")}\n`;
  }
  assSubtitle = assSubtitle + textInform;
  const subtitlesBlob = new Blob([assSubtitle], {
    type: "application/octet-stream",
  });

  const info = {
    inputVideoFile: videoFile,
    inputVideoFileType: "mp4",
    inputSubtitlesFile: subtitlesBlob,
    inputSubtitlesFileType: "ass",
    fontFamily: "Roboto",
    outputFileName: "add_subtitle",
    outputFileType: "mp4",
    type: "addSubtitlesWithFile",
    url: url.origin,
  };

  const worker = new Worker("/core/ffmpeg-worker.js");
  worker.postMessage(info);
  worker.onmessage = (event) => {
    if (event.data) {
      callback(event.data);
    } else {
      callback(null);
    }
  };
}

export default addSubtitles;
