import React from 'react'
import { Grid, Slider } from '@mui/material'

export default function VideoAudio({ videoVolume, setVideoVolume, style, videoRef }) {
  return (
    <Grid
      container
      sx={{
        width: "45px",
        height: "150px",
        padding: "25px 0px",
        backgroundColor: "#333333",
        borderRadius: "8px",
        justifyContent: "center",
        alignItems: "center",
        ...style,
      }}
    >
      <Slider
        onChange={(e, v) => {
          setVideoVolume(v / 100);
          videoRef.audio(v / 100);
        }}
        orientation="vertical"
        color="white"
        size="small"
        value={videoVolume * 100}
      />
    </Grid>
  );
}
