/* eslint-disable no-undef */
import React, { useEffect } from "react";
import {
  Box,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { GlobalContext } from "../../../utils/GlobalContext";
import { verifyEmailFormat, verifyPasswordFormat } from "../../../utils/verifyFormat";
import { useMedia } from "react-use";

export default function OnTrial(props) {
  //定义注册时的定时器变量
  let registerTimer = null;
  const { setUserName, setUserToken, setUserSubState } = React.useContext(GlobalContext);
  const [values, setValues] = React.useState({
    amount: "",
    email: "",
    password: "",
    weight: "",
    weightRange: "",
    showPassword: false,
  });
  //注册时的状态
  const [registerEmailState, setRegisterEmailState] = React.useState("");
  const [registerPasswordState, setRegisterPasswordState] = React.useState("");
  //校验时的输入格式
  const [errorRegisterEmail, setErrorRegisterEmail] = React.useState("");
  const [errorRegisterPassword, setErrorRegisterPassword] = React.useState("");
  //点击登录按钮切换loading按钮
  const [loading, setLoading] = React.useState(false);
  //页面显示的响应后的错误提示
  const [registerError, setRegisterError] = React.useState("");
  // 用户注册
  async function register() {
    // 注册按钮进入 loading 状态
    setLoading(true);
    // 发送用户注册按钮点击事件
    sendConstructEventByEventParams("register_page", { click: "register" });
    // 判断用户名密码格式是否正确
    if (registerEmailState === "success" && registerPasswordState === "success") {
      // 动态引入注册,登录模块
      const { userRegister, userLogin } = await import("../../../api/user");
      // 发送用户注册请求
      const registerRes = await userRegister({
        username: values.email,
        password: values.password,
      });
      if (registerRes.status === "success") {
        // 发送用户注册成功事件
        sendConstructEventByEventParams("register_page", {
          register_result: "success",
        });
        // 注册成功用户自动登录
        const loginRes = await userLogin({
          username: values.email,
          password: values.password,
        });
        if (loginRes.status === "success") {
          // 发送用户登录成功事件
          sendConstructEventByEventParams("login_page", {
            login_result: "success",
          });
          // 设置登录用户信息
          setUserName(values.email);
          setUserToken(loginRes.response.token);
        } else {
          // 发送用户登录失败事件
          sendConstructEventByEventParams("login_page", {
            login_result: "fail",
            login_fail_reason: loginRes.message,
          });
          // 弹出错误提示
          setRegisterError("login error : " + loginRes.message);
        }
      } else {
        // 发生用户注册失败事件
        sendConstructEventByEventParams("register_page", {
          register_result: "fail",
          register_fail_reason: registerRes.message,
        });
        // 弹出错误提示
        setRegisterError("register error : " + registerRes.message);
      }
    } else {
      //邮箱，密码格式错误时提示
      if (values.email == "" && values.password == "") {
        setErrorRegisterEmail("The mailbox cannot be empty");
        setErrorRegisterPassword("The password cannot be empty");
      } else if (values.email == "") {
        setErrorRegisterEmail("The mailbox cannot be empty");
      } else if (values.password == "") {
        setErrorRegisterPassword("The password cannot be empty");
      }
    }
    // 结束注册按钮 loading 状态
    setLoading(false);
  }
  //检查邮箱格式是否正确
  const checkEmail = (event) => {
    setValues({ ...values, email: event.target.value });
    if (verifyEmailFormat(event.target.value)) {
      setRegisterEmailState("success");
      setErrorRegisterEmail("");
      return true;
    } else {
      setRegisterEmailState("error");
      setErrorRegisterEmail("Email format error");
      if (event.target.value == "") {
        setErrorRegisterEmail("");
      }
      return false;
    }
  };
  //检查密码是否正确
  const checkPassword = (event) => {
    setValues({ ...values, password: event.target.value });
    if (verifyPasswordFormat(event.target.value)) {
      setRegisterPasswordState("success");
      setErrorRegisterPassword("");
      return true;
    } else {
      setRegisterPasswordState("error");
      setErrorRegisterPassword("The password contains 8 to 16 digits or letters");
      if (event.target.value == "") {
        setErrorRegisterPassword("");
      }
      return false;
    }
  };
  //密码的隐藏与显示
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  //取消默认
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  useEffect(() => {
    return () => {
      //组件页面卸载后，清除定时器
      clearTimeout(registerTimer);
    };
  }, []);

  //媒体查询
  const isWide = useMedia("(max-width: 600px)");
  if (isWide) {
    return (
      <Box>
        <Box
          sx={{
            width: "100vw",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              width: "100vw",
              margin: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                height: "400px",
                // position: "relative",
                // top: "5vh",
                // left: "22vw",
              }}
            >
              {/* sm={8} md={12} xs={6} */}
              <Grid container justifyContent="center" item sm={8} md={12} xs={6}>
                <Typography
                  // variant="subtitle2"
                  gutterBottom
                  sx={{
                    textAlign: "center",
                    marginBottom: "88px",
                    height: "58px",
                    lineHeight: "58px",
                  }}
                >
                  <span
                    style={{
                      fontSize: "30px",
                      fontWeight: "bold",
                      color: "#323339",
                    }}
                  >
                    Create
                  </span>
                  <span style={{ fontSize: "30px" }}>your user</span>
                </Typography>
                {/* 输入框 */}
                {/*邮箱*/}
                <TextField
                  color="secondary"
                  InputProps={{
                    sx: {
                      height: "35px",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      top: "-12px",
                      fontSize: "16px",
                    },
                  }}
                  error={errorRegisterEmail === "" ? false : true}
                  id="Email"
                  onChange={checkEmail}
                  label="Email"
                  helperText={errorRegisterEmail}
                  sx={{ width: "100vw", height: "48px" }}
                />
                <Box
                  sx={{
                    marginLeft: "30px",
                    fontSize: "14px",
                    width: "100vw",
                    marginBottom: "30px",
                    color: "red",
                    overflow: "hidden",
                    wordBreak: "nowrap",
                  }}
                >
                  {registerError}
                </Box>
                {/*密码*/}
                <TextField
                  //Theme颜色黑
                  color="secondary"
                  InputProps={{
                    sx: {
                      height: "35px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={{ padding: "auto", fontSize: "14px" }}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {values.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  InputLabelProps={{
                    sx: {
                      top: "-12px",
                      fontSize: "16px",
                    },
                  }}
                  error={errorRegisterPassword === "" ? false : true}
                  sx={{ width: "100vw", height: "30px" }}
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={checkPassword}
                  label="Password"
                  helperText={errorRegisterPassword}
                />
                {/*后台错误提示*/}
                <Box
                  sx={{
                    marginLeft: "30px",
                    fontSize: "14px",
                    width: "100vw",
                    height: "28px",
                    color: "red",
                    lineHeight: "28px",
                    overflow: "hidden",
                    wordBreak: "nowrap",
                  }}
                >
                  {registerError}
                </Box>
                {/* 注册 */}
                {loading ? (
                  <LoadingButton
                    loading
                    variant="contained"
                    sx={{
                      width: "100vw",
                      height: "48px",
                      // marginLeft: "30px",
                      borderRadius: "24px",
                      outline: "none",
                      background: "rgb(0,85,225)",
                    }}
                  >
                    submit
                  </LoadingButton>
                ) : (
                  <Button
                    onClick={() => register("right")}
                    variant="contained"
                    sx={{
                      fontSize: "16px",
                      textTransform: "none",
                      borderRadius: "24px",
                      // marginLeft: "30px",
                      width: "100vw",
                      height: "48px",
                      outline: "none",
                      boxShadow: "0px 2px 4px rgba(88, 88, 88,0.5)",
                      ":hover": { backgroundColor: "#6176e8" },
                      marginTop: "40px",
                    }}
                  >
                    Sign Up
                  </Button>
                )}
                <Box
                  sx={{
                    textAlign: "center",
                    marginTop: "10px",
                    fontSize: "14px",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      marginRight: "10px",
                      fontFamily: "Quicksand-Medium, Quicksand",
                    }}
                  >
                    By creating an account, you agree to our
                    <Link
                      href="/static/privacy.html"
                      underline="hover"
                      onClick={() => {
                        console.log(1121);
                      }}
                    >
                      Privacy Policy
                    </Link>
                    and our
                    <Link href="/static/terms.html" underline="hover">
                      terms and conditions
                    </Link>
                  </Box>
                </Box>
                {/* 横线 */}
                {/* 注册 */}
                <Box
                  sx={{
                    width: "100vw",
                    textAlign: "center",
                    marginTop: "10px",
                    fontFamily: "Quicksand-Medium, Quicksand",
                  }}
                >
                  <Link
                    underline="none"
                    sx={{
                      fontSize: "16px",
                      color: "#323339",
                      fontFamily: "Quicksand-Medium, Quicksand",
                    }}
                  >
                    I already have an account？
                  </Link>
                  <Link
                    underline="hover"
                    sx={{ fontSize: "16px", color: "#253fe7" }}
                    onClick={() => {
                      //登录访问次数
                      sendConstructEventByEventParams("register_page", {
                        click: "login",
                      });
                      props.setNowPage(props.pages[0]);
                    }}
                  >
                    <br />
                    Log in
                  </Link>
                </Box>
              </Grid>
            </Box>
          </Box>
        </Box>
        {/* <Divider /> */}
      </Box>
    );
  }
  // 标记
  else
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "600px",
            maxHeight: "100vh",
          }}
        >
          <Grid container justifyContent="center" item>
            <Typography
              variant="body1"
              gutterBottom
              sx={{
                textAlign: "center",
                marginBottom: "88px",
                width: "416px",
                height: "58px",
                lineHeight: "58px",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#323339" }}>Create</span> your user
            </Typography>
            {/* 输入框 */}
            {/*邮箱*/}
            <TextField
              color="secondary"
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              InputLabelProps={{
                sx: {
                  top: "-6px",
                  fontSize: "16px",
                },
              }}
              error={errorRegisterEmail === "" ? false : true}
              id="Email"
              onChange={checkEmail}
              label="Email"
              helperText={errorRegisterEmail}
              sx={{
                width: "360px",
                height: "48px",
                "& div": {
                  fontSize: "16px",
                },
              }}
            />
            <Box
              sx={{
                textAlign: "left",
                fontSize: "14px",
                width: "360px",
                marginBottom: "40px",
                color: "red",
                overflow: "hidden",
                wordBreak: "nowrap",
              }}
            >
              {registerError}
            </Box>
            {/*密码*/}
            <TextField
              //Theme颜色黑
              color="secondary"
              InputProps={{
                sx: {
                  height: "40px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ padding: "auto", fontSize: "14px" }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  top: "-6px",
                  fontSize: "16px",
                },
              }}
              error={errorRegisterPassword === "" ? false : true}
              sx={{ width: "360px", height: "48px" }}
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={checkPassword}
              label="Password"
              helperText={errorRegisterPassword}
            />
            {/*后台错误提示*/}

            {/* 注册 */}
            {loading ? (
              <LoadingButton
                loading
                variant="contained"
                sx={{
                  borderRadius: "24px",
                  width: "360px",
                  height: "48px",
                  outline: "none",
                  marginTop: "72px",
                  background: "rgb(0,85,225)",
                }}
              >
                Submit
              </LoadingButton>
            ) : (
              <Button
                onClick={() => register("right")}
                variant="contained"
                sx={{
                  borderRadius: "24px",
                  width: "360px",
                  height: "48px",
                  marginBottom: "20px",
                  outline: "none",
                  fontSize: "16px",
                  marginTop: "72px",
                  boxShadow: "0px 2px 4px rgba(88, 88, 88,0.5)",
                  ":hover": { backgroundColor: "#6176e8" },
                }}
              >
                Sign Up
              </Button>
            )}
            <Box
              sx={{
                textAlign: "center",
                marginTop: "10px",
                fontSize: "14px",
                display: "flex",
              }}
            >
              <Box
                sx={{
                  marginRight: "10px",
                  fontFamily: "Quicksand-Medium, Quicksand",
                }}
              >
                By creating an account, you agree to our
                <Link href="/static/privacy.html" underline="hover">
                  Privacy Policy
                </Link>{" "}
                and our
                <Link href="/static/terms.html" underline="hover">
                  terms and conditions
                </Link>
              </Box>
            </Box>
            {/* 横线 */}
            {/* 注册 */}
            <Box
              sx={{
                width: "400px",
                textAlign: "center",
                fontSize: "14px",
                marginTop: "10px",
                fontFamily: "Quicksand-Medium, Quicksand",
              }}
            >
              I already have an account？
              <Link
                underline="hover"
                sx={{
                  fontSize: "16px",
                  color: "#253fe7",
                  fontFamily: "Quicksand-Medium, Quicksand",
                }}
                onClick={() => {
                  sendConstructEventByEventParams("register_page", {
                    click: "login",
                  });
                  props.setNowPage(props.pages[0]);
                }}
              >
                <br />
                Log in
              </Link>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
}
