import {  fetchFile } from "@ffmpeg/util";
import newFFmpegFactory from "./newFFmpeg";

const noiseReduction = async (file) => {
  let ffmpeg = await newFFmpegFactory.loadFFmpeg();

  /**
   *
   *
   * @description: 写入文件
   *
   *
   */

  await ffmpeg.writeFile(`input.mp4`, await fetchFile(file));

  /**
   *
   *
   * @description: 执行命令
   *
   *
   */

  await ffmpeg.exec([
    "-i",
    "input.mp4",
    "-af",
    "highpass=f=200,lowpass=f=3000",
    "-c:v",
    "copy",
    "output.mp4",
  ]);

  /**
   *
   *
   * @description: 读取文件
   *
   *
   */
    const data = await ffmpeg.readFile("output.mp4");
    const dataFile = new File([data], "output.mp4", { type: "video/mp4" });

    return dataFile;
};

export default noiseReduction;
