import storage from ".";
import { randomKey } from "../utils/random";
import StorageKey from "./storage-key";
// 
import TrashStorage from "./trash-storage";

const VideosStorage = {
  // 增
  add: async (key, value) => {
    // const key = randomKey();
    storage.write(key, value);
    //
    const videos = await storage.read(StorageKey.videos);
    if (videos) {
      videos.push(key);
      storage.write(StorageKey.videos, videos);
    } else {
      storage.write(StorageKey.videos, [key]);
    }

    return key;
  },

  // 删
  remove: (key) => {
    const videos = storage.read(StorageKey.videos);
    if (videos) {
      const index = videos.indexOf(key);
      if (index > -1) {
        videos.splice(index, 1);
        storage.write(StorageKey.videos, videos);
      }
    }

    TrashStorage.add(key);
  },

  // 恢复
  restore: (key) => {
    TrashStorage.restore(key);

    const videos = storage.read(StorageKey.videos);
    videos.push(key);
    storage.write(StorageKey.videos, videos);
  },

  // 改
  update: (key, value) => {
    storage.write(key, value);
  },

  // 查
  read: async (key) => {
    return await storage.read(key);
  },

  //
  async getAll() {
    const videos = await storage.read(StorageKey.videos);
    return {
      videos: videos || [],
      length: videos ? videos.length : 0,
    };
  },
};

export default VideosStorage;