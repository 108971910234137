import {  fetchFile } from "@ffmpeg/util";
import newFFmpegFactory from "./newFFmpeg";

const stitchVideoFFmpeg = async (videoList, stitchCommand) => {
  let ffmpeg = await newFFmpegFactory.loadFFmpeg();

  /**
   *
   *
   * @description: 写入文件
   *
   *
   */

  for (let index = 0; index < videoList.length; index++) {
    let videoItem = videoList[index];
    await ffmpeg.writeFile(`input${index}.mp4`, await fetchFile(videoItem));
  }

  /**
   *
   *
   * @description: 执行命令
   *
   *
   */

  await ffmpeg.exec([
    ...stitchCommand.inputFileCommand,
    "-filter_complex",
    stitchCommand.scaleCommand + stitchCommand.concatCommand,
    "-map",
    "[out]",
    "-vsync",
    "0",
    "-c:v",
    "libx264",
    "-preset",
    "veryfast",
    "-crf",
    "18",
    "output.mp4",
  ]);

  /**
   *
   *
   * @description: 读取文件
   *
   *
   */
};

export default stitchVideoFFmpeg;
