/* eslint-disable no-undef */
// REACT
import React, { useEffect, useContext } from "react";
// MUI
import { Box, CircularProgress, Stack, Card, Typography } from "@mui/material";
// PAYPAL
import {
  PayPalScriptProvider,
  PayPalButtons,
  usePayPalScriptReducer,
} from "@paypal/react-paypal-js";
import { verifySubscriptionValidity } from "../../../api/subscribe";
// 全局状态管理
import { GlobalContext } from "../../../utils/GlobalContext";

// MAIN
export default function TryDaysFree(props) {
  // 获取用户订阅计划
  const { userSubPlan } = useContext(GlobalContext);
  // 查询的订阅sub planId 年订阅 月订阅 clientId商家ID
  const [planId, setPlanId] = React.useState({}); // PAYPAL按钮
  const ButtonWrapper = ({ type }) => {
    const { userName, userToken, setUserSubState } = useContext(GlobalContext);
    const [{ options }, dispatch] = usePayPalScriptReducer();
    console.log(options, "options");
    useEffect(() => {
      dispatch({
        type: "resetOptions",
        value: {
          ...options,
          intent: "subscription",
        },
      });
    }, [type]);
    return (
      <PayPalButtons
        fundingSource="paypal"
        createSubscription={(data, actions) => {
          if (planId.sub === monthlyPlan.planId) {
            sendConstructEventByEventParams("pricing_dailog", {
              click_buy: "month",
            });
          } else if (planId.sub === yearlyPlan.planId) {
            sendConstructEventByEventParams("pricing_dailog", {
              click_buy: "year",
            });
          }
          return actions.subscription
            .create({
              plan_id: planId.sub,
              custom_id: userName,
            })
            .then((orderId) => {
              return orderId;
            });
        }}
        onApprove={async function (data, actions) {
          const res = await verifySubscriptionValidity({
            userName,
            userToken,
            planId: planId.sub,
            subscriptionId: data.subscriptionID,
          });
          if (res.status === "success") {
            setUserSubState(res.response);
            sendConstructEventByEventParams("navbar_drop_menu", {
              click: "navigation",
              navigation: props.item.EventCategroy,
            });
            if (planId.sub === monthlyPlan.planId) {
              sendConstructEventByEventParams("pricing_dailog", {
                click_buy_result: "month_success",
              });
            } else if (planId.sub === yearlyPlan.planId) {
              sendConstructEventByEventParams("pricing_dailog", {
                click_buy_result: "year_success",
              });
            }
          } else {
            if (planId.sub === monthlyPlan.planId) {
              sendConstructEventByEventParams("pricing_dailog_month", {
                click_buy_fail_reason: "fail_service_no_fount",
              });
            } else if (planId.sub === yearlyPlan.planId) {
              sendConstructEventByEventParams("pricing_dailog_year", {
                click_buy_fail_reason: "fail_service_no_fount",
              });
            }
          }
        }}
        onError={function (errorData) {
          console.log(errorData);
        }}
        onCancel={function (cancelData, actions) {
          if (planId.sub === monthlyPlan.planId) {
            sendConstructEventByEventParams("pricing_dailog_month", {
              click_buy_fail_reason: "fail_cancel",
            });
          } else if (planId.sub === yearlyPlan.planId) {
            sendConstructEventByEventParams("pricing_dailog_year", {
              click_buy_fail_reason: "fail_cancel",
            });
          }
        }}
        style={{
          label: "subscribe",
        }}
      />
    );
  };
  // 月订阅信息
  const [monthlyPlan, setMonthlyPlan] = React.useState(null);
  // 年订阅信息
  const [yearlyPlan, setYearlyPlan] = React.useState(null);
  const [userSelectPlan, setUserSelectPlan] = React.useState(props.plan);
  // 试用时间
  const trialTime = 14;
  const now = new Date();
  // 月订阅结束日期
  const monthlyPlanEndDate = new Date(
    now.getFullYear(),
    now.getMonth() + 1,
    now.getDate() + trialTime,
  );
  // 年订阅结束日期
  const yearlyPlanEndDate = new Date(
    now.getFullYear() + 1,
    now.getMonth(),
    now.getDate() + trialTime,
  );
  //订阅改变
  const [backgroundSelectMonth, setBackgroundSelectMonth] = React.useState("#000000");
  const [backgroundSelectYear, setBackgroundSelectYear] = React.useState("#000000");
  const [backgroundSelectMonthBox, setBackgroundSelectMonthBox] = React.useState("#fff");
  const [backgroundSelectYearBox, setBackgroundSelectYearBox] = React.useState("#fff");
  const chooseSubscribeMonth = (event) => {
    sendConstructEventByEventParams("pricing_dailog", { select_mode: "month" });
    setBackgroundSelectMonth("#253fe7");
    setBackgroundSelectYear("#000000");
    setBackgroundSelectMonthBox("#253fe7");
    setBackgroundSelectYearBox("#fff");
    setPlanId({ ...planId, sub: monthlyPlan.planId });
    setUserSelectPlan("/month");
  };
  function chooseSubscribeYear() {
    sendConstructEventByEventParams("pricing_dailog", { select_mode: "year" });
    setBackgroundSelectYear("#253fe7");
    setBackgroundSelectMonth("#000000");
    setBackgroundSelectYearBox("#253fe7");
    setBackgroundSelectMonthBox("#fff");
    setPlanId({ ...planId, sub: yearlyPlan.planId });
    setUserSelectPlan("/year");
  }
  // 页面初始化,获取可用订阅信息
  React.useEffect(() => {
    setMonthlyPlan({ ...userSubPlan.monthlyPlan });
    setYearlyPlan({ ...userSubPlan.yearlyPlan });
    setPlanId({
      sub: userSubPlan.monthlyPlan.planId,
      clientId: userSubPlan.clientId,
    });
  }, [userSubPlan]);
  // 设置默认选中项
  React.useEffect(() => {
    if (monthlyPlan && yearlyPlan) {
      if (props.plan === "/month") {
        chooseSubscribeMonth();
      } else {
        chooseSubscribeYear();
      }
    }
  }, [monthlyPlan, yearlyPlan]);
  return (
    <React.Fragment>
      {planId.clientId != null ? (
        <React.Fragment>
          {/* 标题 */}
          <Box
            sx={{
              width: "100%",
              margin: "auto",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "416px",
                padding: "24px 30px 24px 30px",
                position: "relative",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                component="div"
                sx={{ textAlign: "center", paddingTop: "10px" }}
              >
                Try{" "}
                {userSelectPlan === "/month" ? monthlyPlan.trialDuration : yearlyPlan.trialDuration}{" "}
                days free
              </Typography>
            </Box>
          </Box>
          {/* 订阅有效期提示 */}
          <Box sx={{ marginTop: "20px", marginLeft: "30px" }}>
            <Box
              sx={{
                marginLeft: "1px",
                fontSize: "18px",
                color: "#253fe7",
              }}
            >
              Service time: {new Date().getFullYear()}-{new Date().getMonth() + 1}-
              {new Date().getDate()}~
              {userSelectPlan === "/month"
                ? `${monthlyPlanEndDate.getFullYear()}-${
                    monthlyPlanEndDate.getMonth() + 1
                  }-${monthlyPlanEndDate.getDate()}`
                : `${yearlyPlanEndDate.getFullYear()}-${
                    yearlyPlanEndDate.getMonth() + 1
                  }-${yearlyPlanEndDate.getDate()}`}
            </Box>
          </Box>
          {/* 订阅计费方式描述 */}
          <Box sx={{ marginTop: "20px" }}>
            <Box
              sx={{
                marginLeft: "30px",
                fontSize: "18px",
              }}
            >
              Today's charge: $0.00. After{" "}
              {userSelectPlan === "/month" ? monthlyPlan.trialDuration : yearlyPlan.trialDuration}{" "}
              days you will be charged{" $"}
              {userSelectPlan === "/month"
                ? monthlyPlan.billPrice + " per month"
                : yearlyPlan.billPrice + " per year"}
              . You can cancel before the free trial is end, and you will not pay anything.
            </Box>
          </Box>
          {/* 订阅选项卡*/}
          <Box
            sx={{
              marginTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            {/* 第一个选项卡  月订阅 */}
            <Card
              sx={{
                borderRadius: "8px",
                border: "1px solid rgba(136, 83, 83, 0.12)",
                width: "240px",
                height: "140px",
                margin: "10px",
                "&:hover": {
                  boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)",
                },
              }}
              onClick={chooseSubscribeMonth}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: backgroundSelectMonthBox,
                  height: "10px",
                }}
              ></Box>
              <Typography
                variant="body5"
                sx={{
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              >
                Pro
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* 工具 */}
                <Typography
                  variant="body5"
                  sx={{
                    fontWeight: "700",
                    fontSize: "30px",
                    paddingLeft: "10px",
                    color: backgroundSelectMonth,
                  }}
                >
                  ${monthlyPlan.billPrice}
                </Typography>

                <Typography
                  variant="body5"
                  component="div"
                  sx={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    width: "49px",
                    height: "35px",
                    lineHeight: "35px",
                    color: "#323339",
                  }}
                >
                  /month
                </Typography>
              </Box>

              <Typography
                variant="body5"
                component="div"
                sx={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  color: "#323339",
                  marginTop: "10px",
                }}
              >
                Unlimited document processing
              </Typography>
            </Card>
            {/* 第二个选项卡 年订阅*/}
            <Card
              sx={{
                borderRadius: "8px",

                border: "1px solid rgba(136, 83, 83, 0.12)",
                width: "240px",
                height: "140px",
                margin: "10px",
                "&:hover": {
                  boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)",
                },
              }}
              onClick={chooseSubscribeYear}
            >
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: backgroundSelectYearBox,
                  height: "10px",
                }}
              ></Box>
              <Typography
                variant="body5"
                sx={{
                  fontSize: "18px",
                  marginLeft: "10px",
                }}
              >
                Pro
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                {/* 工具 */}
                <Typography
                  variant="body5"
                  sx={{
                    fontWeight: "700",
                    fontSize: "30px",
                    paddingLeft: "10px",
                    color: backgroundSelectYear,
                  }}
                >
                  ${yearlyPlan.billPrice}
                </Typography>

                <Typography
                  variant="body5"
                  component="div"
                  sx={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    width: "49px",
                    height: "35px",
                    lineHeight: "35px",
                  }}
                >
                  /year
                </Typography>
              </Box>

              <Typography
                variant="body5"
                component="div"
                sx={{
                  marginLeft: "10px",
                  fontSize: "14px",
                  color: "#323339",
                  marginTop: "10px",
                }}
              >
                Unlimited document processing
              </Typography>
            </Card>
          </Box>
          {/* paypal按钮 */}
          <Box
            sx={{
              margin: "40px 60px",
              height: "60px",
            }}
          >
            {console.log(planId, "planId")}
            <PayPalScriptProvider
              options={{
                "client-id": planId.clientId,
                components: "buttons",
                intent: "subscription",
                vault: true,
              }}
            >
              <ButtonWrapper type="subscription" />
            </PayPalScriptProvider>
          </Box>
        </React.Fragment>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </React.Fragment>
  );
}
