import React from 'react'
import { Box, Grid } from '@mui/material'
import IconButton from '../../components/ui/button/icon-button'
import TextButton from '../../components/ui/button/text-button'
import deleteAll from '../../utils/tool-bar/deleteAll'
import downloadAll from '../../utils/tool-bar/downloadAll'

export default function ToolBar({
    num,
    selectedKeys,
    setVideosData,
    setSelectedKeys
}) {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: '16px',
                left: '0px',
                height: '48px',
                width: '100%',
                padding: '0px 50px',
                boxSizing: 'border-box',
                zIndex: '100',
            }}
        >
            <Grid
                container
                sx={{
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(47, 101, 247, 1)',
                    borderRadius: '8px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    color: 'rgba(255, 255, 255, 1)',
                }}
            >
                {/* 左侧 */}
                <Grid
                    container
                    sx={{
                        width: 'auto',
                        height: '100%',
                        alignItems: 'center',
                    }}
                >
                    <span
                        style={{
                            width: '66px',
                            textAlign: 'center',
                            fontSize: '14px',
                            fontWeight: '600',
                            margin: '0px 16px',
                        }}>
                        {num} Select
                    </span>
                    {/* 删除所有的选择  */}
                    <IconButton
                    onClick={()=> {
                        deleteAll(selectedKeys,setVideosData,setSelectedKeys);
            
                    }}
                        icon='delete'
                        width={24}
                        height={24}
                        style={{
                            ":hover": {
                                backgroundColor: 'rgba(255,255,255,0.3)',
                            },
                        }}
                    />
                    {/* 下载所有  */}

                    <IconButton
                    onClick={()=> {
                        downloadAll({selectedKeys,setSelectedKeys});
                    }}
                        icon='download'
                        width={24}
                        height={24}
                        style={{
                            marginLeft: '12px',
                            ":hover": {
                                backgroundColor: 'rgba(255,255,255,0.3)',
                            },
                        }}
                    />
                </Grid>
                {/* 右侧 */}
                <TextButton
                onClick={()=> {
                    setSelectedKeys([])
                }}
                    title='Cancel'
                    style={{
                        margin: '0px 16px',
                        height: '32px',
                    }}
                />
            </Grid>
        </Box>
    )
}
