import React from 'react'

export default function TemplateCard() {
    return (
        <img src="/images/template.png" alt=""
            style={{
                width: '100%',
            }}
        />
    )
}
