import axios from "axios";
import { encryptText, decryptText } from "./AES";

/**
 * 判断是否需要刷新token
 */
const isRefreshToken = () => {
  let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
  if (googleAuthInfo) {
    googleAuthInfo = JSON.parse(googleAuthInfo);
    if (googleAuthInfo.expires_in - new Date().getTime() < 300000) {
      return true;
    } else {
      return false;
    }
  }
  return true;
};

const RefreshToken = async (refreshToken) => {
  try {
    let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
    googleAuthInfo = JSON.parse(googleAuthInfo);
    let refresh_token = await axios.post(
      `https://oauth2.googleapis.com/token?client_id=${
        process.env.REACT_APP_CLIENT_ID
      }&client_secret=${process.env.REACT_APP_DRIVE_SECRET_KEY}&refresh_token=${decryptText(
        googleAuthInfo.refresh_token,
      )}&grant_type=refresh_token`,
    );
    /**
     *
     * @更新token
     *
     */
    googleAuthInfo.access_token = encryptText(refresh_token.data.access_token);
    googleAuthInfo.expires_in = new Date().getTime() + refresh_token.data.expires_in * 1000;
    window.localStorage.setItem("googleAuthInfo", JSON.stringify(googleAuthInfo));
    return refresh_token.data.access_token;
  } catch (error) {
    console.log("[ error ] >", error);
  }
};

export { isRefreshToken, RefreshToken };
