import React from "react";
import { Grid, Box, Drawer, Select, Button, MenuItem } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ToolBar from "../tool-bar";
import QuickStart from "../quick-start";
import HomeList from "../../../components/home-list";
import VideoCard from "../../../components/video-card";
import VideosStorage from "../../../storage/videos-storage";

import CreateClassroom from "./CreateClassroom";
import SelectClassroom from "./SelectClassroom";

export default function MyVideos({
  isOpenClassroomDrawer,
  setIsOpenClassroomDrawer,
  classroomList,
}) {
  // video列表渲染数据
  const [videosData, setVideosData] = React.useState([]);
  // 当前选择的视频key值集合
  const [selectedKeys, setSelectedKeys] = React.useState([]);

  React.useEffect(() => {
    // 获取videos列表
    const getVideosList = async () => {
      const videosList = await VideosStorage.getAll();
      console.log("[ videosList ] >", videosList);
      if (videosList.length > 0) {
        setVideosData(videosList.videos);
      }
    };

    getVideosList();
  }, []);

  // 选择视频事件
  const selectVideo = React.useCallback(
    (key, type) => {
      if (type === "add") {
        setSelectedKeys([...selectedKeys, key]);
      }

      if (type === "remove") {
        setSelectedKeys(selectedKeys.filter((item) => item !== key));
      }
    },
    [selectedKeys, setSelectedKeys],
  );

  return (
    <Grid
      item
      xs
      sx={{
        width: "100%",
        maxWidth: "1040px",
        height: "calc(100vh - 64px)",
        boxSizing: "border-box",
        padding: "0px 50px 64px 50px",
        position: "relative",
        // 内容超出高度，垂直滚动内容
        overflowY: "auto",
        // 内容超出宽度，水平滚动内容
        overflowX: "hidden",
        // 内容超出高度，显示滚动条
        "&::-webkit-scrollbar": {
          width: "0px",
          height: "0px",
        },
        "&::-webkit-scrollbar-thumb": {
          borderRadius: "4px",
          backgroundColor: "#d3d1d9",
        },
      }}
    >

      {/* 工具条 */}
      {selectedKeys.length > 0 && (
        <ToolBar
          setVideosData={setVideosData}
          selectedKeys={selectedKeys}
          num={selectedKeys.length}
          setSelectedKeys={setSelectedKeys}
        />
      )}
      {/* 快速开始 */}
      <QuickStart title="My Videos" subTitle={`${videosData.length} Videos`} />

      {videosData.length === 0 ? (
        <NoVideos />
      ) : (
        <HomeList
          minWidth={"252px"}
          listData={videosData}
          ListItem={VideoCard}
          selectVideo={selectVideo}
          state={selectedKeys.length > 0 ? "selected" : "normal"}
          setVideosData={setVideosData}
          selectedKeys={selectedKeys}
        />
      )}
      {/* Classroom  */}

      <Drawer
        anchor={"right"}
        open={isOpenClassroomDrawer}
        onClose={() => {
          setIsOpenClassroomDrawer(false);
        }}
      >
        <Box
          sx={{
            width: "100vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100vh",
            position: "relative",
          }}
        >
           <Button
            onClick={()=> {
                setIsOpenClassroomDrawer(false)
            }}
            sx={{
              padding: "0px",
              position: "absolute",
              top: "40px",
              right: "40px",
              minWidth: "auto",
            }}
          >
            <CloseIcon
              sx={{
                color: "#000000",
              }}
            />
          </Button>
          {classroomList.length === 0 ? (
            <CreateClassroom 
            setIsOpenClassroomDrawer={setIsOpenClassroomDrawer}
            />
          ) : (
            <SelectClassroom
              setIsOpenClassroomDrawer={setIsOpenClassroomDrawer}
              classroomList={classroomList}
            />
          )}
        </Box>
      </Drawer>
    </Grid>
  );
}

// 没有视频
function NoVideos() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "100px",
      }}
    >
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: "500",
          fontFamily: "Roboto",
          textAlign: "center",
          color: "#cfcfcf",
          marginBottom: "20px",
        }}
      >
        You haven't recorded a video yet.
      </Box>
      <Box
        sx={{
          fontSize: "20px",
          fontWeight: "500",
          fontFamily: "Roboto",
          textAlign: "center",
          color: "#cfcfcf",
        }}
      >
        You can use the recording function on this page or install plugins for recording
      </Box>
    </Box>
  );
}
