import React from 'react'
import { Box, LinearProgress, Slider } from "@mui/material";

export default function VideoProgress({ setProgress, progress = 0, videoRef, videoDuration }) {
  return (
    <Box
      sx={{
        width: "100%",
        height: "4px",
        backgroundColor: "rgba(255,255,255,0.3)",
        position: "relative",
      }}
    >
      <Slider
        onChange={(e, value) => {
          videoRef.setVideoCurrentTime(videoDuration * (value / 100));
          videoRef.setCurrentTime(videoDuration * (value / 100));

          setProgress(value / 100);
        }}
        value={progress * 100}
        sx={{
          padding: 0,
          position: "absolute",
          top: "0px",
        }}
      />
      {/* <Box
        sx={{
          width: progress,
          height: "100%",
          backgroundColor: "rgba(76, 122, 249, 1)",
          transition: "width 0.5s linear",
        }}
      /> */}
    </Box>
  );
}
