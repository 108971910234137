import React from 'react'
import { Grid, List, ListItemButton } from '@mui/material'
import Icon from '../../../../components/ui/icon'
import IconButton from '../../../../components/ui/button/icon-button'
import SwitchButton from '../../../../components/ui/button/switch-button'
// 
import { editorList } from './editor-list'

export default function Edit({
    setToolState,
    toChangeThumbnail,
    isRNoiseLoading,
    isReduceNoise,
    reduceNoiseHandle,
    isAddSubtitleLoading,
    isAddSubtitles,
    addSubtitlesHandle,
}) {
    return (
        <List>
            {
                editorList.map((item, index) => (
                    <EditItem
                        key={index}
                        icon={item.icon}
                        title={item.title}
                        type={item.type}
                        isLoading={
                            item.title === 'Noise Reduction' ? isRNoiseLoading :
                                item.title === 'Add subtitles' ? isAddSubtitleLoading :
                                    null
                        }
                        checked={
                            item.title === 'Noise Reduction' ? isReduceNoise :
                                item.title === 'Add subtitles' ? isAddSubtitles :
                                    null
                        }
                        onClick={(e) => {
                            if (item.title === 'Split and trim') {
                                setToolState('split')
                            }
                            if (item.title === 'Stitch videos') {
                                setToolState('stitch')
                            }
                            if (item.title === 'Change thumbnail') {
                                toChangeThumbnail();
                            }
                            if (item.title === 'Noise Reduction') {
                                reduceNoiseHandle()
                            }
                            if (item.title === 'Add subtitles') {
                                addSubtitlesHandle()
                            }

                        }}
                    />
                ))
            }
        </List>
    )
}

function EditItem({
    icon,
    title,
    type,
    onClick,
    isLoading,
    checked,
}) {
    return (
        <ListItemButton
            onClick={onClick}
        >
            <Grid
                container
                sx={{
                    width: '100%',
                    height: '32px',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    cursor: 'pointer',
                }}
            >
                <Grid
                    container
                    sx={{
                        width: 'auto',
                        height: '100%',
                        alignItems: 'center',
                    }}
                >
                    <Icon
                        name={icon}
                        style={{
                            marginRight: '12px',
                        }}
                    />

                    <span
                        style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            color: 'rgba(51, 51, 51, 1)',
                        }}
                    >
                        {title}
                    </span>
                </Grid>

                {type === 'switch' && <SwitchButton isLoading={isLoading} checked={checked} />}
                {type === 'button' && <IconButton icon='arrow' />}
            </Grid>
        </ListItemButton>
    )
}
