export const timeToSec = function (time) {
  var s = "";
  var hour = time.split(":")[0];
  var min = time.split(":")[1];
  var sec = time.split(":")[2];
  s = Number(hour * 3600) + Number(min * 60) + Number(sec);
  return s;
};

export const secToTime = (seconds) => {
  let hour =
    Math.floor(seconds / 3600) >= 10
      ? Math.floor(seconds / 3600)
      : "0" + Math.floor(seconds / 3600);
  seconds -= 3600 * hour;
  let min =
    Math.floor(seconds / 60) >= 10 ? Math.floor(seconds / 60) : "0" + Math.floor(seconds / 60);
  seconds -= 60 * min;
  let sec = seconds >= 10 ? seconds : "0" + seconds;
  sec = Math.floor(sec) >= 10 ? Math.floor(sec) : "0" + Math.floor(sec);
  min = Math.floor(min) >= 10 ? Math.floor(min) : "0" + Math.floor(min);
  hour = Math.floor(hour) >= 10 ? Math.floor(hour) : "0" + Math.floor(hour);

  return hour + ":" + min + ":" + sec;
};
