import api from ".";
import md5 from "md5";
export const audioToText = async (successCallback = "", failureCallback = "") => {
  try {
    const timeStamp = new Date().getTime();
    const str = `${process.env.REACT_APP_SECRET_KEY}timeStamp=${timeStamp}`;
    const signature = md5(str);
    const res = await api({
      url: "/getMSToken/" + timeStamp,
      method: "GET",
      headers: {
        signature,
      },
    });
    if (res?.status === 200) {
      return res.data.dataResult.token;
    } else {
      failureCallback();
      throw new Error("network error");
    }
  } catch (error) {
    failureCallback();
    throw new Error("network error");
  }
};
