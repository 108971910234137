const getVideoInfo = (videoSrc) => {
  const video = document.createElement("video");
  video.preload = "metadata";
  video.src = videoSrc;
  return new Promise((resolve, reject) => {
    video.onloadedmetadata = () => {
      const duration = video.duration;
      const { videoWidth, videoHeight } = video;
      resolve({ duration, videoWidth, videoHeight });
    };
    video.onerror = (err) => {
      reject(err);
    };
  });
};
export default getVideoInfo;
