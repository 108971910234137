import React, { useContext } from "react";
import { Button, TextField } from "@mui/material";
import TextButton from "../../../components/ui/button/text-button";
import { decryptText } from "../../../utils/GoogleOAuth2/AES";
import { HomeContext } from "..";
import saveToDrive from "../../../utils/GoogleOAuth2/SaveFileToDrive";
import localforage from "localforage";
import newFFmpegFactory from "../../../utils/ffmpeg/newFFmpeg";

export default function CreateClassroom({ setIsOpenClassroomDrawer }) {
  const { globalVideoKey, isLoading, setIsLoading } = useContext(HomeContext);

  return (
    <>
      <span
        style={{
          fontSize: "20px",
          fontWeight: "700",
          fontFamily: "Roboto-Medium",
          lineHeight: "60px",
          marginRight: "20px",
        }}
      >
        Please enter the course name
      </span>
      <TextField variant="standard" id="courseNameTextFile" />
      <TextButton
        disabled={isLoading}
        style={{
          marginTop: "20px",
        }}
        onClick={() => {
          try {
            // 开始loading
            setIsLoading(true);

            let courseName = document.getElementById("courseNameTextFile").value;
            /**
             *
             *
             * @description: 获取 google  token
             *
             *
             */
            let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
            googleAuthInfo = JSON.parse(googleAuthInfo);
            googleAuthInfo.access_token = decryptText(googleAuthInfo.access_token);

            /**
             *
             *
             * @description: 创建课程
             *
             *
             */
            let createClassroomCoursesRes = window.createClassroomCourses(
              googleAuthInfo.access_token,
              courseName,
            );

            window.toast.promise(createClassroomCoursesRes, {
              pending: "Creating Classroom...",
              success: "Created successfully 👌",
              error: "Creation failed 🤯",
            });

            createClassroomCoursesRes.then(async (res) => {
              console.log(res);
              // 创建成功 保存 classroom id
              if (res.id) {
                // 将文件保存到drive 中
                // saveToDrive
                // 获取文件
                let globalVideoKeyRes = await localforage.getItem(globalVideoKey);
                let fileObject = new File(globalVideoKeyRes.file, "test.webm", {
                  type: "video/webm",
                });
                let fileTitle = globalVideoKeyRes.videoTitle;

                /**
                 *
                 *
                 * @description: 转换为 MP4
                 *
                 *
                 */
                // 转换为 mp4
                let webmToMp4Res = await newFFmpegFactory.webmToMp4(fileObject);

                /**
                 *
                 *
                 * @description: 上传到 google drive 中
                 *
                 *
                 */
                window.toast('Loading File...', { autoClose:false });
                saveToDrive(webmToMp4Res);
                let getWindowFileIDsetIntervalID = setInterval(() => {
                  if (window.fileID) {
                    clearInterval(getWindowFileIDsetIntervalID);

                    // 上传到 classroom 中
                    // 获取 token
                    let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
                    googleAuthInfo = JSON.parse(googleAuthInfo);
                    googleAuthInfo.access_token = decryptText(googleAuthInfo.access_token);

                    /**
                     *
                     *
                     * @description: 上传资料
                     *
                     *
                     */
                    let createClassroomCoursesSourceRes = window.createClassroomCoursesSource(
                      googleAuthInfo.access_token,
                      res.id,
                      window.fileID,
                      fileTitle,
                    );
                    window.toast.promise(createClassroomCoursesSourceRes, {
                      pending: "Uploading to Classroom...",
                      success: "Uploaded successfully 👌",
                      error: "Upload failed 🤯",
                    });
                    createClassroomCoursesSourceRes.then((res) => {
                      if (res.error) throw new Error(res.error);
                      else {
                        setIsOpenClassroomDrawer(false);
                        setIsLoading(false);
                      }
                    });
                  }
                }, 1000);
              } else {
                setIsOpenClassroomDrawer(false);
                setIsLoading(false);
                window.toast.warn("Something went wrong, please try again later");
              }
            });
          } catch (error) {
            setIsOpenClassroomDrawer(false);
            setIsLoading(false);
            window.toast.warn("Something went wrong, please try again later");
          }
        }}
        title={"Confirm Create"}
      ></TextButton>
    </>
  );
}
