import React from 'react'
import { Box } from '@mui/material'

export default function TimePeriod({
    width,
    isActive,
    onClick,
}) {
    return (
        <Box
            onClick={onClick}
            sx={{
                width: width,
                height: '100%',
                backgroundColor: isActive ? '#D2DEFF' : '#fbfbfb',
                border: isActive ? '2px solid #2F65F7' : '2px solid #c4c2ce',
                borderRadius: '8px',
                boxSizing: 'border-box',
                cursor: 'pointer',
            }}
        />
    )
}
