import React from 'react'
import { Box, Tab, Tabs } from '@mui/material'

export default function ToolTabs({
    tabsData,
    tab,
    setTab,
}) {
    return (
        <Box
            sx={{
                width: '100%',
                height: '42px',
                boxSizing: 'border-box',
                marginBottom: '17px',
                padding: '0px 16px',
            }}
        >
            <Tabs value={tab} onChange={(event, newValue) => setTab(newValue)} aria-label="basic tabs example">
                {
                    tabsData.map((item, index) => (
                        <Tab key={index} label={item} />
                    ))
                }
            </Tabs>
        </Box>
    )
}
