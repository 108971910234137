import { Box, Typography, Switch } from "@mui/material";
import React from "react";
export default function Watermark({ pageSize, subtitleInformation, setSubtitleInformation }) {
  return (
    <Box
      sx={{
        width: pageSize.width <= 1095 ? "37%" : "384px",
      }}
    >
      {/* 顶部是否开启水印  */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px !important",
          padding: "10px",
          boxSizing: "border-box",
          alignItems: "center",
        }}
      >
        {/* title  */}
        <Typography variant="recorderFunctionTitle">Add Watermark</Typography>
        <Switch
          checked={subtitleInformation.isNeed}
          onChange={(event, value) => {
            subtitleInformation.isNeed = value;
            setSubtitleInformation({
              ...subtitleInformation,
            });
          }}
        />
      </Box>
      {/* 底部编辑区域  */}
    </Box>
  );
}
