/* eslint-disable no-undef */
// PDFToolList
// 引入功能模块
// REACT
import React, { useEffect, useState } from "react";
// MUI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { GlobalContext } from "../../../utils/GlobalContext";
import SubscribeDialogs from "../subscribe/SubscribeDialogs";
import Divider from "@mui/material/Divider";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import MyDrawer from "../Home/MyDrawer";

// 工具卡片组件
function ToolCard(props) {
  const { userSubPlan, userToken } = React.useContext(GlobalContext);
  const [isLogin, setIsLogin] = useState(false);
  useEffect(() => {
    if (userToken && userSubPlan && isLogin) {
      // document.getElementsByClassName("hj")[0].click();
      setIsLogin(false);
    }
  }, [userToken]);
  return (
    <Grid item xs={12} md={4} container justifyContent="center">
      {/* 工具卡片 */}
      <Card
        sx={{
          borderRadius: "12px",
          border: "1px solid rgba(136, 83, 83, 0.12)",
          height: "500px",
          width: "300px",
          margin: "10px",
          "&:hover": { boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)" },
        }}
      >
        <Box
          sx={{
            border: "0",
            height: "20px",
            width: "300px",
          }}
        ></Box>
        <CardContent
          sx={{
            height: "680px",
          }}
        >
          {/* 工具名称 */}
          <Box
            sx={{
              width: "250px",
            }}
          >
            <Typography>
              <span style={{ fontWeight: "700", paddingLeft: "10px" }}>{props.tool.name}</span>
              {props.tool.isNot && (
                <span style={{ color: "#253fe7", fontSize: "16px" }}>/Most Popular</span>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "10px",
            }}
          >
            {/* 工具 */}

            <Typography
              sx={{
                fontWeight: "700",
                fontSize: { lg: "40px", md: "40px", sm: "40px", xs: "40px" },
                paddingLeft: "10px",
              }}
            >
              {props.tool.currencySymbol}
              {props.tool.billPrice}
            </Typography>
            <Typography
              component="div"
              sx={{
                marginLeft: "5px",
                fontSize: "16px",
                width: "49px",
                height: "35px",
                lineHeight: "35px",
                color: "#323339",
              }}
            >
              {props.tool.billCycle}
            </Typography>
          </Box>
          {/* 工具描述 */}
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "bold",
              paddingLeft: "10px",
            }}
          >
            {props.tool.preProfile}
            <Typography component="span" sx={{ fontSize: "16px", marginLeft: "5px" }}>
              {props.tool.nextProfile}
            </Typography>
          </Typography>
          {/* 按钮 */}
          <Box sx={{ textAlign: "center", marginTop: "30px" }}>
            {userToken ? (
              // 传参
              <SubscribeDialogs plan={props.tool.billCycle}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    sendConstructEventByEventParams("pricing_page", {
                      click: props.tool.billCycle.slice(1),
                    });
                  }}
                  sx={{
                    borderRadius: "25px",
                    color: "#253fe7",
                    width: "192px",
                    height: "48px",
                    textTransform: "none",
                    fontSize: "18px",
                  }}
                >
                  {props.tool.buttonText}
                </Button>
              </SubscribeDialogs>
            ) : (
              <React.Suspense fallback={<p>loading</p>}>
                <MyDrawer anchor={"right"} form="free_try" startPage={"onTrial"}>
                  <Button
                    variant="outlined"
                    onClick={() => setIsLogin(true)}
                    sx={{
                      color: "#253fe7",
                      borderRadius: "25px",
                      width: "192px",
                      height: "48px",
                      textTransform: "none",
                      fontSize: "18px",
                      "&:hover": {
                        color: "#253fe7",
                      },
                    }}
                  >
                    {props.tool.buttonText}
                  </Button>
                </MyDrawer>
              </React.Suspense>
            )}
            <Box
              sx={{
                marginTop: "30px",
              }}
            >
              <Divider />
            </Box>
          </Box>
          {/* 工具描述 */}
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              paddingLeft: "10px",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {props.tool.middleTittle}
          </Typography>
          {/* 第一张卡片规则 */}
          <Grid sx={{ paddingLeft: "10px" }}>
            <Grid container item xs={12}>
              <Grid item xs={10}>
                <Typography
                  variant="body5"
                  sx={{
                    fontSize: "16px",
                    // width: "260px",
                    // paddingLeft: "10px",
                    marginTop: "15px",
                    color: "rgb(121, 121, 121)",
                    lineHeight: "20px",
                  }}
                >
                  {props.tool.planOne}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    paddingLeft: "10px",
                    marginTop: "1px",
                    color: "rgb(121, 121, 121)",
                  }}
                >
                  <CheckCircleOutlineIcon />
                </Typography>
              </Grid>
            </Grid>
            {/* xxxxx */}
            <Grid container item xs={12}>
              <Grid item xs={10}>
                <Typography
                  variant="body5"
                  sx={{
                    fontSize: "16px",
                    width: "240px",
                    marginTop: "15px",
                    color: "rgb(121, 121, 121)",
                    lineHeight: "25px",
                  }}
                >
                  {props.tool.planTwo}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    paddingLeft: "10px",
                    color: "rgb(121, 121, 121)",
                  }}
                >
                  <CheckCircleOutlineIcon />
                </Typography>
              </Grid>
            </Grid>
            <Grid
              sx={{
                marginTop: "5px",
              }}
              container
              item
              xs={12}
            >
              <Grid item xs={10}>
                <Typography
                  variant="body5"
                  sx={{
                    fontSize: "16px",
                    width: "240px",

                    color: "rgb(121, 121, 121)",
                    lineHeight: "25px",
                  }}
                >
                  {props.tool.planThree}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    fontSize: "16px",
                    paddingLeft: "10px",
                    color: "rgb(121, 121, 121)",
                  }}
                >
                  <CheckCircleOutlineIcon />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
// MAIN
export default function DemoMitglied({ handleClosePricing, setWhichFunction }) {
  const { userSubPlan, userSubState } = React.useContext(GlobalContext);
  const [toolList, setToolList] = React.useState([]);
  // 第二第三个卡片描述
  React.useEffect(() => {
    if (userSubPlan) {
      setToolList([
        {
          planId: userSubPlan.monthlyPlan.planId,
          clientId: userSubPlan.clientId,
          name: "Standard",
          preMonth: "",
          currencySymbol: userSubPlan.monthlyPlan.currencySymbol,
          billPrice: userSubPlan.monthlyPlan.billPrice,
          billCycle: "/month",
          preProfile: "",
          nextProfile: "",
          buttonText: "Start Free Trial",
          path: "/",
          isNot: true,
          middleTittle: "All features of the Free plan plus:",
          planOne: "Web Recording",
          planTwo: "Speech recognition",
          planThree: "Smart Summary",
        },
        {
          planId: userSubPlan.yearlyPlan.planId,
          clientId: userSubPlan.clientId,
          name: "Pro",
          preMonth: " pre month",
          currencySymbol: userSubPlan.yearlyPlan.currencySymbol,
          billPrice: userSubPlan.yearlyPlan.billPrice,
          billCycle: "/year",
          preProfile: "Total$" + `${(userSubPlan.yearlyPlan.billPrice / 12).toFixed(2)}` + "/month",
          nextProfile: "billed annually",
          buttonText: "Start Free Trial",
          path: "/",
          isNot: false,
          middleTittle: "All features of the Free plan plus:",
          planOne: "Web Recording",
          planTwo: "Speech recognition",
          planThree: "Smart Summary",
        },
      ]);
    }
  }, [userSubPlan]);
  React.useEffect(() => {
    if (userSubState && userSubState.vip) {
      document.getElementsByClassName("gohome")[0].click();
    }
  }, [userSubState && userSubState.vip]);
  return (
    <Box marginTop="50px" className="Subscribe">
      <a href="/" position="subscribe_to_home" className="gohome"></a>
      {/* 工具列表 */}
      <Box sx={{ flexGrow: 1, margin: "auto", maxWidth: "1200px" }}>
        <Grid container>
          <Grid item xs={12} md={4} container justifyContent="center">
            <Card
              sx={{
                borderRadius: "12px",
                border: "1px solid rgba(136, 83, 83, 0.12)",
                height: "500px",
                width: "300px",
                margin: "10px",
                "&:hover": {
                  boxShadow: "0px 8px 20px 0px rgba(0, 0, 0, 0.12)",
                },
              }}
            >
              <Box
                sx={{
                  border: "0",
                  height: "20px",
                  width: "300px",
                }}
              ></Box>
              <CardContent
                sx={{
                  height: "680px",
                }}
              >
                {/* 工具名称 */}
                <Box
                  sx={{
                    width: "250px",
                  }}
                >
                  <Typography sx={{ fontWeight: "700", paddingLeft: "10px" }} variant="body5">
                    Basic
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: "10px",
                  }}
                >
                  {/* 工具 */}
                  <Typography
                    sx={{
                      fontWeight: "700",
                      fontSize: {
                        lg: "40px",
                        md: "40px",
                        sm: "40px",
                        xs: "40px",
                      },
                      paddingLeft: "10px",
                    }}
                  >
                    $0
                  </Typography>
                </Box>
                <Box sx={{ textAlign: "center", marginTop: "30px" }}>
                  {/* 标记 */}
                  <a
                    href="/?scroll=PDFToolList"
                    position="subscribe_to_home_toollist"
                    className="goHome"
                  />
                  <Button
                    variant="outlined"
                    sx={{
                      fontSize: "18px",
                      borderRadius: "25px",
                      width: "192px",
                      height: "48px",
                      textTransform: "none",
                      marginBottom: "30px",
                      marginTop: "50px",
                      color: "#253fe7",
                    }}
                    onClick={() => {
                      handleClosePricing();
                      setWhichFunction(0);
                      sendConstructEventByEventParams("pricing_page", {
                        click: "get_start",
                      });
                      // document.getElementsByClassName("goHome")[0].click();
                      sendConstructEventByEventParams("pricing", {
                        click: "free_plan",
                      });
                    }}
                  >
                    Get Start
                  </Button>
                </Box>
                {/* 分割线 */}
                <Box>
                  <Divider />
                </Box>
                {/* 工具描述 */}
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontWeight: "bold",
                    paddingLeft: "10px",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                >
                  Free features include:
                </Typography>
                {/* 第一张卡片第个规则 */}
                <Grid container item xs={12}>
                  <Grid item xs={10} sx={{ paddingLeft: "10px" }}>
                    <Typography
                      variant="body5"
                      sx={{
                        fontSize: "16px",
                        width: "240px",
                        lineHeight: "20px",
                        marginTop: "15px",

                        color: "rgb(121, 121, 121)",
                      }}
                    >
                      Web Recording
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography
                      sx={{
                        fontSize: "16px",
                        color: "rgb(121, 121, 121)",
                      }}
                    >
                      <CheckCircleOutlineIcon />
                    </Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          {userSubPlan &&
            toolList.map((tool, index) => {
              return <ToolCard key={index} tool={tool} />;
            })}
        </Grid>
      </Box>
    </Box>
  );
}
