import axios from "axios";
import axiosRetry from "axios-retry";
/**
 * 封装的 axios 请求方法
 * @param {Object} axiosConfig 请求设置
 * @param {String} axiosConfig.url 请求路径
 * @param {String} axiosConfig.method 请求方法
 * @param {*} axiosConfig.data 请求数据
 * @param {Object} axiosConfig.headers 请求头
 * ...
 * @returns {Promise} axiosRequest 请求承诺
 */
export default function api(axiosConfig) {
  const service = axios.create({
    // 设置请求前缀(基础路径)
    baseURL: process.env.REACT_APP_SERVER_BASEURL,
    // 设置超时时长
    timeout: process.env.REACT_APP_SERVER_TIME_OUT || 30000,
    // 设置请求头
    headers: {
      // 设置 POST 请求默认请求头
      post: {
        "Content-Type": "application/json",
      },
      // 设置 PUT 请求默认请求头
      put: {
        "Content-Type": "multipart/form-data",
      },
    },
  });
  // 请求自动重试次数
  axiosRetry(service, { retries: process.env.REACT_APP_REQUEST_RETRIES || 3 });
  return service(axiosConfig);
}
/**
 * 处理回调
 * @param {Object} res 响应数据
 * @param {Function} callback 回调函数
 * @returns {*} response 请求响应结果
 */
export const response = (res, callback, msg) => {
  msg && console.log(msg);
  if (res.status) {
    res.status === "success" ? console.log(JSON.stringify(res)) : console.error(JSON.stringify(res));
  } else {
    res = { status: "error", message: "Response message lost." };
  }
  return typeof callback === "function" ? callback(res) : res;
};
