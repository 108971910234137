import React from "react";
import { Box, Grid } from "@mui/material";
import IconButton from "../ui/button/icon-button";
//
import VideoProgress from "./video-progress";
import VideoSpeed from "./video-speed";
import VideoAudio from "./video-audio";
//
import Controls from "./controls";
import { secToTime } from "../../utils/editor-recorder/handleTime";
import fullEndScreen from "../../utils/editor-recorder/fullEndScreen";

export default function VideoControls({ videoRef, videoDuration, fullscreenAreaRef }) {
  // 视频播放状态
  const [videoState, setVideoState] = React.useState("pause"); // pause | play | end
  // 视频播放时间
  const [currentTime, setCurrentTime] = React.useState(0);
  // 视频总时长
  const [duration, setDuration] = React.useState(0);
  // 当前进度
  const [progress, setProgress] = React.useState(0);
  // 当前播放速度
  const [videoSpeed, setVideoSpeed] = React.useState(1);
  // 当前的音量
  const [videoVolume, setVideoVolume] = React.useState(0.5);
  //是否全屏
  const [isFullScreen, setIsFullScreen] = React.useState(false);
  // 控件实例
  const controlsRef = React.useRef(null);

  // 当前使用工具类型
  const toolType = React.useRef("");
  const [isOpenSpeed, setIsOpenSpeed] = React.useState(false);
  const [isOpenAudio, setIsOpenAudio] = React.useState(false);

  React.useEffect(() => {
    if (videoRef.current) {
      controlsRef.current = new Controls(
        videoRef,
        videoDuration,
        setProgress,
        setVideoState,
        setCurrentTime,
      );
      controlsRef.current.init();
    }
  }, [videoRef, videoDuration]);

  React.useEffect(() => {
    console.log("videoDuration", videoDuration);
    setDuration(videoDuration);
    // 监听退出全屏 ->esc
    document.onfullscreenchange = () => {
      if (!document.fullscreenElement) {
        setIsFullScreen(false);
      }
    };
  }, [videoDuration]);

  return (
    <Box
      ref={fullscreenAreaRef}
      sx={{
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0px",
        left: "0px",
      }}
    >
      {/* 视频遮盖层 */}
      <Box
        onClick={() => {
          if (toolType.current === "speed") {
            setIsOpenSpeed(false);
          }
          if (toolType.current === "audio") {
            setIsOpenAudio(false);
          }
        }}
        sx={{
          width: "100%",
          height: "100%",
          background: "transparent",
          zIndex: "100",
        }}
      />
      <Box
        onClick={() => {
          if (toolType.current === "speed" && isOpenSpeed) {
            setIsOpenSpeed(false);
          }
          if (toolType.current === "audio" && isOpenAudio) {
            setIsOpenAudio(false);
          }
        }}
        sx={{
          width: "100%",
          position: "absolute",
          bottom: "0px",
          left: "0px",
        }}
      >
        {/* 进度条 */}
        <VideoProgress
          progress={progress}
          setProgress={setProgress}
          videoRef={controlsRef.current}
          videoDuration={videoDuration}
        />
        {/* 控件 */}
        <Grid
          container
          sx={{
            width: "100%",
            height: "56px",
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            justifyContent: "space-between",
          }}
        >
          {/* 左侧 */}
          <Grid
            container
            sx={{
              width: "auto",
              height: "100%",
              alignItems: "center",
            }}
          >
            {/* play & pause */}
            <IconButton
              icon={
                videoState === "pause"
                  ? "video_play"
                  : videoState === "end"
                  ? "video_play"
                  : "video_pause"
              }
              onClick={() => {
                controlsRef.current?.playButtonHandle();
              }}
              style={{
                margin: "0px 16px",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            />

            {/* time */}
            <span
              style={{
                fontSize: "16px",
                fontWeight: "500",
                fontFamily: "Quicksand",
                color: "rgba(255, 255, 255, 1)",
              }}
            >
              {secToTime(currentTime)} / {secToTime(duration)}
            </span>
          </Grid>

          {/* 右侧 */}
          <Grid
            container
            sx={{
              height: "100%",
              width: "auto",
              alignItems: "center",
            }}
          >
            {/* 播放速度 */}
            <Grid
              container
              onClick={() => {
                if (toolType.current === "audio") setIsOpenAudio(false);

                setIsOpenSpeed(!isOpenSpeed);
                toolType.current = "speed";
              }}
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                marginRight: "24px",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            >
              <span
                style={{
                  fontSize: "20px",
                  fontWeight: "500",
                  color: "rgba(255, 255, 255, 1)",
                }}
              >
                {videoSpeed}x
              </span>
            </Grid>
            ;{/* 音量 */}
            <IconButton
              icon="audio"
              onClick={() => {
                if (toolType.current === "speed") setIsOpenSpeed(false);

                setIsOpenAudio(!isOpenAudio);
                toolType.current = "audio";
              }}
              style={{
                marginRight: "24px",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            />
            ;{/* 全屏 */}
            <IconButton
              onClick={() => {
                setIsFullScreen(!isFullScreen);
                fullEndScreen(isFullScreen, fullscreenAreaRef);
              }}
              icon={isFullScreen ? "end_of_full_screen" : "full_screen"}
              style={{
                marginRight: "24px",
                ":hover": {
                  backgroundColor: "rgba(255, 255, 255, 0.2)",
                },
              }}
            />
          </Grid>
        </Grid>

        {/* 视频倍速调节 */}
        {isOpenSpeed && (
          <VideoSpeed
            setVideoSpeed={setVideoSpeed}
            videoRef={controlsRef.current}
            style={{
              position: "absolute",
              bottom: "60px",
              right: "4px",
            }}
          />
        )}

        {/* 视频音量 */}
        {isOpenAudio && (
          <VideoAudio
            videoRef={controlsRef.current}
            setVideoVolume={setVideoVolume}
            videoVolume={videoVolume}
            style={{
              position: "absolute",
              bottom: "60px",
              right: "69px",
            }}
          />
        )}
      </Box>
    </Box>
  );
}

// 将时间格式转换为 00:00:00
function formatTime(time) {
  const hours = Math.floor(time / 60 / 60);
  const minutes = Math.floor(time / 60) - hours * 60;
  const seconds = Math.floor(time % 60);
  return `${hours}:${minutes}:${seconds}`;
}
