export const SideList = [{
    title: 'My Videos',
    value: 'videos',
}, {
    title: 'Background',
    value: 'background',
}, {
    title: 'Trash',
    value: 'trash',
}, {
    title: 'Setting',
    value: 'setting',
}]
