import React from 'react'
import { Grid, Box } from '@mui/material'
import COLOR_THEME from '../../../utils/color-scheme'
import Icon from '../icon'

export default function IconButton({
    icon,
    width,
    height,
    onClick,
    isActive = false,
    style,
}) {
    return (
      <Grid
        id={icon}
        container
        onClick={onClick}
        sx={{
          width: "auto",
          padding: "2px",
          borderRadius: "6px",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
          position: "relative",
          ":hover": {
            backgroundColor: COLOR_THEME.gray.hover,
          },
          ...style,
        }}
      >
        {isActive && (
          <Box
            sx={{
              width: "3px",
              height: "3px",
              borderRadius: "50%",
              backgroundColor: "#FA1515",
              position: "absolute",
              right: "3px",
              top: "3px",
            }}
          />
        )}

        <Icon name={icon} width={width} height={height} />
      </Grid>
    );
}
