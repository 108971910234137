import React from 'react'
import { Grid } from '@mui/material'
import TextButton from '../../components/ui/button/text-button'
import RecordPopover from '../../page/components/Record/RecordPopover'
export default function QuickStart({
    title,
    subTitle,
}) {

      //控制录屏弹窗显示和隐藏
  const [isOpenPopover, setIsOpenPopover] = React.useState(false);


    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: '40px',
                alignItems: 'center',
                justifyContent: 'space-between',
                margin: '36px 0px 24px 0px',
            }}
        >
            {/* title */}
            <Grid
                container
                sx={{
                    alignItems: 'center',
                    width: 'auto',
                }}
            >
                <span
                    style={{
                        fontSize: '32px',
                        fontWeight: '700',
                        color: 'rgba(0, 0, 0, 0.87)',
                        marginRight: '16px',
                    }}
                >
                    {title}
                </span>
                <span
                    style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: 'rgba(0, 0, 0, 0.87)',
                    }}
                >
                    {subTitle}
                </span>
            </Grid>
            <TextButton
            onClick={()=> {
                setIsOpenPopover(true)
            }}
                title='Quick Start'
            />
            <RecordPopover isOpenPopover={isOpenPopover} setIsOpenPopover={setIsOpenPopover} />
        </Grid>
    )
}
