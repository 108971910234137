import React from "react";

// Import the routes object from the router.js file
import routes from "./router";

// Import the useRoutes hook from the react-router-dom package
import { useRoutes } from "react-router-dom";

// Import the animate.css library
import "animate.css";

// Export the App component
export default function App() {
  // Create a const variable named outlet that will store the routes object
  const outlet = useRoutes(routes);

  // Return an HTML div element that will be the root of the app
  return <div>{outlet}</div>;
}
