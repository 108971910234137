import VideosStorage from "../../storage/videos-storage";
import localforage from "localforage";

const initVideoInfo = async (
  uuid,
  uuidCorrespondingVideo,
  setPlayKey,
  setVideoTitle,
  setVideoDuration,
  setVideoSrc,
  setVideoInfo,
  setTranscriptData,
  setTranscriptState,
  getTranscriptData,
  setGlobalCurrentVideo,
  globalCurrentVideo,
) => {
  /**
   * @description: 优先根据url参数获取视频信息
   * 1. 如果 url 包含 uuid
   * 2. 判断是否存在
   * 3. 不存在 首次存
   * 4. 存在使用直接读取的能力
   */

  if (uuid && !uuidCorrespondingVideo) {
    //uuid 存在并且对应的视频不存在
    await setPlayKey(uuid);

    let videoInformation = {
      videoDuration: 0,
      videoCurrentTime: 0,
      videoSrc: "",
      videoWidth: 0,
      videoHeight: 0,
      isPlay: false,
      videoTitle: "",
    };
    let isCanReadSetIntervalID = setInterval(async () => {
      let isCanRead = await localforage.getItem("isCanRead");
      if (!isCanRead) return;

      clearInterval(isCanReadSetIntervalID); //终止定时器
      localforage.setItem("isCanRead", false); // 重置 isCanRead 数据库
      videoInformation.file = await localforage.getItem("exchangeFiles");
      videoInformation.videoTitle = await localforage.getItem("fileTitle"); // 初始化title
      videoInformation.videoDuration = await localforage.getItem("exchangeFilesLength"); //初始化时间轨道

      setVideoTitle(videoInformation.videoTitle);
      setVideoDuration(videoInformation.videoDuration);

      // *获取 video 数据
      let videoFileBlob = new Blob(videoInformation.file, {
        type: "video/webm",
      });
      videoInformation.videoSrc = URL.createObjectURL(videoFileBlob);
      setGlobalCurrentVideo({
        ...globalCurrentVideo,
        videoFile: videoFileBlob,
      });

      const video = document.createElement("video");
      video.src = videoInformation.videoSrc;
      video.currentTime = 1;

      video.oncanplay = () => {
        videoInformation.videoWidth = video.videoWidth;
        videoInformation.videoHeight = video.videoHeight;
        videoInformation.videoCurrentTime = video.currentTime;

        // *cover
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        ctx.fillRect(0, 0, video.videoWidth, video.videoHeight);
        ctx.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        videoInformation.videoCover = canvas.toDataURL("image/jpeg", 0.5);
        // *cover
        setVideoInfo(videoInformation);

        VideosStorage.add(uuid, videoInformation);

        // 判断是否存在transcript数据
        if (videoInformation.transcriptData && videoInformation.transcriptData.length > 0) {
          setTranscriptData(videoInformation.transcriptData);
          setTranscriptState("end");
        } else {
          // 获取transcript数据
          getTranscriptData(videoInformation.file, videoInformation, uuid);
        }
      };
    }, 200);
  }
};

export default initVideoInfo;
