import React, { useContext } from "react";
import { Box, Select, MenuItem } from "@mui/material";
import TextButton from "../../../components/ui/button/text-button";
import { HomeContext } from "..";
import localforage from "localforage";
import newFFmpegFactory from "../../../utils/ffmpeg/newFFmpeg";
import saveToDrive from "../../../utils/GoogleOAuth2/SaveFileToDrive";
import { decryptText } from "../../../utils/GoogleOAuth2/AES";

export default function SelectClassroom({ setIsOpenClassroomDrawer, classroomList }) {
  const { globalVideoKey, isLoading, setIsLoading } = useContext(HomeContext);

  let [currentClassroomID, setCurrentClassroomID] = React.useState(classroomList[0]?.id);

  return (
    <>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <span
          style={{
            fontSize: "20px",
            fontWeight: "700",
            fontFamily: "Roboto-Medium",
            lineHeight: "60px",
            marginRight: "20px",
          }}
        >
          Select Classroom
        </span>
        <Select
          value={currentClassroomID}
          onChange={(event) => {
            console.log(event.target.value);
            setCurrentClassroomID(event.target.value);
          }}
          sx={{
            width: "200px",
            fontSize: "16px",
          }}
        >
          {classroomList.map((classroomListItem, index) => {
            return (
              <MenuItem
                sx={{
                  fontSize: "16px",
                }}
                key={index}
                value={classroomListItem.id}
              >
                {classroomListItem.name}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      <TextButton
        disabled={isLoading}
        // 选择课程的上传按钮
        onClick={async () => {
          try {
            // 开始loading
            setIsLoading(true);
            /**
             *
             *
             * @description: 获取文件
             *
             * 转换文件
             *
             *
             * 上传文件
             *
             *
             */
            window.toast("Loading File...", { autoClose: false });
            let globalVideoKeyRes = await localforage.getItem(globalVideoKey);
            let fileObject = new File(globalVideoKeyRes.file, "test.webm", {
              type: "video/webm",
            });
            let fileTitle = globalVideoKeyRes.videoTitle;
            let webmToMp4Res = await newFFmpegFactory.webmToMp4(fileObject);
            saveToDrive(webmToMp4Res);
            let getWindowFileIDsetIntervalID = setInterval(() => {
              if (window.fileID) {
                clearInterval(getWindowFileIDsetIntervalID);
                // 上传到 classroom 中
                // 获取 token
                let googleAuthInfo = window.localStorage.getItem("googleAuthInfo");
                googleAuthInfo = JSON.parse(googleAuthInfo);
                googleAuthInfo.access_token = decryptText(googleAuthInfo.access_token);

                /**
                 *
                 *
                 * @description: 上传资料
                 *
                 *
                 */
                let createClassroomCoursesSourceRes = window.createClassroomCoursesSource(
                  googleAuthInfo.access_token,
                  currentClassroomID,
                  window.fileID,
                  fileTitle,
                );
                window.toast.promise(createClassroomCoursesSourceRes, {
                  pending: "Uploading to Classroom...",
                  success: "Uploaded successfully 👌",
                  error: "Upload failed 🤯",
                });
                createClassroomCoursesSourceRes.then((res) => {
                  if (res.error) throw new Error(res.error);
                  else {
                    setIsOpenClassroomDrawer(false);
                    setIsLoading(false);
                  }
                });
              }
            }, 1000);
          } catch (error) {
            setIsOpenClassroomDrawer(false);
            setIsLoading(false);
            window.toast.warn("Something went wrong, please try again later");
          }
        }}
        style={{
          marginTop: "20px",
        }}
        title={"Confirm Upload"}
      ></TextButton>
    </>
  );
}
