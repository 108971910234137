import { Box } from "@mui/system";
import React from "react";

export default function VideoTrack({
  positioningRefSize,
  videoRef,
  videoDuration,
  signTrackInformation,
  videoTrackInformation,
  setVideoTrackInformation,
  editButtonEnable,
  setEditButtonEnable,
  whichTrackIsSelect,
  setWhichTrackIsSelect,
  videoTrackIndex,
  pointerTruePosition,
  setPointerTruePosition,
  undoStack,
  setUndoStack,
  pageSize,
  videoInformation,
  setVideoInformation,
}) {
  // ?移动左侧 -- marginLeft 并且调整中间的宽度
  // ?移动右侧  -- 中间宽度
  // 初始化数据
  let [trackPosition, setTrackPosition] = React.useState({
    left: 0,
    right: 0,
    leftProportion: 0,
    rightProportion: 0,
  });
  //* 拖拽事件区域  ----
  // #当鼠标 按下的时候需要记录当前的 坐标
  const onMouseDownFun = (event) => {
    if (event.target.getAttribute("data-id") === "left") {
      //  添加监听事件
      let startLeft = trackPosition.left;
      document.onmousemove = (e) => {
        onMouseMoveLeft(event.screenX, startLeft, e);
      };
    } else if (event.target.getAttribute("data-id") === "right") {
      let startRight = trackPosition.right;
      document.onmousemove = (e) => {
        onMouseMoveRight(event.screenX, startRight, e);
      };
    }
  };
  // !左侧的移动事件
  const onMouseMoveLeft = (clickPosition, startLeft, event) => {
    // 禁止存储
    undoStack.isUndoStack = true;
    setUndoStack({ ...undoStack });
    // pc和手机端的事件不同，手机端没有event.screenX
    if (event.screenX) {
      trackPosition.left = startLeft + event.screenX - clickPosition;
      // 如果已经到达了最左侧
      if (trackPosition.left < signTrackInformation.minStartTime) {
        trackPosition.left = signTrackInformation.minStartTime;
        // 移除事件-- 默认松手
        removeMouseMouse();
      }
      // 如果已经和右侧的的重合
      if (trackPosition.left + trackPosition.right > positioningRefSize.width - 30) {
        trackPosition.left = positioningRefSize.width - 30 - trackPosition.right;
        removeMouseMouse();
      }
    } else {
      trackPosition.left = startLeft + (event.targetTouches[0].pageX - clickPosition);
    }
    trackPosition.leftProportion = trackPosition.left / positioningRefSize.width;
    setTrackPosition({
      ...trackPosition,
    });
    setVideoTrackInformation([...videoTrackInformation]);
    //!---- 鼠标松开移除 移动监听事件

    document.onmouseup = () => {
      undoStack.isUndoStack = false;
      setUndoStack({ ...undoStack });
      removeMouseMouse();
      setTrackPosition({
        ...trackPosition,
      });
      setVideoTrackInformation([...videoTrackInformation]);
    };
  };

  // !右侧的移动事件
  const onMouseMoveRight = (clickPosition, startRight, event) => {
    // 禁止存储
    undoStack.isUndoStack = true;
    setUndoStack({ ...undoStack });
    // pc和手机端的事件不同，手机端没有event.screenX
    if (event.screenX) {
      trackPosition.right = startRight - (event.screenX - clickPosition);
      // // 如果已经到达了最右侧
      if (trackPosition.right < signTrackInformation.maxEndTime) {
        trackPosition.right = signTrackInformation.maxEndTime;
        // 移除事件-- 默认松手
        removeMouseMouse();
      }
      // // 如果已经和左侧的的重合
      if (trackPosition.left + trackPosition.right > positioningRefSize.width - 30) {
        trackPosition.right = positioningRefSize.width - 30 - trackPosition.left;
        removeMouseMouse();
      }
    } else {
      trackPosition.right = startRight + (event.targetTouches[0].pageX - clickPosition);
    }
    trackPosition.rightProportion = trackPosition.right / positioningRefSize.width;
    setTrackPosition({
      ...trackPosition,
    });
    setVideoTrackInformation([...videoTrackInformation]);
    //!---- 鼠标松开移除 移动监听事件

    document.onmouseup = () => {
      undoStack.isUndoStack = false;
      setUndoStack({ ...undoStack });
      removeMouseMouse();
      setTrackPosition({
        ...trackPosition,
      });
      setVideoTrackInformation([...videoTrackInformation]);
    };
  };

  //!---- 鼠标松开移除 移动监听事件
  const removeMouseMouse = () => {
    document.onmousemove = null;
    document.onmouseup = null;
  };
  //* 拖拽事件区域  ---- end

  // *计算时间和长度的比例
  React.useEffect(() => {
    trackPosition.left = trackPosition.leftProportion * positioningRefSize.width;
    trackPosition.right = trackPosition.rightProportion * positioningRefSize.width;
    setTrackPosition({
      ...trackPosition,
    });
  }, [pageSize.width]);

  //# 中间的指针 悬浮
  // *设置 悬浮黄色的坐标
  let [pointerFalsePosition, setPointerFalsePosition] = React.useState({
    isShow: false,
    left: 20,
  });

  // ?悬浮显示 黄色的指针
  const onMouseEnterShowPointer = () => {
    pointerFalsePosition.isShow = true;
    setPointerFalsePosition({
      ...pointerFalsePosition,
    });
  };
  // ?悬浮隐藏 黄色的指针
  const noMouseLeaveHiddenPointer = () => {
    pointerFalsePosition.isShow = false;
    setPointerFalsePosition({
      ...pointerFalsePosition,
    });
  };
  // !设置真正的坐标 -- 并且让视频动起来
  const setTruePointerPosition = (event) => {
    // 存储当前点击的轨道
    pointerTruePosition.left = pointerFalsePosition.left;
    pointerTruePosition.computeLeft = event.pageX - window.innerWidth * 0.1;
    setWhichTrackIsSelect({
      index: videoTrackIndex,
      signTrackInformation,
      offsetX: pointerTruePosition.computeLeft,
      selectPosition: pointerTruePosition.left,
    });
    pointerTruePosition.isShow = true;
    editButtonEnable.split = false;
    setEditButtonEnable({
      ...editButtonEnable,
    });
    setPointerTruePosition({
      ...pointerTruePosition,
    });
  };
  const centerOnMousemove = (event) => {
    // const a = document.createElement("div")
    pointerFalsePosition.left = event.pageX - event.target.getBoundingClientRect().x;
    setPointerFalsePosition({
      ...pointerFalsePosition,
    });
  };
  // 监听当左右的拖拽的位置发生改变的时候需要改变指针的位置
  React.useEffect(() => {
    pointerTruePosition.isShow = false;
    setPointerTruePosition({
      ...pointerTruePosition,
    });
    editButtonEnable.split = true;

    setEditButtonEnable({
      ...editButtonEnable,
    });
    // videoTrackInformation[index]
  }, [trackPosition]);
  // 当确定红色指针的位置的时候，需要调整视频的时间
  React.useEffect(() => {
    videoRef.current.currentTime = Math.floor(
      (pointerTruePosition.computeLeft / positioningRefSize.width) * videoDuration,
    );
    videoInformation.videoCurrentTime = Math.floor(
      (pointerTruePosition.computeLeft / positioningRefSize.width) * videoDuration,
    );
    setVideoInformation({ ...videoInformation });
  }, [pointerTruePosition.left]);
  React.useEffect(() => {
    trackPosition.leftProportion = trackPosition.left / positioningRefSize.width;
    trackPosition.rightProportion = trackPosition.right / positioningRefSize.width;
    // setTrackPosition({
    //   ...trackPosition,
    // });
  }, [videoTrackInformation]);
  // #  测试区域
  React.useEffect(() => {
    // trackPosition.left = signTrackInformation.startTime;
    // trackPosition.right = signTrackInformation.endTime;
    trackPosition.left = signTrackInformation.startTimeProportion * positioningRefSize.width;
    trackPosition.right = signTrackInformation.endTimeProportion * positioningRefSize.width;
    setTrackPosition({
      ...trackPosition,
    });
  }, [signTrackInformation]);

  React.useEffect(() => {
    signTrackInformation.startTimeProportion = trackPosition.leftProportion;
    signTrackInformation.endTimeProportion = trackPosition.rightProportion;
    signTrackInformation.startTime = trackPosition.left;
    signTrackInformation.endTime = trackPosition.right;
    setVideoTrackInformation([...videoTrackInformation]);
  }, [trackPosition]);

  return (
    <Box
      sx={{
        width: "100%",
        position: "absolute",
        height: "80px",
        display: "flex",
        top: "0px",
      }}
    >
      {/* 左侧  */}
      <Box
        data-id="left"
        onMouseDown={(event) => {
          onMouseDownFun(event);
        }}
        sx={{
          position: "absolute",
          left: trackPosition.left,
          cursor: "ew-resize",
          zIndex: "999",
        }}
      >
        <img
          style={{
            pointerEvents: "none",
          }}
          height={"100%"}
          src={
            videoTrackIndex === whichTrackIsSelect.index
              ? "/images/editor-recorder/track-left.svg"
              : "/images/editor-recorder/track-left-unselect.svg"
          }
          alt="track-left"
        />
      </Box>
      {/* 中间  */}
      <Box
        onMouseMove={(event) => {
          centerOnMousemove(event);
        }}
        onMouseEnter={() => {
          onMouseEnterShowPointer();
        }}
        onMouseLeave={() => {
          noMouseLeaveHiddenPointer();
        }}
        onClick={(event) => {
          setTruePointerPosition(event);
        }}
        sx={{
          opacity: videoTrackIndex === whichTrackIsSelect.index ? "1" : "0.4",
          zIndex: "990",
          position: "absolute",
          left: `${15 + trackPosition.left}px`,
          width: positioningRefSize.width - 30 - trackPosition.left - trackPosition.right + "px",
          height: "100%",
          boxSizing: "border-box",
          background: "rgba(76, 122, 249,0.4)",
          borderTop: "6px solid #4b7af9",
          borderBottom: "6px solid #4b7af9",
          "& div": {
            pointerEvents: "none",
          },
        }}
      >
        {/* 播放的时间 -- 假*/}
        {pointerFalsePosition.isShow && (
          <Box
            sx={{
              position: "absolute",
              left: pointerFalsePosition.left,
              height: "100%",
              width: "2px",
              background: "#ffcfca",
            }}
          ></Box>
        )}
      </Box>
      {/* 右边  */}
      <Box
        data-id="right"
        onMouseDown={(event) => {
          onMouseDownFun(event);
        }}
        sx={{
          zIndex: "999",
          position: "absolute",
          right: trackPosition.right,
          cursor: "ew-resize",
        }}
      >
        <img
          style={{
            pointerEvents: "none",
          }}
          height={"100%"}
          src={
            videoTrackIndex === whichTrackIsSelect.index
              ? "/images/editor-recorder/track-right.svg"
              : "/images/editor-recorder/track-right-unselect.svg"
          }
          alt="track-right"
        />
      </Box>
    </Box>
  );
}
