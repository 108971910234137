import React from 'react'
import { Button } from '@mui/material'
import COLOR_THEME from '../../../utils/color-scheme'
import Icon from '../icon'

export default function TextButton({ title, icon, onClick, style = {}, disabled = false, loading, loadingImg }) {
  return (
    <Button
      startIcon={loading ? loadingImg : icon && <Icon name={icon} style={{ marginRight: "8px" }} />}
      variant="contained"
      onClick={onClick}
      disabled={disabled}
      sx={{
        backgroundColor: COLOR_THEME.primary.main,
        padding: "6px 16px",
        fontSize: "14px",
        fontWeight: 500,
        fontFamily: "Roboto",
        color: "#FFFFFF",
        borderRadius: "8px",
        cursor: "pointer",
        letterSpacing: "0.5px",
        lineHeight: "24px",
        boxShadow: "none",
        ":hover": {
          backgroundColor: COLOR_THEME.primary.hover,
          boxShadow: "none",
        },
        ...style,
      }}
    >
      {title}
    </Button>
  );
}
