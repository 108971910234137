// 获取本地transcript token
import storage from "../../storage";
import JWTAnalysis from "../../utils/JWT";
import requestToken from "./request-token";

const storageKey = 'transcript-token';

const getToken = async () => {
  const token = await storage.read(storageKey);

  if (token && checkToken(token)) {
    return token;
  } else {
    console.log("[ 1 ] >", 1);
    const res = await requestToken();

    if (res.state) {
      storage.write(storageKey, res.token);
      return res.token;
    } else {
      return null;
    }
  }
};

export default getToken;

// 判断token的有效性
const checkToken = (token) => {
  let exp = JWTAnalysis(token) / 1000;
  let now = new Date().getTime() / 1000;
  if (now - exp < 600) {
    return true;
  } else {
    return false;
  }
};
