import storage from ".";
import StorageKey from "./storage-key";

const TrashStorage = {
    add: (key) => {
        const trash = storage.read(StorageKey.trash);
        if (trash) {
            trash.push(key);
            storage.write(StorageKey.trash, trash);
        }
        else {
            storage.write(StorageKey.trash, [key]);
        }
    },

    // 删
    remove: (key) => {
        const trash = storage.read(StorageKey.trash);
        if (trash) {
            const index = trash.indexOf(key);
            if (index > -1) {
                trash.splice(index, 1);
                storage.write(StorageKey.trash, trash);
                storage.remove(key);
            }
        }
    },

    // 恢复
    restore: (key) => {
        const trash = storage.read(StorageKey.trash);
        if (trash) {
            const index = trash.indexOf(key);
            if (index > -1) {
                trash.splice(index, 1);
                storage.write(StorageKey.trash, trash);
            }
        }
    },

    getAll: async () => {
        const trash = await storage.read(StorageKey.trash);
        return {
            trash: trash || [],
            length: trash ? trash.length : 0,
        }
    }

}

export default TrashStorage;
