// video标签控件,创建实例对象必须传入videoRef
class Controls {
    constructor(videoRef, duration, setProgress, setState, setCurrentTime) {
        this.video = videoRef.current;
        this.duration = duration;
        this.setProgress = setProgress;
        this.setState = setState;
        this.setCurrentTime = setCurrentTime;
    }

    state = 'pause';

    init() {
        // 核查video标签是否存在
        if (!this.video) {
            throw new Error('video标签不存在');
        }

        // 核查video标签是否有controls属性
        if (this.video.controls) {
            throw new Error('video标签已有controls属性');
        }

        // 添加video进度变化事件
        this.setProgressValue();

    }

    // 设置进度条 传入setProgress函数
    setProgressValue() {
        if (!this.setProgress) return;
        this.video.addEventListener('timeupdate', () => {
            const { currentTime } = this.video;

            this.setCurrentTime(currentTime);

            const percent = currentTime / this.duration;
              this.setProgress(percent);
            if (currentTime + 1 > this.duration && this.duration !== 0) {
              this.setState("end");
              this.state = "end";
            }

        });
    }

    playButtonHandle() {
        if (this.state === 'pause') {
            this.play();
            this.setState('play');
            this.state = 'play';
            return;
        }

        if (this.state === 'play') {
            this.pause();
            this.setState('pause');
            this.state = 'pause';
            return;
        }

        if (this.state === 'end') {
            this.replay();
            this.setState('play');
            this.state = 'play';
            return;
        }
    }

    // 播放
    play() {
        this.video.play();
    }

    // 暂停
    pause() {
        this.video.pause();
    }

    // 重播
    replay() {
        this.video.currentTime = 0;
        this.video.play();
    }

    // 音量
    audio(value) {
        if (value > 1 || value < 0) return;

        this.video.volume = value;
    }

    // 速度
    speed(value) {
        if (value > 2 || value < 0.5) return;

        this.video.playbackRate = value;
    }

    // 指定视频当前的时间

    setVideoCurrentTime(value) {
        if (value < 0 || value > this.duration) return;

        this.video.currentTime = value;
    }
}

export default Controls;


// 将时间格式转换为 00:00:00
function formatTime(time) {
    const hours = Math.floor(time / 60 / 60)
    const minutes = Math.floor(time / 60) - (hours * 60)
    const seconds = Math.floor(time % 60)
    return `${hours}:${minutes}:${seconds}`
}
