import { fetchFile } from "@ffmpeg/util";
import newFFmpegFactory from "./newFFmpeg";

const webmArrayToMp4 = async (videoList) => {
  let ffmpeg = await newFFmpegFactory.loadFFmpeg();

  /**
   *
   *
   * @description: 写入文件
   *
   *
   */

  for (let i = 0; i < videoList.length; i++) {
    let videoItem = videoList[i];
    await ffmpeg.writeFile(`input${i}.webm`, await fetchFile(videoItem));
  }

  /**
   *
   *
   * @description: 执行命令
   *
   *
   */

  for (let i = 0; i < videoList.length; i++) {
    await ffmpeg.exec(["-i", `input${i}.webm`, "-c", "copy", `output${i}.mp4`]);
  }

  /**
   *
   *
   * @description: 读取文件
   *
   *
   */
  let dataFile = [];
  for (let i = 0; i < videoList.length; i++) {
    const data = await ffmpeg.readFile(`output${i}.mp4`);
    dataFile.push(new File([data], `output${i}.mp4`, { type: "video/mp4" }));
  }

  return dataFile;
};

export default webmArrayToMp4;
