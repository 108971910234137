import React from 'react'
import { Box, Grid } from '@mui/material'

export default function Summary({
    data
}) {
    return (
        <Box
            sx={{
                width: '100%',
                padding: '0px 16px',
                boxSizing: 'border-box',
            }}
        >
            {
                data.map((item, index) => (
                    <SummaryItem
                        key={index}
                        data={item}
                    />
                ))
            }
        </Box>
    )
}

function SummaryItem({
    data,
}) {
    return (
        <Grid
            container
            sx={{
                width: '100%',
                height: 'auto',
                backgroundColor: data.type === 'beginning' ? 'rgba(201, 215, 255, 0.3)' :
                    data.type === 'middle' ? 'rgba(146, 255, 182, 0.15)' : 'rgba(255, 255, 247, 1)',
                boxSizing: 'border-box',
                padding: '10px 8px',
                marginTop: '12px',
            }}
        >
            <Box
                sx={{
                    width: '4px',
                    backgroundColor: data.type === 'beginning' ? 'rgba(47, 100, 247, 1)' :
                        data.type === 'middle' ? 'rgba(23, 185, 175, 1)' : 'rgba(238, 218, 33, 1)',
                    marginRight: '24px',
                }}
            />
            <Grid
                item
                xs
                container
                sx={{
                    width: '100%',
                    flexWrap: 'nowrap',
                }}
            >
                <ul
                    style={{
                        listStyle: 'initial',
                    }}
                >
                    {
                        data.content.map((item, index) => (
                            <SummaryItemLi
                                key={index}
                                data={item}
                            />
                        ))
                    }
                </ul>

            </Grid>
        </Grid>
    )
}

function SummaryItemLi({
    data,
}) {
    return (
        <li
            style={{
                marginBottom: '8px',
                fontSize: '14px',
                fontWeight: '400',
                color: 'rgba(0, 0, 0, 0.87)',
                letterSpacing: '0.5px',
            }}
        >
            {data}
        </li>
    )
}
