/* eslint-disable no-undef */
import React from "react";
// 全局状态管理
// 功能引入
import { createContext, useState } from "react";
// hook 工具,跨域存储
import { useCrossStorage } from "./hookTools";
import { syncServerSubscriptionPlan, syncUserSubscriptionState } from "../api/subscribe";
// 创建 Context 对象
const GlobalContext = createContext();
// 封装 ContextProvider
function GlobalContextProvider({ children }) {
  // 例子
  // 用户登录凭证
  const [userToken, setUserToken, getUserToken] = useCrossStorage("user_token", null);
  // 用户名
  const [userName, setUserName, getUserName] = useCrossStorage("user_name", null);
  // 用户订阅状态
  const [userSubState, setUserSubState, getUserSubState] = useCrossStorage("user_sub_state", null);
  // 用户订阅计划
  const [userSubPlan, setUserSubPlan, getUserSubPlan] = useCrossStorage("user_sub_plan", null);
  //   当前选择的功能
  let [whichFunction, setWhichFunction, getWhichFunction] = useCrossStorage("which_function", 0);
  //用户选择的模板
  const [userTemplate, setUserTemplate, getUserTemplate] = useCrossStorage("user_template", null);
  // summary 和 audio 的全局状态
  const [summaryState, setSummaryState, getSummaryState] = useCrossStorage("summary_state", null);
  // 用户选择播放的视频key值
  const [playKey, setPlayKey, getPlayKey] = useCrossStorage("play_key", null);
  let [globalCurrentVideo, setGlobalCurrentVideo] = useState(null);

  let [globalUploadFileToDrive, setGlobalUploadFileToDrive] = useState([]);
  // 保存全局状态
  const globalState = {
    userToken,
    setUserToken,
    getUserToken,
    userName,
    setUserName,
    getUserName,
    // 用户选择播放的视频key值
    playKey,
    setPlayKey,
    getPlayKey,
    // 订阅计划
    userSubPlan,
    setUserSubPlan,
    getUserSubPlan,
    userSubState,
    setUserSubState,
    getUserSubState,
    whichFunction,
    setWhichFunction,
    getWhichFunction,
    userTemplate,
    setUserTemplate,
    getUserTemplate,
    summaryState,
    setSummaryState,

    globalCurrentVideo,
    setGlobalCurrentVideo,

    globalUploadFileToDrive,
    setGlobalUploadFileToDrive,

    
  };
  React.useEffect(() => {
    (async () => {
      // 查看用户订阅计划是否缓存,如果没有则通过服务器获取用户订阅计划
      const subPlanCache = await getUserSubPlan();
      console.log("subPlanCache:", JSON.stringify(subPlanCache));
      if (!subPlanCache) {
        const serverSubPlan = await syncServerSubscriptionPlan();
        if (serverSubPlan.status === "success") {
          setUserSubPlan(serverSubPlan.response);
        }
      }
    })();
  }, []);
  //当 userToken 发生变化的时候,即用户登录登出时,重新设置用户属性
  React.useEffect(() => {
    (async () => {
      // 获取当前浏览器可见区域的宽高
      const windowWidth = window.document.documentElement.clientWidth;
      const windowHeight = window.document.documentElement.clientHeight;
      // 查看用户登录凭证是否缓存
      const userTokenCache = await getUserToken();
      if (userTokenCache) {
        // 同步用户订阅状态
        const userSub = await syncUserSubscriptionState({
          userToken: userTokenCache,
        });
        // 同步成功保存用户订阅状态
        if (userSub.status === "success" && userSub.response) {
          setUserSubState(userSub.response);
        }
        // 根据用户订阅状态设置不同用户属性
        if (userSub?.response.status === "ACTIVE") {
          setConstructEventUserProperties({
            user_vip_state: userSub.response.billCycle,
            screen_size: windowWidth + "*" + windowHeight,
          });
        } else {
          setConstructEventUserProperties({
            user_vip_state: "NONE",
            screen_size: windowWidth + "*" + windowHeight,
          });
        }
      } else {
        setConstructEventUserProperties({
          user_vip_state: "NONE",
          screen_size: windowWidth + "*" + windowHeight,
        });
      }
    })();
  }, [userToken]);
  // 发布全局状态
  return (
    <>
      <GlobalContext.Provider value={globalState}>{children}</GlobalContext.Provider>
    </>
  );
}

export { GlobalContext, GlobalContextProvider };
