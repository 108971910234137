/* eslint-disable no-undef */
// Login 用户登录
// 引入功能模块
// REACT
import React, { useEffect, useState } from "react";
// MUI
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Typography,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
// MUI ICON
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
// GLOBAL全局状态
import { GlobalContext } from "../../../utils/GlobalContext";
import { verifyEmailFormat, verifyPasswordFormat } from "../../../utils/verifyFormat";
import Grid from "@mui/material/Grid";
import { useMedia } from "react-use";

export default function Login(props) {
  const {closeDrawer} = props
  //定时器
  let loginTimer = null;
  //翻译成德文，英文

  // 引入全局属性
  const { setUserName, setUserToken } = React.useContext(GlobalContext);
  //点击登录,登录时的loading
  const [loading, setLoading] = React.useState(false);
  //响应后错误信息的提示
  const [loginError, setLoginError] = React.useState("");
  // 输入框参数
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });

  //邮箱验证
  // 验证邮箱状态 初始值为'' 验证失败时值为'error'
  const [emailState, setEmailState] = React.useState("");
  //校验，邮箱格式的错误信息提示
  const [errorMsg, setErrorMsg] = React.useState("");
  //密码验证
  // 验证密码状态 初始值为'' 验证失败时值为'error'
  const [passwordState, setPasswordState] = React.useState("");
  //校验，密码格式的错误信息提示
  const [errorPwd, setErrorPwd] = React.useState("");

  // 切换显示隐藏用户密码
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  // 检查用户名(邮箱)合法性
  const checkEmail = (event) => {
    setValues({ ...values, email: event.target.value });
    // 验证邮箱
    if (verifyEmailFormat(event.target.value)) {
      // 验证成功
      setEmailState("success");
      setErrorMsg("");
      return true;
    } else {
      // 验证失败
      setEmailState("error");
      setErrorMsg("Email format error");
      if (event.target.value == "") {
        setErrorMsg("");
      }
      return false;
    }
  };

  // 验证用户密码合法性
  const checkPassword = (event) => {
    setValues({ ...values, password: event.target.value });
    // 验证密码的正则表达式
    if (verifyPasswordFormat(event.target.value)) {
      // 验证成功
      setPasswordState("success");
      setErrorPwd("");
      return true;
    } else {
      // 验证失败
      setPasswordState("error");
      setErrorPwd("The password contains 8 to 16 digits or letters");
      if (event.target.value == "") {
        setErrorPwd("");
      }
      return false;
    }
  };
  let timer;
  React.useEffect(() => {
    timer = setTimeout(() => {
      if (loginError) {
        setLoginError("");
        clearTimeout(timer);
      }
    }, 2000);
  }, [loginError]);
  // 用户登录方法
  async function login() {
    // 用户登录按钮进入 loading 状态
    setLoading(true);
    // 发送用户登录按钮点击事件
    sendConstructEventByEventParams("login_page", { click: "login" });
    // 如果用户邮箱和验证通过则发送用户登录请求
    if (emailState === "success" && passwordState === "success") {
      const { userLogin } = await import("../../../api/user");
      const res = await userLogin({
        username: values.email,
        password: values.password,
      });
      if (res.status === "success") {
        setUserName(values.email);
        setUserToken(res.response.token);
        console.log('[ 123123123 ] >', 123123123)
        closeDrawer()
      } else {
        setLoginError(res.message);
      }
    } else {
      if (values.email === "" && values.password === "") {
        setErrorMsg("The mailbox cannot `be empty");
        setErrorPwd("The password cannot `be empty");
      } else if (values.email === "") {
        setErrorMsg("The mailbox cannot `be empty");
      } else if (values.password === "") {
        setErrorPwd("The password cannot `be empty");
      }
    }
    // 结束用户登录按钮 loading 状态
    setLoading(false);
  }
  useEffect(() => {
    return () => {
      //组件页面卸载后，清除定时器
      clearTimeout(loginTimer);
    };
  }, []);
  //媒体查询
  const isWide = useMedia("(max-width: 600px)");
  if (isWide) {
    return (
      <Box
        sx={{
          width: "100vw",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "100vw",

            margin: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "auto",
              height: "300px",
              // position: "relative",
              // top: "5vh",
              // left: "11vw",
            }}
          >
            <Grid container justifyContent="center" item sm={8} md={12} xs={6}>
              <Typography
                gutterBottom
                sx={{
                  textAlign: "center",
                  marginBottom: "88px",
                  height: "58px",
                  lineHeight: "58px",
                }}
              >
                <span
                  style={{
                    fontSize: "30px",
                    fontWeight: "bold",
                    color: "#323339",
                  }}
                >
                  Login
                </span>
                <span style={{ fontSize: "30px" }}>PDF Convert</span>
              </Typography>
              {/*邮箱输入框 */}
              <TextField
                InputProps={{
                  sx: {
                    height: "35px",
                  },
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "16px",
                    top: "-12px",
                  },
                }}
                error={errorMsg === "" ? false : true}
                id="Email"
                sx={{
                  width: "100vw",
                  height: "48px",
                  marginBottom: "30px",
                }}
                onChange={checkEmail}
                label={"Email"}
              />
              {/* 密码输入框*/}
              <TextField
                InputProps={{
                  sx: {
                    height: "35px",
                  },
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        sx={{ padding: "auto", fontSize: "16px" }}
                        onClick={handleClickShowPassword}
                      >
                        {values.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{
                  sx: {
                    fontSize: "16px",
                    top: "-12px",
                  },
                }}
                error={errorPwd === "" ? false : true}
                sx={{ width: "100vw", height: "30px" }}
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={checkPassword}
                label={"Password"}
              />
              <Box
                sx={{
                  fontSize: "14px",
                  marginTop: "5px",
                  marginBottom: "25px",
                  color: "red",
                  height: "20px",
                  width: "100vw",
                  overflow: "hidden",
                  wordBreak: "nowrap",
                }}
              >
                {loginError}
              </Box>
              <Box sx={{ textAlign: "right", marginTop: "20px", width: "360px" }}>
                <Link
                  underline="hover"
                  sx={{ fontSize: "14px", color: "#253fe7" }}
                  onClick={() => {
                    sendConstructEventByEventParams("login_page", {
                      click: "forget",
                    });
                    props.setNowPage(props.pages[1]);
                  }}
                >
                  Forgot your password?
                </Link>
              </Box>

              {/* 登陆 */}

              {loading ? (
                <LoadingButton
                  loading
                  variant="contained"
                  sx={{
                    width: "100vw",
                    height: "48px",
                    // marginLeft: "30px",
                    borderRadius: "24px",
                    outline: "none",
                    background: "rgb(0,85,225)",
                  }}
                />
              ) : (
                <Button
                  onClick={login}
                  variant="contained"
                  sx={{
                    // fontSize: "16px",
                    textTransform: "none",
                    borderRadius: "24px",
                    // marginLeft: "30px",
                    width: "100vw",
                    height: "48px",
                    outline: "none",
                  }}
                >
                  <Typography variant="body5">Login</Typography>
                </Button>
              )}
              {/* 横线 */}
              {/* 注册 */}
              <Box
                sx={{
                  width: "100vw",
                  textAlign: "center",
                  marginTop: "30px",
                  fontFamily: "Quicksand-Medium, Quicksand",
                }}
              >
                <Link underline="none" sx={{ fontSize: "16px", color: "#323339" }}>
                  Don't have an account？
                </Link>
                <br />
                <Link
                  underline="hover"
                  sx={{ fontSize: "16px", color: "#253fe7" }}
                  onClick={() => {
                    sendConstructEventByEventParams("login_page", {
                      click: "register",
                    });
                    props.setNowPage(props.pages[2]);
                  }}
                >
                  Sign up
                </Link>
              </Box>
            </Grid>
          </Box>
        </Box>
      </Box>
    );
  }
  // 标记
  else
    return (
      <Box
        sx={{
          // margin: "auto",
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "600px",
            maxHeight: "100vh",
          }}
        >
          {/* 移除的属性 */}
          <Grid container justifyContent="center" item>
            <Typography
              gutterBottom
              variant="body1"
              sx={{
                textAlign: "center",
                marginBottom: "88px",
                width: "416px",
                height: "58px",
                lineHeight: "58px",
              }}
            >
              <span style={{ fontWeight: "bold", color: "#323339" }}>Login</span>
            </Typography>
            {/*邮箱输入框 */}
            <TextField
              InputProps={{
                sx: {
                  height: "40px",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "16px",
                  top: "-6px",
                },
              }}
              color="secondary"
              error={errorMsg === "" ? false : true}
              id="Email"
              sx={{
                width: "360px",
                height: "48px",
                marginBottom: "40px",
                "& div": {
                  fontSize: "16px !important",
                },
              }}
              onChange={checkEmail}
              label={"Email"}
              helperText={errorMsg}
            />

            {/* 密码输入框*/}
            <TextField
              InputProps={{
                sx: {
                  height: "40px",
                },
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      sx={{ padding: "auto", fontSize: "14px" }}
                      onClick={handleClickShowPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "16px",
                  top: "-6px",
                },
              }}
              color="secondary"
              error={errorPwd === "" ? false : true}
              sx={{ width: "360px", height: "48px" }}
              type={values.showPassword ? "text" : "password"}
              value={values.password}
              onChange={checkPassword}
              label={"Password"}
              helperText={errorPwd}
            />
            <Box sx={{ textAlign: "right", margin: "10px 0", width: "360px" }}>
              <Link
                underline="hover"
                sx={{ fontSize: "14px", color: "#253fe7" }}
                onClick={() => {
                  sendConstructEventByEventParams("login_page", {
                    click: "forget",
                  });
                  props.setNowPage(props.pages[1]);
                }}
              >
                Forgot your password?
              </Link>
            </Box>
            <Box
              sx={{
                fontSize: "14px",
                textAlign: "left",
                height: "20px",
                lineHeight: "20px",
                color: "red",
                width: "300px",
                margin: "auto",
                overflow: "hidden",
                wordBreak: "nowrap",
              }}
            >
              {loginError}
            </Box>

            {/* 登陆 */}
            {loading ? (
              <LoadingButton
                loading
                variant="contained"
                sx={{
                  width: "360px",
                  height: "48px",
                  // marginLeft: "30px",
                  borderRadius: "24px",
                  outline: "none",
                  background: "rgb(0,85,225)",
                }}
              />
            ) : (
              <Button
                onClick={login}
                variant="contained"
                sx={{
                  fontSize: "16px",
                  textTransform: "none",
                  borderRadius: "24px",
                  width: "360px",
                  height: "48px",
                  outline: "none",
                }}
              >
              
                login
              </Button>
            )}
            {/* 横线 */}
            <div
              style={{
                width: "400px",
                marginTop: "10px",
              }}
            ></div>
            {/* 注册 */}
            <Box
              sx={{
                width: "400px",
                textAlign: "center",
                marginTop: "30px",
                fontFamily: "Quicksand-Medium, Quicksand",
              }}
            >
              <Link underline="none" sx={{ fontSize: "16px", color: "#323339" }}>
                Don't have an account？
              </Link>
              <Link
                underline="hover"
                sx={{ fontSize: "16px", color: "#253fe7" }}
                onClick={() => {
                  sendConstructEventByEventParams("login_page", {
                    click: "register",
                  });
                  props.setNowPage(props.pages[2]);
                }}
              >
                Sign up
              </Link>
            </Box>
          </Grid>
        </Box>
      </Box>
    );
}
