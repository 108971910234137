/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { Box, Grid } from '@mui/material'
import { SyncLoader, BarLoader } from "react-spinners";
import { secToTime } from '../../../../utils/editor-recorder/handleTime'


export default function Transcript({
    transcriptData,
    transcriptState,
}) {

    return (
      <Grid
        container
        sx={{
          width: "100%",
          height: "auto",
          marginTop: "17px",
          padding: "0px 16px",
          boxSizing: "border-box",
          justifyContent: "center",
        }}
      >
        {transcriptData.map((item, index) => (
          <TranscriptItem key={index} time={item?.time} text={item?.text} />
        ))}
        <BarLoader
          color="#2F64F7"
          loading={transcriptState === "handle"}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
          width="100%"
          cssOverride={{
            marginTop: "17px",
          }}
        />
        <SyncLoader
          color="#2F64F7"
          loading={transcriptState === "ready"}
          size={10}
          aria-label="Loading Spinner"
          data-testid="loader"
          cssOverride={{
            marginTop: "100px",
          }}
        />
      </Grid>
    );
}

function TranscriptItem({
    time,
    text,
}) {
    return (
        <Box
            sx={{
                width: '100%',
                height: 'auto',
                marginTop: '17px',
            }}
        >
            <Grid
                container
                sx={{
                    width: '100%',
                    height: '24px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: '8px',
                }}
            >
                {/* <Grid
                    container
                    sx={{
                        width: 'auto',
                        height: '100%',
                        alignItems: 'center',
                    }}
                >
                    <Grid
                        container
                        sx={{
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            backgroundColor: 'rgba(76, 122, 249, 1)',
                            marginRight: '10px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '14px',
                            fontWeight: '500',
                            color: 'rgba(255, 255, 255, 1)',
                        }}
                    >
                        1
                    </Grid>
                    <span
                        style={{
                            fontSize: '14px',
                            fontWeight: '500',
                            fontFamily: 'Roboto',
                            color: 'rgba(0, 0, 0, 0.87)',
                        }}
                    >
                        Speaker 1
                    </span>
                </Grid> */}
                <span
                    style={{
                        fontSize: '14px',
                        fontWeight: '400',
                        fontFamily: 'Roboto',
                        color: '#2F64F7',
                    }}
                >
                    {secToTime(time / 10000000)}
                </span>

            </Grid>
            <span
                style={{
                    fontSize: '14px',
                    fontWeight: '400',
                    fontFamily: 'Roboto',
                    color: 'rgba(51, 51, 51, 1)',
                    whiteSpace: 'wrap',
                    letterSpacing: '0.5px',
                }}
            >
                {text}
            </span>
        </Box>
    )
}
