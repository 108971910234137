import React from 'react'
import { Grid, Popover } from '@mui/material'
import IconButton from '../ui/button/icon-button'
import { GlobalContext } from "../../utils/GlobalContext";
import { HomeContext } from '../../screen/home';

export default function ToolDialog({ data, icon, videoItem, setIsEditVideoTitle,setVideosData ,setTrashesData}) {
  let { globalUploadFileToDrive, setGlobalUploadFileToDrive } = React.useContext(GlobalContext);
  let {isOpenClassroomDrawer, setIsOpenClassroomDrawer,classroomList, setClassroomList,globalVideoKey, setGlobalVideoKey} = React.useContext(HomeContext);



  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // main
  return (
    <>
      <IconButton icon={icon} aria-describedby={id} onClick={(e) => setAnchorEl(e.currentTarget)} />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        sx={{
          ".MuiPopover-paper": {
            borderRadius: "8px",
          },
        }}
      >
        <Grid
          container
          sx={{
            minWidth: "130px",
            padding: "12px 8px",
            height: data?.length * 41 + 14 + "px",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          {data?.map((item, index) => (
            <Grid
              container
              onClick={() => {
                item?.onClick({
                  videoItemUUID: videoItem,
                  setAnchorEl,
                  globalUploadFileToDrive,
                  setGlobalUploadFileToDrive,
                  setIsEditVideoTitle,
                  setVideosData,
                  setTrashesData,
                  setIsOpenClassroomDrawer,
                  setClassroomList,
                  globalVideoKey, setGlobalVideoKey

                });
              }}
              key={index}
              sx={{
                width: "100%",
                height: "31px",
                padding: "0px 8px",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "8px",
                ":hover": {
                  backgroundColor: "rgba(238, 238, 238, 1)",
                },
              }}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "24px",
                }}
              >
                {item?.title}
              </span>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </>
  );
}
