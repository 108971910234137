import React from "react";
import { Box } from "@mui/material";
import NarVarStyle from "../../css/narBar.module.scss";
import User from "./Title/User";
import { GlobalContext } from "../../utils/GlobalContext";
export default function NavBar() {
  let { setWhichFunction } = React.useContext(GlobalContext);
  return (
    <div>
      <Box className={NarVarStyle.top}>
        {/* logo 区域  */}
        <a className={NarVarStyle.top_logo} href="/">
          <img src="/images/editor-recorder/logo.svg" alt="logo" />
        </a>
        <Box
          className={NarVarStyle.top_right}
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {!document.body.getAttribute("is-install-SRecord") && (
            <span
              onClick={() => {
                window.open(
                  "https://chrome.google.com/webstore/detail/srecorder-screen-recorder/kkkghbbjolnllgjdpanjikgpedpnnpfj",
                  "_blank",
                );
              }}
              style={{
                width: "200px",
              }}
            >
              Install Chrome extension
            </span>
          )}

          {/* <Button variant="text">
            <img src="/images/editor-recorder/transmission.svg" alt="transmission" />
          </Button>
          <Button variant="text">
            <img src="/images/editor-recorder/news.svg" alt="news" />
          </Button> */}
          {/* <Button variant="text">
            <img src="/images/editor-recorder/personal.svg" alt="personal" />
          </Button> */}
          <User setWhichFunction={setWhichFunction} />
        </Box>
      </Box>
    </div>
  );
}
