/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Grid, Drawer } from "@mui/material";
import localforage from "localforage";
import Navbar from "../../components/navbar";
import SideMenu from "../../components/side-menu";
import PlayPage from "./play-page";
import SplitPage from "./split-page";
import StitchPage from "./stitch-page";
import { ServiceList } from "../home/page-list";
import { SideList } from "./side-list";
import { GlobalContext } from "../../utils/GlobalContext";
import VideosStorage from "../../storage/videos-storage";
import transcript from "../../common/transcript";
import addSubtitles from "../../common/add-subtitles";
import getAllUrlParams from "../../utils/getAllUrlParams";
import initVideoInfo from "../../utils/editor-recorder/initVideoInfo";
import getVideoInfo from "../../utils/getVideoInfo";
import newFFmpegFactory from "../../utils/ffmpeg/newFFmpeg";

export default function Editor() {
  // 获取全局存储的当前播放视频的key值
  let { getPlayKey, setPlayKey, globalCurrentVideo, setGlobalCurrentVideo } =
    React.useContext(GlobalContext);

  // 当前视频的key值
  const [videoKey, setVideoKey] = React.useState("");
  // 是否打开菜单
  const [isOpenMenu, setIsIOpenMenu] = React.useState(false);
  // 视频信息
  const [videoInfo, setVideoInfo] = React.useState({});
  // 视频名称
  const [videoTitle, setVideoTitle] = React.useState("");
  // 视频url
  const [videoSrc, setVideoSrc] = React.useState("");
  // 视频总长
  const [videoDuration, setVideoDuration] = React.useState(0);

  // 当前工具状态
  const [toolState, setToolState] = React.useState("play"); // play,split,stitch
  // 是否正在处理降噪
  const [isRNoiseLoading, setIsRNoiseLoading] = React.useState(false);
  const [isReduceNoise, setIsReduceNoise] = React.useState(false);
  // 是否正在处理添加字幕
  const [isAddSubtitleLoading, setIsAddSubtitleLoading] = React.useState(false);
  const [isAddSubtitles, setIsAddSubtitles] = React.useState(false);
  const isHandleAddSubtitles = React.useRef(false);

  // transcript
  let [transcriptData, setTranscriptData] = React.useState([]);
  const [transcriptState, setTranscriptState] = React.useState("ready");
  const transcriptIndex = React.useRef(0);

  /**
   *
   *
   * @description: Navbar 状态
   *
   *
   */
  const [isNavbarTitleEditor, setIsNavbarTitleEditor] = React.useState(false);

  // 监听全局 globalCurrentVideo 的变化,一旦变化需要修改 video src
  React.useEffect(() => {
    (async () => {
      if (!globalCurrentVideo) return;
      /**
       *
       * @description: 判断是否为 mp4 格式
       *
       */
      if (globalCurrentVideo.videoFile.type !== "video/mp4") {
        let webmToMp4Res = await newFFmpegFactory.webmToMp4(globalCurrentVideo.videoFile);
        const videoSrc = URL.createObjectURL(webmToMp4Res);
        setVideoSrc(videoSrc);

        let getVideoInfoRes = await getVideoInfo(videoSrc);
        setVideoDuration(getVideoInfoRes.duration);

        globalCurrentVideo.videoFile = webmToMp4Res;
        setGlobalCurrentVideo({
          ...globalCurrentVideo,
        });
      } else {
        /**
         *
         * @description: 直接设置视频的信息
         *
         */
        const videoSrc = URL.createObjectURL(globalCurrentVideo.videoFile);
        setVideoSrc(videoSrc);

        let getVideoInfoRes = await getVideoInfo(videoSrc);
        setVideoDuration(getVideoInfoRes.duration);
      }
    })();
  }, [globalCurrentVideo]);

  // 初始化读取视频数据
  React.useEffect(() => {
    // 获取视频信息
    const getVideoInfo = async () => {
      const uuid = getAllUrlParams(window.location.href).uuid;
      const uuidCorrespondingVideo = await VideosStorage.read(uuid);
      if (uuid && !uuidCorrespondingVideo) {
        /**
         *
         * @description: 读取首次的文件
         *
         */
        initVideoInfo(
          uuid,
          uuidCorrespondingVideo,
          setPlayKey,
          setVideoTitle,
          setVideoDuration,
          setVideoSrc,
          setVideoInfo,
          setTranscriptData,
          setTranscriptState,
          getTranscriptData,
          setGlobalCurrentVideo,
          globalCurrentVideo,
        );
        return;
      }

      let playKey = "";
      if (uuid) {
        playKey = uuid;
      }
      playKey = await getPlayKey();
      setVideoKey(playKey);
      const info = await VideosStorage.read(playKey);
      if (info) {
        setVideoInfo(info);
        setVideoTitle(info.videoTitle);
        setVideoDuration(info.videoDuration);

        // const videoBlob = new Blob(info.file, { type: "video/webm" });
        const fileRes = new File(info.file, "video.webm", { type: "video/webm" });
        setGlobalCurrentVideo({
          ...globalCurrentVideo,
          videoFile: fileRes,
        });
        // 判断是否存在transcript数据
        if (info.transcriptData && info.transcriptData.length > 0) {
          setTranscriptData(info.transcriptData);
          setTranscriptState("end");
        } else {
          // 获取transcript数据
          getTranscriptData(info.file, info, playKey);
        }
      }
    };

    getVideoInfo();
  }, []);

  // 更改视频封面
  const changeThumbnail = React.useCallback(
    async (thumbnail) => {
      const info = { ...videoInfo, videoCover: thumbnail };
      VideosStorage.update(videoKey, info);
    },
    [videoInfo],
  );

  // 获取transcript数据
  const getTranscriptData = React.useCallback(
    async (videoFile, videoInfo, videoKey) => {
      let file = new File(videoFile, "video.webm", { type: "video/webm" });

      // 定义回调函数
      const callback = ({ type, content }) => {
        if (type === "update") {
          setTranscriptState("handle");
          // 替换数据
          transcriptData[transcriptIndex.current] = content;
          transcriptData = transcriptData.filter(
            (element) => element !== undefined && element !== null,
          );
          setTranscriptData([...transcriptData]);
        }

        if (type === "add") {
          transcriptIndex.current++;
        }

        if (type === "end") {
          //存储数据
          const info = { ...videoInfo, transcriptData: transcriptData };
          VideosStorage.update(videoKey, info);
          setVideoInfo(info);

          setTranscriptState("end");
        }
        if (type === "null") {
          transcriptData[0] = {
            time: 0,
            text: "This video does not contain sound",
          };
          setTranscriptData([...transcriptData]);
        }

        if (type === "error") {
          console.log("error", content);
          setTranscriptState("error");
        }
      };

      // 获取transcript数据
      transcript(file, callback);
    },
    [transcriptData, isAddSubtitleLoading],
  );

  // 添加字幕
  const addSubtitlesHandle = React.useCallback(async () => {
    if (isAddSubtitleLoading) return;

    if (isAddSubtitles) {
      // 去除字幕
      const videoBlob = new Blob(videoInfo.file, { type: "video/webm" });
      setVideoSrc(URL.createObjectURL(videoBlob));

      setIsAddSubtitles(false);
    } else {
      // 添加字幕
      if (videoInfo.isHasSubtitles) {
        // 已经存在字幕视频文件
        globalCurrentVideo.videoFile = videoInfo.subtitlesFile;
        setGlobalCurrentVideo({
          ...globalCurrentVideo,
        });
        setIsAddSubtitles(true);
      } else {
        // 不存在字幕视频文件
        setIsAddSubtitleLoading(true);

        // 设置回调函数
        const callback = (file) => {
          if (file) {
            const info = {
              ...videoInfo,
              subtitlesFile: file,
              isHasSubtitles: true,
              isAddSubtitles: true,
            };
            VideosStorage.update(videoKey, info);
            setVideoInfo(info);

            setIsAddSubtitles(true);
            globalCurrentVideo.videoFile = file;
            setGlobalCurrentVideo({
              ...globalCurrentVideo,
            });
            console.log("完成视频的添加字幕");
          } else {
            console.log("添加字幕失败,file 为 null");
          }

          // 结束loading状态
          setIsAddSubtitleLoading(false);
        };

        if (transcriptState === "end") {
          // 已经存在transcript数据
          // 获取字幕文件
          console.log("[ globalCurrentVideo.videoFile ] >", globalCurrentVideo.videoFile);
          addSubtitles(globalCurrentVideo.videoFile, videoInfo.transcriptData, callback);
        } else {
          // 不存在transcript数据
          console.log("等待transcript数据");
          isHandleAddSubtitles.current = true;
          return;
        }
      }
    }
  }, [isAddSubtitles, videoInfo, isAddSubtitleLoading, transcriptState]);

  // 降噪
  const reduceNoiseHandle = React.useCallback(async () => {
    setIsReduceNoise(!isReduceNoise);

    if (!isReduceNoise) {
      console.log(globalCurrentVideo);
      setIsRNoiseLoading(true);
      // 获取页面上的所有按钮元素
      document.body.style.pointerEvents = "none";

      let noiseReductionRes = await newFFmpegFactory.noiseReduction(globalCurrentVideo.videoFile);

      setIsRNoiseLoading(false);
      document.body.style.pointerEvents = "auto";
      globalCurrentVideo.videoFile = noiseReductionRes;
      setGlobalCurrentVideo({
        ...globalCurrentVideo,
      });
      //#region 旧版本
      /**
       * @description:  连接 Worker
       */
      // const worker = new Worker("/core/ffmpeg-worker.js");
      // worker.postMessage({
      //   type: "noiseReduction",
      //   file: globalCurrentVideo.videoFile,
      // });
      // worker.onmessage = (event) => {
      //   if (event.data.type === "noiseReduction") {
      //     console.log("降噪完成");
      //     setIsRNoiseLoading(false);
      //     document.body.style.pointerEvents = "auto";
      //     globalCurrentVideo.videoFile = event.data.file;
      //     setGlobalCurrentVideo({
      //       ...globalCurrentVideo,
      //     });
      //     worker.onmessage = null;
      //   }
      // };
      //#endregion 旧版本
    }
  }, [isReduceNoise, globalCurrentVideo]);

  return (
    <Grid
      container
      sx={{
        width: "100vw",
        height: "100vh",
        flexDirection: "column",
      }}
    >
   
      {/* 导航栏 */}
      <Navbar
        type="editor"
        openMenu={() => setIsIOpenMenu(true)}
        videoTitle={videoTitle}
        setVideoTitle={setVideoTitle}
        isNavbarTitleEditor={isNavbarTitleEditor}
        setIsNavbarTitleEditor={setIsNavbarTitleEditor}
        videoKey={videoKey}
        globalCurrentVideo={globalCurrentVideo}
      />

      {/* 侧边栏菜单 */}
      <Drawer open={isOpenMenu} onClose={() => setIsIOpenMenu(false)} anchor="left">
        <SideMenu PageList={SideList} ServiceList={ServiceList} />
      </Drawer>

      <Box sx={{ width: "100%", height: "1px", backgroundColor: "rgba(216, 216, 216, 1)" }} />

      {/* <EditorPage /> */}
      <Grid
        xs
        container
        item
        sx={{
          width: "100%",
          height: "calc(100% - 65px)",
        }}
      >
        {toolState === "play" && (
          <PlayPage
            videoKey={videoKey}
            videoSrc={videoSrc}
            setToolState={setToolState}
            videoDuration={videoDuration}
            changeThumbnail={changeThumbnail}
            transcriptData={transcriptData}
            transcriptState={transcriptState}
            addSubtitlesHandle={addSubtitlesHandle}
            isAddSubtitles={isAddSubtitles}
            isAddSubtitleLoading={isAddSubtitleLoading}
            reduceNoiseHandle={reduceNoiseHandle}
            isReduceNoise={isReduceNoise}
            isRNoiseLoading={isRNoiseLoading}
          />
        )}
        {toolState === "split" && (
          <SplitPage
            videoDuration={videoDuration}
            videoSrc={videoSrc}
            setToolState={setToolState}
            isAddSubtitleLoading={isAddSubtitleLoading}
          />
        )}
        {toolState === "stitch" && (
          <StitchPage
            isAddSubtitleLoading={isAddSubtitleLoading}
            setToolState={setToolState}
            videoInfo={videoInfo}
          />
        )}
      </Grid>
    </Grid>
  );
}
