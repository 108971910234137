import React,{useContext} from "react";
import { Box, Grid, Divider, Typography,Dialog } from "@mui/material";
import COLOR_THEME from "../../utils/color-scheme";
import TextButton from "../ui/button/text-button";
//
import HeadTitle from "./head-title";
import List from "./list";
import SubscribePage from "../../page/components/subscribe/SubscribePage";
import { GlobalContext } from "../../utils/GlobalContext";

export default function SideMenu({ page, setPage, PageList, ServiceList }) {
  let { whichFunction, setWhichFunction } = useContext(GlobalContext);

    const [openPricing, setOpenPricing] = React.useState(false);
    const handleClosePricing = () => {
        setOpenPricing(false);
      };

  return (
    <Grid
      container
      sx={{
        boxSizing: "border-box",
        width: "240px",
        height: "100vh",
        backgroundColor: "#f9f9f9",
        padding: "0px 12px",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box>
        {/* 头部名称 */}
        <HeadTitle />
        {/* 选项列表 */}
        <List data={PageList} activeValue={page} onClick={(item) => setPage(item.value)} />
        <Divider
          sx={{
            margin: "12px 0px",
          }}
        />
        {/* 协议列表 */}
        <List
          data={ServiceList}
          activeValue={"none"}
          onClick={(item) => (window.location.href = item.link)}
        />
      </Box>
      <Grid
        container
        sx={{
          width: "100%",
          height: "125px",
          flexDirection: "column",
          alignItems: "center",
          backgroundColor: COLOR_THEME.secondary.main,
          marginBottom: "16px",
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "700",
            fontFamily: "Quicksand",
            color: COLOR_THEME.primary.main,
            lineHeight: "24px",
            marginTop: "16px",
          }}
        >
          Upgrade to Premium
        </Typography>
        <img
          src="/images/go_pro.png"
          alt=""
          style={{
            height: "8px",
            width: "147px",
            marginTop: "6px",
          }}
        />
           <TextButton
            onClick={() => {
                setOpenPricing(true)
            }}
            title="UPGRADE"
            style={{
              marginTop: "15px",
            }}
          />
      </Grid>
      <Dialog
      sx={{
        zIndex: 2,
      }}
      maxWidth={false} open={openPricing} onClose={handleClosePricing}>
          <SubscribePage
            setWhichFunction={setWhichFunction}
            handleClosePricing={handleClosePricing}
          />
        </Dialog>
      
    </Grid>
  );
}
