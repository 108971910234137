import { FFmpeg } from "@ffmpeg/ffmpeg";
import { toBlobURL, fetchFile } from "@ffmpeg/util";

// const urlOrigin = new URL(window.location.href).origin;
const loadFFmpeg = async () => {
  const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.2/dist/umd";
  let ffmpeg = new FFmpeg();
  ffmpeg.on("log", ({ message }) => {
    console.log("[ message ] >", message);
  });
  await ffmpeg.load({
    coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
    wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, "application/wasm"),
  });

    return ffmpeg;
};

export default loadFFmpeg;
